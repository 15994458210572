



// // client/src/Pages/Home.js

// import React from 'react';
// import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom'; // react-router-dom এর Link ব্যবহার করা হয়েছে

// import { ShoppingBag, Users, TrendingUp, Gift, Crown } from 'lucide-react';

// const FeatureCard = ({ icon: Icon, title, description }) => (
//   <motion.div
//     className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//   >
//     <Icon className="h-12 w-12 text-blue-600 mb-4" />
//     <h3 className="text-xl font-semibold mb-2">{title}</h3>
//     <p className="text-gray-600">{description}</p>
//   </motion.div>
// );

// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     className={`px-6 py-3 rounded-full font-semibold text-lg transition-colors duration-300 ${className}`}
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     {...props}
//   >
//     {children}
//   </motion.button>
// );

// export default function HomePage() {
//   return (
//     <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white">
//       <header className="bg-blue-800 text-white py-6">
//         <div className="container mx-auto px-4">
//           <h1 className="text-3xl font-bold text-center">বাজারপ্রতিক্ষণ</h1>
//           <p className="text-xl text-center mt-2">বাজার প্রতিক্ষণ সুবিধা সারাক্ষণ </p>
//         </div>
//       </header>

//       <main className="container mx-auto px-4 py-12">
//         <section className="text-center mb-16">
//           <h2 className="text-4xl font-bold mb-6">আপনার অনলাইন বাজারের নতুন অভিজ্ঞতা</h2>
//           <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
//             বাজারপ্রতিক্ষণে আপনাকে স্বাগতম! আমরা আপনার অনলাইন কেনাকাটাকে আরও সহজ, নিরাপদ এবং আনন্দদায়ক করে তুলতে প্রতিশ্রুতিবদ্ধ।
//           </p>
          
          
//         {/* <div className="flex jujustify-cente space-x-4">
//             <Link to="/register">
//               <Button className="bg-green-500 text-white hover:bg-green-600">রেজিস্ট্রেশন করুন</Button>
//             </Link>
//             <Link to="/login">
//               <Button className="bg-blue-600 text-white hover:bg-blue-700">লগইন করুন</Button>
//             </Link>
//           </div>*/}
//           <div className="flex justify-center space-x-4 p-4">
//   <Link to="/register">
//     <Button className="h-12 w-36 bg-green-500 text-white hover:bg-green-600 transition duration-200">রেজিস্ট্রেশন করুন</Button>
//   </Link>
//   <Link to="/login">
//     <Button className="h-12 w-36 bg-blue-600 text-white hover:bg-blue-700 transition duration-200">লগইন করুন</Button>
//   </Link>
// </div>

          
          
          
//         </section>

//         <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
//           <FeatureCard
//             icon={ShoppingBag}
//             title="সহজ কেনাকাটা"
//             description="আমাদের প্ল্যাটফর্মে সহজেই আপনার প্রয়োজনীয় পণ্য খুঁজে পান এবং কিনুন।"
//           />
//           <FeatureCard
//             icon={Users}
//             title="বিশ্বস্ত বিক্রেতা"
//             description="শুধুমাত্র যাচাইকৃত এবং বিশ্বস্ত বিক্রেতাদের সাথে লেনদেন করুন।"
//           />
//           <FeatureCard
//             icon={TrendingUp}
//             title="ক্যাশব্যাক অফার"
//             description="প্রতিটি কেনাকাটায় আকর্ষণীয় ক্যাশব্যাক এবং পয়েন্ট অর্জন করুন।"
//           />
//           <FeatureCard
//             icon={Gift}
//             title="রেফারেল বোনাস"
//             description="বন্ধুদের আমন্ত্রণ জানান এবং আকর্ষণীয় রেফারেল বোনাস পান।"
//           />
//           <FeatureCard
//             icon={Crown}
//             title="রয়্যালিটি ও ইনসেন্টিভ"
//             description="আপনার প্রয়োজনীয় কেনাকাটা চালিয়ে যান আর নির্দিষ্ট নিয়মে রয়্যালিটি ও ইনসেন্টিভ উপভোগ করুন।"
//           />
//         </section>

//         <section className="text-center mb-16">
//           <h2 className="text-3xl font-bold mb-4">আমাদের সম্পর্কে</h2>
//           <p className="text-xl text-gray-600 max-w-3xl mx-auto">
//             বাজারপ্রতিক্ষণ হল একটি অনলাইন মার্কেটপ্লেস যা ক্রেতা এবং বিক্রেতাদের মধ্যে সেতুবন্ধন তৈরি করে। 
//             আমরা বিশ্বাস করি যে সততা, স্বচ্ছতা এবং গ্রাহক সন্তুষ্টি হল সফল ব্যবসার মূল চাবিকাঠি।
//           </p>
//         </section>

//         <section className="text-center mb-16">
//           <h2 className="text-3xl font-bold mb-8">আজই যোগ দিন বাজারপ্রতিক্ষণ পরিবারে</h2>
        
        
//         <div className="flex justify-center space-x-4 p-4">
//   <Link to="/register">
    
//     <Button className="h-12 w-36 bg-green-500 text-white hover:bg-green-600 transition duration-200">রেজিস্ট্রেশন করুন</Button>
//   </Link>
//   <Link to="/login">
//     <Button className="h-12 w-36 bg-blue-600 text-white hover:bg-blue-700 transition duration-200">লগইন করুন</Button>
//   </Link>
// </div>

        
//         </section>
        
//       </main>

//       <footer className="bg-gray-800 text-white py-8 mt-12">
//         <div className="container mx-auto px-4">
//           <div className="flex flex-col md:flex-row justify-between items-center">
//             <div className="mb-4 md:mb-0">
//               <h3 className="text-2xl font-bold">বাজারপ্রতিক্ষণ</h3>
//               <p className="mt-2">আপনার বাজারের বিশ্বস্ত সঙ্গী</p>
//             </div>
//             <nav>
//               <ul className="flex flex-wrap justify-center md:justify-end space-x-4">
//                 <li><a href="#" className="hover:text-blue-400 transition-colors">গোপনীয়তা নীতি</a></li>
//                 <li><a href="#" className="hover:text-blue-400 transition-colors">ব্যবহারের শর্তাবলী</a></li>
//                 <li><a href="#" className="hover:text-blue-400 transition-colors">যোগাযোগ করুন</a></li>
//               </ul>
//             </nav>
//           </div>
//           <div className="mt-8 text-center text-sm">
//             <p>&copy; {new Date().getFullYear()} বাজারপ্রতিক্ষণ। সর্বস্বত্ব সংরক্ষিত।</p>
//           </div>
//         </div>
//       </footer>
//     </div>
//   )
// }






import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ShoppingBag, Users, TrendingUp, Gift, Crown, Sun, Moon } from 'lucide-react';
import { useTheme } from '../ThemeContext';

const FeatureCard = ({ icon: Icon, title, description, isNightMode }) => (
  <motion.div
    className={`${isNightMode ? 'bg-gray-800' : 'bg-white'} bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Icon className="h-12 w-12 text-yellow-400 mb-4" />
    <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
    <p className="text-gray-200">{description}</p>
  </motion.div>
);

const Button = ({ children, className, ...props }) => (
  <motion.button
    className={`px-6 py-3 rounded-full font-semibold text-lg transition-all duration-300 ${className}`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    {...props}
  >
    {children}
  </motion.button>
);

export default function HomePage() {
  const { isNightMode, toggleNightMode } = useTheme();

  const bgGradient = isNightMode
    ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
    : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

  const cardBg = isNightMode
    ? 'bg-gray-800 bg-opacity-30'
    : 'bg-black bg-opacity-30';

  return (
    <div className={`min-h-screen ${bgGradient} text-white transition-colors duration-500 relative`}>
      
      {/* Registration Button
      <button
        onClick={toggleNightMode}
        className="absolute top-4 right-4 p-2 rounded-full bg-white bg-opacity-20 text-white hover:bg-opacity-30 transition-all duration-300 z-50"
      >
        {isNightMode ? <Sun size={24} /> : <Moon size={24} />}
      </button>

 */}

      <header className={`${cardBg} backdrop-filter backdrop-blur-lg py-6 sticky top-0 z-10`}>
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold text-center">বাজারপ্রতিক্ষণ</h1>
          <p className="text-xl text-center mt-2">বাজার প্রতিক্ষণ সুবিধা সারাক্ষণ</p>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <section className="text-center mb-16">
          <motion.h2
            className="text-4xl font-bold mb-6"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            আপনার অনলাইন বাজারের নতুন অভিজ্ঞতা
          </motion.h2>
          <motion.p
            className="text-xl text-gray-200 max-w-3xl mx-auto mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            বাজারপ্রতিক্ষণে আপনাকে স্বাগতম! আমরা আপনার অনলাইন কেনাকাটাকে আরও সহজ, নিরাপদ এবং আনন্দদায়ক করে তুলতে প্রতিশ্রুতিবদ্ধ।
          </motion.p>

          <div className="flex justify-center space-x-4 p-4">
<Link to="/register">
  <Button className={`w-full py-4 px-6 rounded-full text-white bg-gradient-to-r ${isNightMode ? 'from-blue-500 to-purple-600' : 'from-green-400 to-blue-500'} hover:from-blue-600 hover:to-purple-700 transition-all duration-300 text-sm`}>
    রেজিস্ট্রেশন করুন
  </Button>
</Link>

<Link to="/login">
  <Button className={`w-full py-4 px-6 rounded-full text-black bg-gradient-to-r ${isNightMode ? 'from-yellow-500 to-red-600' : 'from-yellow-400 to-orange-500'} hover:from-yellow-600 hover:to-red-700 transition-all duration-300 text-sm`}>
    লগইন করুন
  </Button>
</Link>

          </div>
        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          <FeatureCard
            icon={ShoppingBag}
            title="সহজ কেনাকাটা"
            description="আমাদের প্ল্যাটফর্মে সহজেই আপনার প্রয়োজনীয় পণ্য খুঁজে পান এবং কেনাকাটা করুন খুব সহজে।"
            isNightMode={isNightMode}
          />
          <FeatureCard
            icon={Users}
            title="বিশ্বস্ত বিক্রেতা"
            description="শুধুমাত্র আমাদের বাজার প্রতিক্ষণ সেলার থেকে শতভাগ বিশ্বস্ততার সাথে কেনাকাটা করুন এবং নগদ লেনদেন করুন। "
            isNightMode={isNightMode}
          />
          <FeatureCard
            icon={TrendingUp}
            title="ক্যাশব্যাক অফার"
            description="প্রতিটি কেনাকাটায় আকর্ষণীয় ক্যাশব্যাক পয়েন্ট হিসাবে বুঝে নিন নির্দিষ্ট সময় পরপর বোনাস উপভোগ করুন।"
            isNightMode={isNightMode}
          />
          <FeatureCard
            icon={Gift}
            title="রেফারেল বোনাস"
            description="বন্ধুদের আমন্ত্রণ জানান এবং আকর্ষণীয় রেফারেল ইনকাম উপভোগ করুন।"
            isNightMode={isNightMode}
          />
          <FeatureCard
            icon={Crown}
            title="রয়্যালিটি ও ইনসেন্টিভ"
            description="বাজার প্রতিক্ষণ থেকে আপনার প্রয়োজনীয় কেনাকাটা চালিয়ে যান আর নির্দিষ্ট নিয়মে রয়্যালিটি ও ইনসেন্টিভ উপভোগ করুন।"
            isNightMode={isNightMode}
          />
        </section>

        <section className={`text-center mb-16 ${cardBg} backdrop-filter backdrop-blur-lg p-8 rounded-xl`}>
          <h2 className="text-3xl font-bold mb-4">আমাদের সম্পর্কে</h2>
          <p className="text-xl text-gray-200 max-w-3xl mx-auto">
            বাজারপ্রতিক্ষণ হল একটি অনলাইন মার্কেটপ্লেস যা ক্রেতা এবং বিক্রেতাদের মধ্যে সেতুবন্ধন তৈরি করে। 
            আমরা বিশ্বাস করি যে সততা, স্বচ্ছতা এবং গ্রাহক সন্তুষ্টি হল সফল ব্যবসার মূল চাবিকাঠি।
          </p>
        </section>

        <section className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-8">আজই যোগ দিন বাজারপ্রতিক্ষণ পরিবারে</h2>

          <div className="flex justify-center space-x-4 p-4">
          <Link to="/register">
  <Button className={`w-full py-4 px-6 rounded-full text-white bg-gradient-to-r ${isNightMode ? 'from-blue-500 to-purple-600' : 'from-green-400 to-blue-500'} hover:from-blue-600 hover:to-purple-700 transition-all duration-300 text-sm`}>
    রেজিস্ট্রেশন করুন
  </Button>
</Link>

<Link to="/login">
  <Button className={`w-full py-4 px-6 rounded-full text-black bg-gradient-to-r ${isNightMode ? 'from-yellow-500 to-red-600' : 'from-yellow-400 to-orange-500'} hover:from-yellow-600 hover:to-red-700 transition-all duration-300 text-sm`}>
    লগইন করুন
  </Button>
</Link>

          </div>
        </section>
      </main>

      <footer className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 mt-12`}>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h3 className="text-2xl font-bold">বাজারপ্রতিক্ষণ</h3>
              <p className="mt-2">আপনার বাজারের বিশ্বস্ত সঙ্গী</p>
            </div>
            <nav>
              <ul className="flex flex-wrap justify-center md:justify-end space-x-4">
                <li><a href="#" className="hover:text-yellow-400 transition-colors">গোপনীয়তা নীতি</a></li>
                <li><a href="#" className="hover:text-yellow-400 transition-colors">ব্যবহারের শর্তাবলী</a></li>
                <li><a href="#" className="hover:text-yellow-400 transition-colors">যোগাযোগ করুন</a></li>
              </ul>
            </nav>
          </div>
          <div className="mt-8 text-center text-sm">
            <p>&copy; {new Date().getFullYear()} বাজারপ্রতিক্ষণ। সর্বস্বত্ব সংরক্ষিত।</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

