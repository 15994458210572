// // client/src/App.js

// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Navbar from './Components/Navbar'; // ইমপোর্ট করুন
// import Home from './Pages/Home';
// import Login from './Pages/Login';
// import Register from './Pages/Register';
// import CustomerDashboard from './Pages/CustomerDashboard';
// import SellerDashboard from './Pages/SellerDashboard';
// import PrivateRoute from './Components/PrivateRoute'; // ইমপোর্ট করুন

// function App() {
//   return (
//     <Router>
//       <Navbar /> {/* এখানে Navbar যুক্ত করুন */}
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/register" element={<Register />} />

//         {/* কাস্টমার ড্যাশবোর্ড রুট প্রোটেক্ট করা */}
//         <Route
//           path="/customer-dashboard"
//           element={
//             <PrivateRoute allowedUserType="customer">
//               <CustomerDashboard />
//             </PrivateRoute>
//           }
//         />

//         {/* সেলার ড্যাশবোর্ড রুট প্রোটেক্ট করা */}
//         <Route
//           path="/seller-dashboard"
//           element={
//             <PrivateRoute allowedUserType="seller">
//               <SellerDashboard />
//             </PrivateRoute>
//           }
//         />
//       </Routes>
//     </Router>
//   );
// }

// export default App;





// src/App.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Navbar from './Components/Navbar';
// import Home from './Pages/Home';
// import Login from './Pages/Login';
// import Register from './Pages/Register';
// import CustomerDashboard from './Pages/CustomerDashboard';
// import SellerDashboard from './Pages/SellerDashboard';
// import PrivateRoute from './Components/PrivateRoute';
// import { ThemeProvider } from './ThemeContext'; // ThemeProvider ইমপোর্ট

// function App() {
//   return (
//     <ThemeProvider> {/* ThemeProvider দিয়ে অ্যাপ্লিকেশন র‍্যাপ */}
//       <Router>
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route
//             path="/customer-dashboard"
//             element={
//               <PrivateRoute allowedUserType="customer">
//                 <CustomerDashboard />
//               </PrivateRoute>
//             }
//           />
//           <Route
//             path="/seller-dashboard"
//             element={
//               <PrivateRoute allowedUserType="seller">
//                 <SellerDashboard />
//               </PrivateRoute>
//             }
//           />
//         </Routes>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;




import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Register from './Pages/Register';
import CustomerDashboard from './Pages/CustomerDashboard';
import SellerDashboard from './Pages/SellerDashboard';
import PrivateRoute from './Components/PrivateRoute';
import { ThemeProvider } from './ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/customer-dashboard"
            element={
              <PrivateRoute allowedUserType="customer">
                <CustomerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/seller-dashboard"
            element={
              <PrivateRoute allowedUserType="seller">
                <SellerDashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;


