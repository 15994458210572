// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { Eye, EyeOff, Check, AlertCircle, Lock, Mail, User, Phone } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Uaip = process.env.REACT_APP_API_BASE_URL;

// // Button component
// const Button = ({ children, onClick, className, disabled }) => (
//   <button
//     onClick={onClick}
//     className={`py-2 px-4 rounded-md font-medium ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
//     disabled={disabled}
//   >
//     {children}
//   </button>
// );

// // Input component
// const Input = ({ id, type, placeholder, value, onChange, className, name }) => (
//   <input
//     id={id}
//     name={name}
//     type={type}
//     placeholder={placeholder}
//     value={value}
//     onChange={onChange}
//     className={`border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${className}`}
//   />
// );

// // Label component
// const Label = ({ children, htmlFor }) => (
//   <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
//     {children}
//   </label>
// );

// // Select component for user type
// const Select = ({ id, name, value, onChange, options }) => (
//   <select
//     id={id}
//     name={name}
//     value={value}
//     onChange={onChange}
//     className="border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full"
//   >
//     {options.map((option) => (
//       <option key={option.value} value={option.value}>
//         {option.label}
//       </option>
//     ))}
//   </select>
// );

// // Input with Validation component
// const InputWithValidation = ({ label, id, type, placeholder, value, onChange, error, success, icon: Icon, name }) => (
//   <div className="space-y-2">
//     <Label htmlFor={id}>{label}</Label>
//     <div className="relative">
//       <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//         <Icon className="h-5 w-5 text-gray-400" />
//       </div>
//       <Input
//         id={id}
//         name={name}
//         type={type}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         className={`pl-10 pr-10 ${error ? 'border-red-500' : success ? 'border-green-500' : ''}`}
//       />
//       {error && <AlertCircle className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500" />}
//       {success && <Check className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" />}
//     </div>
//     {error && <p className="text-sm text-red-500">{error}</p>}
//   </div>
// );

// export default function RegisterPage() {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     password: '',
//     userType: 'customer',
//     referralCode: '',
//   });
//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//     validateField(name, value);
//   };

//   const validateField = (name, value) => {
//     let newErrors = { ...errors };
//     switch (name) {
//       case 'name':
//         if (!value.trim()) newErrors.name = 'Full Name is required';
//         else delete newErrors.name;
//         break;
//       case 'email':
//         if (value && !/\S+@\S+\.\S+/.test(value)) newErrors.email = 'Invalid email address';
//         else delete newErrors.email;
//         break;
//       case 'phone':
//         if (value && !/^\+?\d{10,15}$/.test(value)) newErrors.phone = 'Invalid phone number';
//         else delete newErrors.phone;
//         break;
//       case 'password':
//         if (!value.trim()) newErrors.password = 'Password is required';
//         else delete newErrors.password;
//         break;
//       case 'referralCode':
//         // Optional: Add any specific validation for referral code if needed
//         break;
//       default:
//         break;
//     }
//     setErrors(newErrors);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate all fields before submission
//     const fields = ['name', 'email', 'phone', 'password'];
//     fields.forEach((field) => validateField(field, formData[field]));

//     if (
//       Object.keys(errors).length === 0 &&
//       formData.name &&
//       (formData.email || formData.phone) &&
//       formData.password
//     ) {
//       setIsSubmitting(true);
//       try {
//         const response = await fetch(`${Uaip}register`, {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({
//             name: formData.name,
//             email: formData.email,
//             phone: formData.phone,
//             password: formData.password,
//             userType: formData.userType,
//             referralCode: formData.userType === 'customer' ? formData.referralCode : '',
//           }),
//         });

//         if (response.ok) {
//           alert('Registration successful');
//           navigate('/login');
//         } else {
//           const errorMsg = await response.text();
//           setError(errorMsg);
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//       setIsSubmitting(false);
//     } else {
//       setError('Please fix the errors in the form.');
//     }
//   };

//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//       className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
//     >
//       <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg pt-2">
//         <div>
//           <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
//           <p className="mt-2 text-center text-sm text-gray-600">Join BaBazarProtikhon Today</p>
//         </div>
//         {error && <p className="text-center text-red-500">{error}</p>}
//         <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
//           <div className="space-y-6">
//             <InputWithValidation
//               label="Full Name"
//               id="name"
//               name="name"
//               type="text"
//               placeholder="Your Full Name"
//               value={formData.name}
//               onChange={handleChange}
//               error={errors.name}
//               success={formData.name && !errors.name}
//               icon={User}
//             />
//             <InputWithValidation
//               label="Email (optional)"
//               id="email"
//               name="email"
//               type="email"
//               placeholder="you@example.com"
//               value={formData.email}
//               onChange={handleChange}
//               error={errors.email}
//               success={formData.email && !errors.email}
//               icon={Mail}
//             />
//             <InputWithValidation
//               label="Phone Number (optional)"
//               id="phone"
//               name="phone"
//               type="text"
//               placeholder="+1234567890"
//               value={formData.phone}
//               onChange={handleChange}
//               error={errors.phone}
//               success={formData.phone && !errors.phone}
//               icon={Phone}
//             />
//             <div className="space-y-2">
//               <Label htmlFor="password">Password</Label>
//               <div className="relative">
//                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                   <Lock className="h-5 w-5 text-gray-400" />
//                 </div>
//                 <Input
//                   id="password"
//                   name="password"
//                   type={showPassword ? 'text' : 'password'}
//                   placeholder="••••••••"
//                   value={formData.password}
//                   onChange={handleChange}
//                   className={`pl-10 pr-10 ${errors.password ? 'border-red-500' : formData.password ? 'border-green-500' : ''}`}
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setShowPassword(!showPassword)}
//                   className="absolute inset-y-0 right-0 pr-3 flex items-center"
//                 >
//                   {showPassword ? <EyeOff className="h-5 w-5 mr-2 text-gray-400" /> : <Eye className="h-5 w-5 mr-2 text-gray-400" />}
//                 </button>
//               </div>
//               {errors.password && <p className="text-sm text-red-500">{errors.password}</p>}
//             </div>

//             {/* User Type Selection */}
//             <div className="space-y-2">
//               <Label htmlFor="userType">User Type</Label>
//               <div className="relative">
//                 <div className="absolute inset-y-0 left-0.5 pl-0 flex items-center pointer-events-none">
//                   <User className="h-5 w-5 text-gray-400" />
//                 </div>
//                 <Select
//                   id="userType"
//                   name="userType"
//                   value={formData.userType}
//                   onChange={handleChange}
//                   options={[
//                     { value: 'customer', label: 'Customer' },
//                   { value: 'seller', label: 'seller' },
//                   ]}
//                 />
//               </div>
//             </div>

//             {/* Referral Code (Only for Customer) */}
//             {formData.userType === 'customer' && (
//               <InputWithValidation
//                 label="Referral Code (Optional)"
//                 id="referralCode"
//                 name="referralCode"
//                 type="text"
//                 placeholder="Enter Referral Code"
//                 value={formData.referralCode}
//                 onChange={handleChange}
//                 error={errors.referralCode}
//                 success={formData.referralCode && !errors.referralCode}
//                 icon={User}
//               />
//             )}
//           </div>

//           <div>
//             <motion.button
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//               type="submit"
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//               disabled={isSubmitting}
//             >
//               {isSubmitting ? (
//                 <svg
//                   className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                 >
//                   <circle
//                     className="opacity-25"
//                     cx="12"
//                     cy="12"
//                     r="10"
//                     stroke="currentColor"
//                     strokeWidth="4"
//                   ></circle>
//                   <path
//                     className="opacity-75"
//                     fill="currentColor"
//                     d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                   ></path>
//                 </svg>
//               ) : (
//                 'Register'
//               )}
//             </motion.button>
//           </div>
//         </form>
//         <p className="mt-2 text-center text-sm text-gray-600">
//           Already have an account?{' '}
//           <a href="/login" className="font-medium text-blue-600 hover:text-blue-500">
//             Login
//           </a>
//         </p>
//       </div>
//     </motion.div>
//   );
// }








import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Eye, EyeOff, User, Phone, Lock, Gift, Sun, Moon, AlertCircle, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';

const Uaip = process.env.REACT_APP_API_BASE_URL;

const Input = ({ icon: Icon, error, success, ...props }) => (
  <div className="relative">
    <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    <input
      className={`w-full pl-10 pr-10 py-2 rounded-lg bg-white bg-opacity-20 border-2 ${
        error ? 'border-red-500' : success ? 'border-green-500' : 'border-transparent'
      } focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300`}
      {...props}
    />
    {error && <AlertCircle className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500" />}
    {success && <Check className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" />}
  </div>
);

const Select = ({ icon: Icon, ...props }) => (
  <div className="relative">
    <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    <select
      className="w-full pl-10 pr-3 py-2 rounded-lg bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
      {...props}
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default function RegistrationPage() {
  const { isNightMode, toggleNightMode } = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    password: '',
    referralCode: '',
    userType: 'customer',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const payload = {
      name: formData.fullName,
      phone: formData.phone,
      password: formData.password,
      userType: formData.userType,
      referralCode: formData.userType === 'customer' ? formData.referralCode : '',
    };

    try {
      const response = await fetch(`${Uaip}register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('রেজিস্ট্রেশন সফল হয়েছে!');
        navigate('/login');
      } else {
        const errorMsg = await response.text();
        setError(errorMsg || 'রেজিস্ট্রেশন ব্যর্থ হয়েছে। আবার চেষ্টা করুন।');
      }
    } catch (err) {
      console.error(err);
      setError('কোনো সমস্যা হয়েছে। আবার চেষ্টা করুন।');
    } finally {
      setIsSubmitting(false);
    }
  };

  const bgGradient = isNightMode
    ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
    : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

  const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

  return (
    <div className={`min-h-screen ${bgGradient} flex flex-col items-center justify-center p-4 transition-colors duration-500`}>
     

      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-bold text-center text-white mb-4">বাজারপ্রতিক্ষণে স্বাগতম</h1>
        <p className="text-xl text-center text-gray-200 mb-6">আপনার অনলাইন বাজারের নতুন অভিজ্ঞতা শুরু করুন</p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full max-w-md mx-auto"
        >
          <form onSubmit={handleSubmit} className={`${cardBg} backdrop-filter backdrop-blur-lg p-8 rounded-2xl shadow-xl`}>
            <h2 className="text-3xl font-bold text-center text-white mb-6">রেজিস্ট্রেশন</h2>
            {error && <p className="text-center text-red-500 mb-4">{error}</p>}
            <div className="space-y-4">
              <Input
                icon={User}
                type="text"
                name="fullName"
                placeholder="পুরো নাম"
                value={formData.fullName}
                onChange={handleChange}
                error={errors.fullName}
                success={formData.fullName && !errors.fullName}
              />
              <Input
                icon={Phone}
                type="tel"
                name="phone"
                placeholder="ফোন নম্বর"
                value={formData.phone}
                onChange={handleChange}
                error={errors.phone}
                success={formData.phone && !errors.phone}
              />
              <div className="relative">
                <Input
                  icon={Lock}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="পাসওয়ার্ড"
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password}
                  success={formData.password && !errors.password}
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              <Input
                icon={Gift}
                type="text"
                name="referralCode"
                placeholder="রেফারেল কোড (ঐচ্ছিক)"
                value={formData.referralCode}
                onChange={handleChange}
              />
              <Select
                icon={User}
                name="userType"
                value={formData.userType}
                onChange={handleChange}
                options={[
                  { value: 'customer', label: 'কাস্টমার' },
                  { value: 'seller', label: 'সেলার' },
                ]}
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`w-full mt-6 py-3 px-4 bg-gradient-to-r ${
                isNightMode ? 'from-blue-500 to-purple-600' : 'from-yellow-400 to-orange-500'
              } text-white font-semibold rounded-lg shadow-md hover:from-yellow-500 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-75 transition-all duration-300`}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <svg
                  className="animate-spin h-5 w-5 mx-auto text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                'রেজিস্টার'
              )}
            </motion.button>
          </form>
          <p className="text-center text-white mt-4">
            ইতিমধ্যে একাউন্ট আছে?{' '}
            <button onClick={() => navigate('/login')} className="text-yellow-400 hover:underline">
              লগইন করুন
            </button>
          </p>
        </motion.div>
      </div>
    </div>
  );
}









//least 


// import React, { useState } from 'react';

// const Registration = () => {
//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: '',
//     phone: '',
//     password: '',
//     userType: 'customer',
//     referralCode: '',
//   });

//   const [passwordStrength, setPasswordStrength] = useState('');
//   const [formErrors, setFormErrors] = useState({});
//   const [loading, setLoading] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });

//     // Password strength indicator
//     if (name === 'password') {
//       if (value.length > 8) {
//         setPasswordStrength('Strong');
//       } else if (value.length > 5) {
//         setPasswordStrength('Medium');
//       } else {
//         setPasswordStrength('Weak');
//       }
//     }
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!formData.fullName) errors.fullName = 'Full Name is required';
//     if (!formData.email && !formData.phone) {
//       errors.contact = 'Either Email or Phone Number is required';
//     }
//     if (!formData.password) errors.password = 'Password is required';
//     return errors;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const errors = validateForm();
//     if (Object.keys(errors).length > 0) {
//       setFormErrors(errors);
//     } else {
//       setFormErrors({});
//       setLoading(true);
//       // Simulate API call
//       setTimeout(() => {
//         setLoading(false);
//         alert('Registration successful!');
//       }, 2000);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-blue-50 flex items-center justify-center p-4">
//       <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8 space-y-6">
//         <h2 className="text-2xl font-bold text-gray-800 text-center">Create Your Account</h2>
//         <form className="space-y-4" onSubmit={handleSubmit}>
//           {/* Full Name */}
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Full Name</label>
//             <input
//               type="text"
//               name="fullName"
//               value={formData.fullName}
//               onChange={handleInputChange}
//               className={`mt-1 block w-full px-3 py-2 border ${formErrors.fullName ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
//             />
//             {formErrors.fullName && <p className="text-red-500 text-xs mt-1">{formErrors.fullName}</p>}
//           </div>

//           {/* Email */}
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Email (Optional)</label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className={`mt-1 block w-full px-3 py-2 border ${formErrors.contact ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
//             />
//           </div>

//           {/* Phone */}
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Phone Number (Optional)</label>
//             <input
//               type="text"
//               name="phone"
//               value={formData.phone}
//               onChange={handleInputChange}
//               className={`mt-1 block w-full px-3 py-2 border ${formErrors.contact ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
//             />
//             {formErrors.contact && <p className="text-red-500 text-xs mt-1">{formErrors.contact}</p>}
//           </div>

//           {/* Password */}
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Password</label>
//             <input
//               type="password"
//               name="password"
//               value={formData.password}
//               onChange={handleInputChange}
//               className={`mt-1 block w-full px-3 py-2 border ${formErrors.password ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
//             />
//             {formErrors.password && <p className="text-red-500 text-xs mt-1">{formErrors.password}</p>}
//             {formData.password && (
//               <p className={`text-xs mt-1 ${passwordStrength === 'Strong' ? 'text-green-500' : passwordStrength === 'Medium' ? 'text-yellow-500' : 'text-red-500'}`}>
//                 Password Strength: {passwordStrength}
//               </p>
//             )}
//           </div>

//           {/* User Type */}
//           <div>
//             <label className="block text-sm font-medium text-gray-700">I am a:</label>
//             <select
//               name="userType"
//               value={formData.userType}
//               onChange={handleInputChange}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
//             >
//               <option value="customer">Customer</option>
//               <option value="seller">Seller</option>
//             </select>
//           </div>

//           {/* Referral Code */}
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Referral Code (Optional)</label>
//             <input
//               type="text"
//               name="referralCode"
//               value={formData.referralCode}
//               onChange={handleInputChange}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
//             />
//           </div>

//           {/* Submit Button */}
//           <button
//             type="submit"
//             className="w-full py-2 px-4 bg-green-500 text-white font-semibold rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition ease-in-out duration-150"
//             disabled={loading}
//           >
//             {loading ? <span>Creating Account...</span> : 'Create Account'}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Registration;
