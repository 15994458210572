// // client/src/Pages/SellerDashboard.js

// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import {
//   User,
//   Settings,
//   LogOut,
//   DollarSign,
//   Users as UsersIcon,
//   Gift,
//   TrendingUp,
//   Search,
//   Bell,
// } from 'lucide-react';
// const Uaip = process.env.REACT_APP_API_BASE_URL;
// // **UI Components Defined Inline**


// const Button = ({ children, onClick, className, disabled, type = 'button' }) => (
//   <motion.button
//     type={type}
//     onClick={onClick}
//     className={`px-6 py-3 rounded-full font-semibold text-lg transition-colors duration-300 ${className} ${
//       disabled ? 'opacity-50 cursor-not-allowed' : ''
//     }`}
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     disabled={disabled}
//   >
//     {children}
//   </motion.button>
// );

// const Input = ({ id, type, placeholder, value, onChange, className, name }) => (
//   <input
//     id={id}
//     name={name}
//     type={type}
//     placeholder={placeholder}
//     value={value}
//     onChange={onChange}
//     className={`border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${className}`}
//   />
// );

// const Label = ({ children, htmlFor }) => (
//   <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
//     {children}
//   </label>
// );

// const Card = ({ children, className }) => (
//   <div className={`bg-white p-6 rounded-xl shadow-lg ${className}`}>{children}</div>
// );

// const Avatar = ({ src, alt, fallback }) => (
//   <div className="relative">
//     {src ? (
//       <img src={src} alt={alt} className="h-8 w-8 rounded-full" />
//     ) : (
//       <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-white font-bold">
//         {fallback}
//       </div>
//     )}
//   </div>
// );

// const DropdownMenu = ({ children }) => <div className="relative">{children}</div>;

// const DropdownMenuTrigger = ({ children }) => <div>{children}</div>;

// const DropdownMenuContent = ({ children }) => (
//   <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-md shadow-lg z-50">
//     {children}
//   </div>
// );

// const DropdownMenuItem = ({ children, onClick }) => (
//   <div
//     onClick={onClick}
//     className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
//   >
//     {children}
//   </div>
// );

// const DropdownMenuSeparator = () => <hr className="border-t border-gray-200" />;

// const DropdownMenuLabel = ({ children }) => (
//   <div className="px-4 py-2 text-sm text-gray-500">{children}</div>
// );

// const Select = ({ value, onChange, options, placeholder }) => (
//   <select
//     value={value}
//     onChange={onChange}
//     className="border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full"
//   >
//     <option value="" disabled>
//       {placeholder}
//     </option>
//     {options.map((option) => (
//       <option key={option.value} value={option.value}>
//         {option.label}
//       </option>
//     ))}
//   </select>
// );

// const Tabs = ({ children }) => <div className="space-y-8">{children}</div>;

// const TabsList = ({ children }) => <div className="flex space-x-4 border-b">{children}</div>;

// const TabsTrigger = ({ value, children, activeTab, setActiveTab }) => (
//   <button
//     onClick={() => setActiveTab(value)}
//     className={`py-2 px-4 font-medium ${
//       activeTab === value
//         ? 'border-b-2 border-blue-600 text-blue-600'
//         : 'text-gray-600 hover:text-blue-600'
//     }`}
//   >
//     {children}
//   </button>
// );

// const TabsContent = ({ value, children, activeTab }) =>
//   activeTab === value ? <div>{children}</div> : null;

// // **Helper Components**

// const PerformanceCard = ({ title, value, icon: Icon }) => (
//   <Card className="flex items-center">
//     <div className="rounded-full p-3 bg-blue-100">
//       <Icon className="h-8 w-8 text-blue-600" />
//     </div>
//     <div className="ml-4">
//       <p className="text-sm font-medium text-gray-500">{title}</p>
//       <p className="text-2xl font-bold text-gray-900">{value}</p>
//     </div>
//   </Card>
// );

// const TransactionItem = ({ customerId, amount, date }) => (
//   <motion.li
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     className="flex items-center justify-between p-4 border-b last:border-b-0"
//   >
//     <div className="flex items-center">
//       <DollarSign className="h-6 w-6 text-green-500 mr-3" />
//       <div>
//         <p className="font-medium">Customer ID: {customerId}</p>
//         <p className="text-sm text-gray-500">{date}</p>
//       </div>
//     </div>
//     <span className="font-semibold text-green-600">+৳{amount}</span>
//   </motion.li>
// );

// // **Main SellerDashboard Component**

// export default function SellerDashboard() {
//   const navigate = useNavigate();

//   const [activeTab, setActiveTab] = useState('dashboard');
//   const [customerId, setCustomerId] = useState('');
//   const [cashbackAmount, setCashbackAmount] = useState('');
//   const [sellerData, setSellerData] = useState({
//     name: 'Junaet Khan',
//     totalCashback: 5000,
//     customersEngaged: 150,
//     referralBonuses: 500,
//     recentTransactions: [
//       { id: 1, customerId: 'CUST001', amount: 50, date: '2023-06-15' },
//       { id: 2, customerId: 'CUST002', amount: 75, date: '2023-06-14' },
//       { id: 3, customerId: 'CUST003', amount: 100, date: '2023-06-13' },
//     ],
//   });

//   useEffect(() => {
//     // সেলার অথেনটিকেশন চেক করা
//     const token = localStorage.getItem('token');

//     if (!token) {
//       navigate('/login');
//     }

//     // এখানে তোমার সেলার ডেটা ফেচ করার লজিক যুক্ত করতে পারো
//     // উদাহরণস্বরূপ:
//     /*
//     const fetchSellerData = async () => {
//       try {
//         const response = await fetch('/api/seller/dashboard', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (response.ok) {
//           const data = await response.json();
//           setSellerData(data);
//         } else {
//           throw new Error('Failed to fetch seller data');
//         }
//       } catch (error) {
//         console.error(error);
//         localStorage.removeItem('token');
//         localStorage.removeItem('userType');
//         navigate('/login');
//       }
//     };
//     fetchSellerData();
//     */
//   }, [navigate]);

//   const handleAssignCashback = async (e) => {
//     e.preventDefault();

//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${Uaip}dashboard/seller/assign-cashback`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({ customerId, amount: parseFloat(cashbackAmount) }),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         alert(
//           `Cashback assigned to ${data.customerId}. Updated Points: ${data.updatedPoints}`
//         );
//         setCustomerId('');
//         setCashbackAmount('');
//         // Optionally, update recent transactions
//         setSellerData((prev) => ({
//           ...prev,
//           recentTransactions: [
//             { id: Date.now(), customerId: data.customerId, amount: data.amount, date: new Date().toISOString().split('T')[0] },
//             ...prev.recentTransactions,
//           ],
//         }));
//       } else if (response.status === 403 || response.status === 401) {
//         // টোকেন অবৈধ বা এক্সপায়ার্ড হলে লগআউট করুন
//         localStorage.removeItem('token');
//         localStorage.removeItem('userType');
//         navigate('/login');
//       } else {
//         const errorMsg = await response.text();
//         alert(`Error: ${errorMsg}`);
//       }
//     } catch (err) {
//       alert('An error occurred. Please try again.');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('userType');
//     navigate('/login');
//   };

//   return (
//     <div className="min-h-screen bg-gray-100">
//       {/* Header */}
//       <header className="bg-white shadow-sm">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
//           <div className="flex items-center">
//           <img src="https://bazarprotikhon.com/Logo.jpg" className="h-12 w-12 text-blue-600 rounded-full mr-2" alt="Description" />

//             <span className="text-2xl font-bold text-blue-600">BazarProtikhon</span>
//           </div>
//           <nav className="flex items-center space-x-4">
//             <Button className="bg-transparent hover:bg-gray-200">
//               <Bell className="h-5 w-5" />
//             </Button>
            
            
//   {/*          <DropdownMenu>
//               <DropdownMenuTrigger>
//                 <Button className="relative h-8 w-8 rounded-full bg-gray-300 p-0">
//                   <Avatar src="/placeholder.svg" alt={sellerData.name} fallback={sellerData.name.slice(0, 2).toUpperCase()} />
//                 </Button>
//               </DropdownMenuTrigger>
//               <DropdownMenuContent>
//                 <DropdownMenuLabel>
//                   <div className="flex flex-col space-y-1">
//                     <p className="text-sm font-medium">{sellerData.name}</p>
//                     <p className="text-xs text-gray-500">seller@example.com</p>
//                   </div>
//                 </DropdownMenuLabel>
//                 <DropdownMenuSeparator />
//                 <DropdownMenuItem>
//                   <User className="mr-2 h-4 w-4" />
//                   <span>Profile</span>
//                 </DropdownMenuItem>
//                 <DropdownMenuItem>
//                   <Settings className="mr-2 h-4 w-4" />
//                   <span>Settings</span>
//                 </DropdownMenuItem>
//                 <DropdownMenuSeparator />
//                 <DropdownMenuItem onClick={handleLogout}>
//                   <LogOut className="mr-2 h-4 w-4" />
//                   <span>Log out</span>
//                 </DropdownMenuItem>
//               </DropdownMenuContent>
//             </DropdownMenu>/* */}
            
            
            
//           </nav>
//         </div>
//       </header>

//       {/* Main Content */}
//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <motion.h1
//           initial={{ opacity: 0, y: -20 }}
//           animate={{ opacity: 1, y: 0 }}
//           className="text-3xl font-bold text-gray-900 mb-8"
//         >
//           Hello, {sellerData.name}!
//         </motion.h1>

//         <Tabs>
//           <TabsList>
//             <TabsTrigger value="dashboard" activeTab={activeTab} setActiveTab={setActiveTab}>
//               Dashboard
//             </TabsTrigger>
//             <TabsTrigger value="assign-cashback" activeTab={activeTab} setActiveTab={setActiveTab}>
//               Assign Cashback
//             </TabsTrigger>
//             {/*
//             <TabsTrigger value="profile" activeTab={activeTab} setActiveTab={setActiveTab}>
//               Profile
//             </TabsTrigger>*/}
//           </TabsList>

//           {/* Dashboard Tab */}
//           <TabsContent value="dashboard" activeTab={activeTab}>
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
//               <PerformanceCard title="Total Cashback Assigned" value={`৳${sellerData.totalCashback}`} icon={DollarSign} />
//               <PerformanceCard title="Customers Engaged" value={sellerData.customersEngaged} icon={UsersIcon} />
//               <PerformanceCard title="Cashback Bonuses Earned" value={`৳${sellerData.referralBonuses}`} icon={Gift} />
//             </div>

//             <Card>
//               <div className="flex justify-between items-center mb-4">
//                 <Select
//                   value=""
//                   onChange={() => {}}
//                   options={[
//                     { value: 'today', label: 'Today' },
//                     { value: 'week', label: 'This Week' },
//                     { value: 'month', label: 'This Month' },
//                   ]}
//                   placeholder="Filter by date"
//                 />
//                 <Input type="text" placeholder="Search by Customer ID" className="max-w-sm" />
//               </div>
//               <ul>
//                 {sellerData.recentTransactions.map((transaction) => (
//                   <TransactionItem
//                     key={transaction.id}
//                     customerId={transaction.customerId}
//                     amount={transaction.amount}
//                     date={transaction.date}
//                   />
//                 ))}
//               </ul>
//             </Card>
//           </TabsContent>

//           {/* Assign Cashback Tab */}
//           <TabsContent value="assign-cashback" activeTab={activeTab}>
//             <Card>
//               <form onSubmit={handleAssignCashback} className="space-y-4">
//                 <div>
//                   <Label htmlFor="customerId">Customer ID</Label>
//                   <div className="relative">
//                     <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
//                     <Input
//                       id="customerId"
//                       name="customerId"
//                       type="text"
//                       value={customerId}
//                       onChange={(e) => setCustomerId(e.target.value)}
//                       placeholder="Enter Customer ID"
//                       className="pl-10"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div>
//                   <Label htmlFor="cashbackAmount">Cashback Amount (৳)</Label>
//                   <Input
//                     id="cashbackAmount"
//                     name="cashbackAmount"
//                     type="number"
//                     value={cashbackAmount}
//                     onChange={(e) => setCashbackAmount(e.target.value)}
//                     placeholder="Enter amount"
//                     required
//                   />
//                 </div>
//                 <Button type="submit" className="w-full bg-green-600 text-white hover:bg-green-700">
//                   Assign Cashback
//                 </Button>
//               </form>
//             </Card>
//           </TabsContent>

//           {/* Profile Tab
//           <TabsContent value="profile" activeTab={activeTab}>
//             <Card>
//               <div className="space-y-4">
//                 <div>
//                   <Label htmlFor="name">Name</Label>
//                   <Input id="name" name="name" type="text" defaultValue={sellerData.name} />
//                 </div>
//                 <div>
//                   <Label htmlFor="email">Email</Label>
//                   <Input id="email" name="email" type="email" defaultValue="seller@example.com" />
//                 </div>
//                 <div>
//                   <Label htmlFor="paymentMethod">Preferred Payment Method</Label>
//                   <Select
//                     value="bank"
//                     onChange={() => {}}
//                     options={[
//                       { value: 'bank', label: 'Bank Transfer' },
//                       { value: 'paypal', label: 'PayPal' },
//                       { value: 'check', label: 'Check' },
//                     ]}
//                     placeholder="Select payment method"
//                   />
//                 </div>
//                 <div>
//                   <Label htmlFor="notifications">Notification Preferences</Label>
//                   <Select
//                     value="email"
//                     onChange={() => {}}
//                     options={[
//                       { value: 'email', label: 'Email' },
//                       { value: 'sms', label: 'SMS' },
//                       { value: 'both', label: 'Both Email and SMS' },
//                     ]}
//                     placeholder="Select notification method"
//                   />
//                 </div>
//                 <Button className="bg-blue-600 text-white hover:bg-blue-700">Save Changes</Button>
//               </div>
//             </Card>
//           </TabsContent>
//           */}
//         </Tabs>
//       </main>
//     </div>
//   );
// }





// SellerDashboard.jsx






// SellerDashboard.jsx

// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Bell,
//   User,
//   LogOut,
//   Menu,
//   Gift,
//   TrendingUp,
//   DollarSign,
//   Award,
//   Percent,
//   Users,
//   Send,
//   Sun,
//   Moon,
//   CheckCircle,
// } from 'lucide-react';

// // Backend API URL
// const API_BASE_URL = process.env.REACT_APP_ || 'http://localhost:5000';

// // Button Component
// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.button>
// );

// // Card Component
// const Card = ({ children, className, ...props }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//     whileHover={{ scale: 1.02 }}
//     className={`p-6 rounded-2xl shadow-xl ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.div>
// );

// // FeatureCard Component
// const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
//   <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
//     <div className="p-3 rounded-full bg-white bg-opacity-20">
//       <Icon className="h-8 w-8 text-white" />
//     </div>
//     <div className="text-center">
//       <p className="text-lg font-medium text-white">{title}</p>
//       <p className="text-3xl font-bold text-white">{value}</p>
//       <p className="text-sm text-white opacity-80 mt-2">{description}</p>
//     </div>
//   </Card>
// );

// // CustomerItem Component
// const CustomerItem = ({ name, customerId, points }) => (
//   <motion.li
//     initial={{ opacity: 0, x: -20 }}
//     animate={{ opacity: 1, x: 0 }}
//     className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//   >
//     <div>
//       <p className="font-medium text-white">{name}</p>
//       <p className="text-sm text-white opacity-80">ID: {customerId}</p>
//     </div>
//     <div className="text-right">
//       <p className="text-lg font-bold text-yellow-300">{points} পয়েন্ট</p>
//     </div>
//   </motion.li>
// );

// // WithdrawRequestItem Component
// const WithdrawRequestItem = ({ request, onApprove }) => {
//   if (!request || !request.user) {
//     return null; // Or show a message
//   }

//   return (
//     <motion.li
//       initial={{ opacity: 0, x: -20 }}
//       animate={{ opacity: 1, x: 0 }}
//       className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//     >
//       <div>
//         <p className="font-medium text-white">কাস্টমার ID: {request.user.customerId}</p>
//         <p className="text-sm text-white opacity-80">নাম: {request.user.name}</p>
//         <p className="text-sm text-white opacity-80">নাম্বার: {request.user.phone}</p>
//         <p className="text-sm text-white opacity-80">পেমেন্ট মেথড: {request.paymentMethod}</p>
//         <p className="text-sm text-white opacity-80">
//           তারিখ: {new Date(request.createdAt).toLocaleString()}
//         </p>
//       </div>
//       <div className="text-right">
//         <p className="text-lg font-bold text-green-300">৳{request.amount}</p>
//         <Button
//           className="mt-2 bg-green-500 text-white hover:bg-green-600"
//           onClick={() => onApprove(request.id)}
//         >
//           <CheckCircle className="h-5 w-5 mr-1" /> অ্যাপ্রুভ
//         </Button>
//       </div>
//     </motion.li>
//   );
// };

// export default function SellerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview');
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [customerId, setCustomerId] = useState('');
//   const [cashbackAmount, setCashbackAmount] = useState('');
//   const [message, setMessage] = useState('');
//   const [isNightMode, setIsNightMode] = useState(false);

//   const [sellerData, setSellerData] = useState({
//     name: '',
//     bonusFund: 0,
//     sellerIncome: 0,
//     royaltyFund: 0,
//     incentiveFund: 0,
//     companyIncome: 0,
//   });

//   const [customersData, setCustomersData] = useState({
//     allCustomers: [],
//     goldenCustomers: [],
//     activeCustomers: [],
//     highPointCustomers: [],
//   });

//   const [withdrawRequests, setWithdrawRequests] = useState([]);

//   // Loading states
//   const [loadingSellerData, setLoadingSellerData] = useState(true);
//   const [loadingCustomers, setLoadingCustomers] = useState(true);
//   const [loadingWithdrawRequests, setLoadingWithdrawRequests] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       // Redirect to login page
//       window.location.href = '/login';
//     } else {
//       // Fetch seller data
//       fetchSellerFunds();
//       // Fetch customers data
//       fetchCustomers();
//       // Fetch withdraw requests
//       fetchWithdrawRequests();
//     }
//   }, []);

//   const fetchSellerFunds = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/funds`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setSellerData({
//           name: data.name || '',
//           bonusFund: data.bonusFund || 0,
//           sellerIncome: data.sellerIncome || 0,
//           royaltyFund: data.royaltyFund || 0,
//           incentiveFund: data.incentiveFund || 0,
//           companyIncome: data.companyIncome || 0,
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching seller funds:', errorText);
//         alert(`সেলার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching seller funds:', error);
//       alert('সেলার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingSellerData(false);
//     }
//   };

//   const fetchCustomers = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/customers`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setCustomersData({
//           allCustomers: data.allCustomers || [],
//           goldenCustomers: data.goldenCustomers || [],
//           activeCustomers: data.activeCustomers || [],
//           highPointCustomers: data.highPointCustomers || [],
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching customers:', errorText);
//         alert(`কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching customers:', error);
//       alert('কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingCustomers(false);
//     }
//   };

//   const fetchWithdrawRequests = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/withdraw-requests`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setWithdrawRequests(data.requests || []);
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching withdraw requests:', errorText);
//         alert(`উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching withdraw requests:', error);
//       alert('উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingWithdrawRequests(false);
//     }
//   };

//   const handleAssignCashback = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/assign-cashback`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           customerId,
//           amount: parseFloat(cashbackAmount),
//         }),
//       });

//       if (response.ok) {
//         // Show success message
//         alert('ক্যাশব্যাক সফলভাবে অ্যাসাইন করা হয়েছে!');
//         // Reset form
//         setCustomerId('');
//         setCashbackAmount('');
//         // Reload customers data
//         fetchCustomers();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error assigning cashback:', error);
//       alert('ক্যাশব্যাক অ্যাসাইন করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/send-message`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           content: message,
//         }),
//       });

//       if (response.ok) {
//         alert('মেসেজ সফলভাবে পাঠানো হয়েছে!');
//         setMessage('');
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('মেসেজ পাঠাতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleApproveWithdraw = async (requestId) => {
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/approve-withdraw`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           requestId,
//         }),
//       });

//       if (response.ok) {
//         alert('উইথড্র রিকুয়েস্ট সফলভাবে অ্যাপ্রুভ করা হয়েছে।');
//         // Update withdraw requests list
//         fetchWithdrawRequests();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error approving withdraw request:', error);
//       alert('উইথড্র রিকুয়েস্ট অ্যাপ্রুভ করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     window.location.href = '/login';
//   };

//   const toggleNightMode = () => {
//     setIsNightMode(!isNightMode);
//   };

//   const bgGradient = isNightMode
//     ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
//     : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

//   const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

//   if (loadingSellerData) {
//     return (
//       <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
//         <p className="text-white">ডেটা লোড হচ্ছে...</p>
//       </div>
//     );
//   }

//   return (
//     <div
//       className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}
//     >
//       <header
//         className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
//       >
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="flex items-center space-x-2">
//             <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ সেলার হাব</span>
//           </div>
//           <nav className="hidden md:flex items-center space-x-4">
//             <Button
//               className="bg-yellow-400 text-black hover:bg-yellow-500"
//               onClick={() => setShowNotifications(!showNotifications)}
//             >
//               <Bell className="h-5 w-5" />
//             </Button>
//             <Button className="bg-green-400 text-black hover:bg-green-500">
//               <User className="h-5 w-5 mr-2" />
//               প্রোফাইল
//             </Button>
//             <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
//               <LogOut className="h-5 w-5 mr-2" />
//               লগআউট
//             </Button>
//             <Button
//               onClick={toggleNightMode}
//               className={`p-2 rounded-full ${
//                 isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//               } hover:opacity-80`}
//             >
//               {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
//             </Button>
//           </nav>
//           <Button
//             className="md:hidden bg-yellow-400 text-black"
//             onClick={() => setShowMobileMenu(!showMobileMenu)}
//           >
//             <Menu className="h-6 w-6" />
//           </Button>
//         </div>
//       </header>

//       <AnimatePresence>
//         {showMobileMenu && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//             className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
//           >
//             <div className="p-4 space-y-2">
//               <Button
//                 className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
//                 onClick={() => setShowNotifications(!showNotifications)}
//               >
//                 <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
//               </Button>
//               <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
//                 <User className="h-5 w-5 mr-2" /> প্রোফাইল
//               </Button>
//               <Button
//                 className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
//                 onClick={handleLogout}
//               >
//                 <LogOut className="h-5 w-5 mr-2" /> লগআউট
//               </Button>
//               <Button
//                 onClick={toggleNightMode}
//                 className={`flex items-center justify-center w-full ${
//                   isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//                 } hover:opacity-80 rounded-md`}
//               >
//                 {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
//                 {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
//               </Button>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>

//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//           <div>
//             <h2 className="text-3xl font-bold text-white">
//               স্বাগতম, {sellerData.name ? sellerData.name : 'সেলার'}!
//             </h2>
//             <p className="text-white opacity-80">আপনার সেলার ড্যাশবোর্ড</p>
//           </div>
//         </div>

//         <div className="mb-8">
//           <nav className="flex flex-wrap gap-4">
//             <Button
//               onClick={() => setActiveTab('overview')}
//               className={`${
//                 activeTab === 'overview'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ওভারভিউ
//             </Button>
//             <Button
//               onClick={() => setActiveTab('customers')}
//               className={`${
//                 activeTab === 'customers'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               কাস্টমার
//             </Button>
//             <Button
//               onClick={() => setActiveTab('cashback')}
//               className={`${
//                 activeTab === 'cashback'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ক্যাশব্যাক
//             </Button>
//             <Button
//               onClick={() => setActiveTab('withdraw')}
//               className={`${
//                 activeTab === 'withdraw'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               উইথড্র
//             </Button>
//           </nav>
//         </div>

//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -20 }}
//             transition={{ duration: 0.3 }}
//           >
//             {activeTab === 'overview' && (
//               <div className="space-y-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
//                   <FeatureCard
//                     icon={Gift}
//                     title="বোনাস ফান্ড"
//                     value={`৳${sellerData.bonusFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ৪০% (২ টাকা) বোনাস হিসেবে বন্টন হয়"
//                     gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
//                   />
//                   <FeatureCard
//                     icon={DollarSign}
//                     title="সেলার ইনকাম"
//                     value={`৳${sellerData.sellerIncome.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সরাসরি ইনকাম"
//                     gradient="bg-gradient-to-br from-green-400 to-teal-500"
//                   />
//                   <FeatureCard
//                     icon={Award}
//                     title="রয়্যালটি ফান্ড"
//                     value={`৳${sellerData.royaltyFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সাপ্তাহিক রয়্যালটি"
//                     gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
//                   />
//                   <FeatureCard
//                     icon={Percent}
//                     title="ইনসেন্টিভ ফান্ড"
//                     value={`৳${sellerData.incentiveFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ২০% (১ টাকা) ইনসেন্টিভ ফান্ডে জমা"
//                     gradient="bg-gradient-to-br from-purple-400 to-pink-500"
//                   />
//                   <FeatureCard
//                     icon={TrendingUp}
//                     title="কোম্পানির লাভ"
//                     value={`৳${sellerData.companyIncome.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১৫% (৭৫ পয়সা) কোম্পানির লাভ"
//                     gradient="bg-gradient-to-br from-red-400 to-pink-500"
//                   />
//                   <FeatureCard
//                     icon={Users}
//                     title="সক্রিয় কাস্টমার"
//                     value={customersData.activeCustomers.length}
//                     description="মোট সক্রিয় কাস্টমারের সংখ্যা"
//                     gradient="bg-gradient-to-br from-teal-400 to-green-500"
//                   />
//                 </div>

//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমারদের মেসেজ পাঠান</h3>
//                   <form onSubmit={handleSendMessage} className="space-y-4">
//                     <textarea
//                       value={message}
//                       onChange={(e) => setMessage(e.target.value)}
//                       placeholder="আপনার মেসেজ লিখুন..."
//                       className="w-full p-2 rounded-md bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
//                       rows={4}
//                       required
//                     />
//                     <Button type="submit" className="bg-yellow-400 text-black hover:bg-yellow-500">
//                       <Send className="h-5 w-5 mr-2" />
//                       মেসেজ পাঠান
//                     </Button>
//                   </form>
//                 </Card>
//               </div>
//             )}

//             {activeTab === 'customers' && (
//               <div className="space-y-6">
//                 <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমার</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.allCustomers && customersData.allCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.allCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-yellow-600 to-orange-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">গোল্ডেন কাস্টমার (১০০+ পয়েন্ট)</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.goldenCustomers && customersData.goldenCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.goldenCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো গোল্ডেন কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সক্রিয় কাস্টমার</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.activeCustomers && customersData.activeCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.activeCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো সক্রিয় কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-pink-600 to-red-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">
//                     উচ্চ পয়েন্টধারী কাস্টমার (১০০০+ পয়েন্ট)
//                   </h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.highPointCustomers && customersData.highPointCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.highPointCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো উচ্চ পয়েন্টধারী কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>
//               </div>
//             )}

//             {activeTab === 'cashback' && (
//               <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">ক্যাশব্যাক অ্যাসাইন করুন</h3>
//                 <form onSubmit={handleAssignCashback} className="space-y-6">
//                   <div className="space-y-2">
//                     <label htmlFor="customerId" className="block text-sm font-medium text-white">
//                       কাস্টমার আইডি
//                     </label>
//                     <input
//                       type="text"
//                       id="customerId"
//                       value={customerId}
//                       onChange={(e) => setCustomerId(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="কাস্টমার আইডি লিখুন"
//                       required
//                     />
//                   </div>
//                   <div className="space-y-2">
//                     <label htmlFor="cashbackAmount" className="block text-sm font-medium text-white">
//                       ক্যাশব্যাক পরিমাণ (৳)
//                     </label>
//                     <input
//                       type="number"
//                       id="cashbackAmount"
//                       value={cashbackAmount}
//                       onChange={(e) => setCashbackAmount(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="ক্যাশব্যাক পরিমাণ লিখুন"
//                       required
//                     />
//                   </div>
//                   <Button
//                     type="submit"
//                     className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600 transition-all duration-300"
//                   >
//                     <Gift className="h-5 w-5 mr-2" />
//                     ক্যাশব্যাক অ্যাসাইন করুন
//                   </Button>
//                 </form>
//               </Card>
//             )}

//             {activeTab === 'withdraw' && (
//               <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট</h3>
//                 {loadingWithdrawRequests ? (
//                   <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                 ) : withdrawRequests && withdrawRequests.length > 0 ? (
//                   <ul className="space-y-2">
//                     {withdrawRequests.map((request) => (
//                       <WithdrawRequestItem
//                         key={request.id}
//                         request={request}
//                         onApprove={handleApproveWithdraw}
//                       />
//                     ))}
//                   </ul>
//                 ) : (
//                   <p className="text-white">কোনো উইথড্র রিকুয়েস্ট পাওয়া যায়নি।</p>
//                 )}
//               </Card>
//             )}
//           </motion.div>
//         </AnimatePresence>
//       </main>

//       <footer
//         className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
//       >
//         <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//           <div className="mb-4 sm:mb-0">
//             <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
//             <p className="text-sm mt-2 text-white opacity-80">আপনার ব্যবসা বৃদ্ধিতে সহায়তা করছে</p>
//           </div>
//           <nav>
//             <ul className="flex space-x-4">
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   গোপনীয়তা নীতি
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   ব্যবহারের শর্তাবলী
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   যোগাযোগ করুন
//                 </a>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </footer>
//     </div>
//   );
// }




// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Bell,
//   User,
//   LogOut,
//   Menu,
//   Gift,
//   TrendingUp,
//   DollarSign,
//   Award,
//   Percent,
//   Users,
//   Send,
//   Sun,
//   Moon,
//   CheckCircle,
// } from 'lucide-react';

// // Backend API URL
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// // Button Component
// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.button>
// );

// // Card Component
// const Card = ({ children, className, ...props }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//     whileHover={{ scale: 1.02 }}
//     className={`p-6 rounded-2xl shadow-xl ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.div>
// );

// // FeatureCard Component
// const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
//   <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
//     <div className="p-3 rounded-full bg-white bg-opacity-20">
//       <Icon className="h-8 w-8 text-white" />
//     </div>
//     <div className="text-center">
//       <p className="text-lg font-medium text-white">{title}</p>
//       <p className="text-3xl font-bold text-white">{value}</p>
//       <p className="text-sm text-white opacity-80 mt-2">{description}</p>
//     </div>
//   </Card>
// );

// // CustomerItem Component
// const CustomerItem = ({ name, customerId, points }) => (
//   <motion.li
//     initial={{ opacity: 0, x: -20 }}
//     animate={{ opacity: 1, x: 0 }}
//     className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//   >
//     <div>
//       <p className="font-medium text-white">{name}</p>
//       <p className="text-sm text-white opacity-80">ID: {customerId}</p>
//     </div>
//     <div className="text-right">
//       <p className="text-lg font-bold text-yellow-300">{points} পয়েন্ট</p>
//     </div>
//   </motion.li>
// );

// // WithdrawRequestItem Component
// const WithdrawRequestItem = ({ request, onApprove }) => {
//   if (!request || !request.user) {
//     return null; // Or show a message
//   }

//   return (
//     <motion.li
//       initial={{ opacity: 0, x: -20 }}
//       animate={{ opacity: 1, x: 0 }}
//       className="flex flex-col md:flex-row md:items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//     >
//       <div className="mb-4 md:mb-0">
//         <p className="font-medium text-white">কাস্টমার ID: {request.user.customerId}</p>
//         <p className="text-sm text-white opacity-80">নাম: {request.user.name}</p>
//         <p className="text-sm text-white opacity-80">ফোন নম্বর: {request.phoneNumber}</p>
//         <p className="text-sm text-white opacity-80">পেমেন্ট মেথড: {request.paymentMethod}</p>
//         <p className="text-sm text-white opacity-80">
//           রিকুয়েস্টের তারিখ: {new Date(request.createdAt).toLocaleString()}
//         </p>
//         <p className="text-sm text-white opacity-80">স্ট্যাটাস: {request.status}</p>
//         {request.approvedAt && (
//           <p className="text-sm text-white opacity-80">
//             অ্যাপ্রুভের সময়: {new Date(request.approvedAt).toLocaleString()}
//           </p>
//         )}
//       </div>
//       <div className="text-right">
//         <p className="text-lg font-bold text-green-300">৳{request.amount}</p>
//         {request.status === 'pending' && (
//           <Button
//             className="mt-2 bg-green-500 text-white hover:bg-green-600"
//             onClick={() => onApprove(request.id)}
//           >
//             <CheckCircle className="h-5 w-5 mr-1" /> অ্যাপ্রুভ
//           </Button>
//         )}
//       </div>
//     </motion.li>
//   );
// };

// export default function SellerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview');
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [customerId, setCustomerId] = useState('');
//   const [cashbackAmount, setCashbackAmount] = useState('');
//   const [message, setMessage] = useState('');
//   const [isNightMode, setIsNightMode] = useState(false);

//   const [sellerData, setSellerData] = useState({
//     name: '',
//     bonusFund: 0,
//     sellerIncome: 0,
//     royaltyFund: 0,
//     incentiveFund: 0,
//     companyIncome: 0,
//   });

//   const [customersData, setCustomersData] = useState({
//     allCustomers: [],
//     goldenCustomers: [],
//     activeCustomers: [],
//     highPointCustomers: [],
//   });

//   const [withdrawRequests, setWithdrawRequests] = useState([]);

//   // Loading states
//   const [loadingSellerData, setLoadingSellerData] = useState(true);
//   const [loadingCustomers, setLoadingCustomers] = useState(true);
//   const [loadingWithdrawRequests, setLoadingWithdrawRequests] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       // Redirect to login page
//       window.location.href = '/login';
//     } else {
//       // Fetch seller data
//       fetchSellerFunds();
//       // Fetch customers data
//       fetchCustomers();
//       // Fetch withdraw requests
//       fetchWithdrawRequests();
//     }
//   }, []);

//   const fetchSellerFunds = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/funds`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setSellerData({
//           name: data.name || '',
//           bonusFund: data.bonusFund || 0,
//           sellerIncome: data.sellerIncome || 0,
//           royaltyFund: data.royaltyFund || 0,
//           incentiveFund: data.incentiveFund || 0,
//           companyIncome: data.companyIncome || 0,
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching seller funds:', errorText);
//         alert(`সেলার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching seller funds:', error);
//       alert('সেলার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingSellerData(false);
//     }
//   };

//   const fetchCustomers = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/customers`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setCustomersData({
//           allCustomers: data.allCustomers || [],
//           goldenCustomers: data.goldenCustomers || [],
//           activeCustomers: data.activeCustomers || [],
//           highPointCustomers: data.highPointCustomers || [],
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching customers:', errorText);
//         alert(`কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching customers:', error);
//       alert('কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingCustomers(false);
//     }
//   };

//   const fetchWithdrawRequests = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/withdraw-requests`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setWithdrawRequests(data.requests || []);
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching withdraw requests:', errorText);
//         alert(`উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching withdraw requests:', error);
//       alert('উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingWithdrawRequests(false);
//     }
//   };

//   const handleAssignCashback = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/assign-cashback`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           customerId,
//           amount: parseFloat(cashbackAmount),
//         }),
//       });

//       if (response.ok) {
//         // Show success message
//         alert('ক্যাশব্যাক সফলভাবে অ্যাসাইন করা হয়েছে!');
//         // Reset form
//         setCustomerId('');
//         setCashbackAmount('');
//         // Reload customers data
//         fetchCustomers();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error assigning cashback:', error);
//       alert('ক্যাশব্যাক অ্যাসাইন করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/send-message`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           content: message,
//         }),
//       });

//       if (response.ok) {
//         alert('মেসেজ সফলভাবে পাঠানো হয়েছে!');
//         setMessage('');
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('মেসেজ পাঠাতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleApproveWithdraw = async (requestId) => {
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/approve-withdraw`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           requestId,
//         }),
//       });

//       if (response.ok) {
//         alert('উইথড্র রিকুয়েস্ট সফলভাবে অ্যাপ্রুভ করা হয়েছে।');
//         // Update withdraw requests list
//         fetchWithdrawRequests();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error approving withdraw request:', error);
//       alert('উইথড্র রিকুয়েস্ট অ্যাপ্রুভ করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     window.location.href = '/login';
//   };

//   const toggleNightMode = () => {
//     setIsNightMode(!isNightMode);
//   };

//   const bgGradient = isNightMode
//     ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
//     : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

//   const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

//   if (loadingSellerData) {
//     return (
//       <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
//         <p className="text-white">ডেটা লোড হচ্ছে...</p>
//       </div>
//     );
//   }

//   return (
//     <div
//       className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}
//     >
//       <header
//         className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
//       >
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="flex items-center space-x-2">
//             <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ সেলার হাব</span>
//           </div>
//           <nav className="hidden md:flex items-center space-x-4">
//             <Button
//               className="bg-yellow-400 text-black hover:bg-yellow-500"
//               onClick={() => setShowNotifications(!showNotifications)}
//             >
//               <Bell className="h-5 w-5" />
//             </Button>
//             <Button className="bg-green-400 text-black hover:bg-green-500">
//               <User className="h-5 w-5 mr-2" />
//               প্রোফাইল
//             </Button>
//             <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
//               <LogOut className="h-5 w-5 mr-2" />
//               লগআউট
//             </Button>
//             <Button
//               onClick={toggleNightMode}
//               className={`p-2 rounded-full ${
//                 isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//               } hover:opacity-80`}
//             >
//               {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
//             </Button>
//           </nav>
//           <Button
//             className="md:hidden bg-yellow-400 text-black"
//             onClick={() => setShowMobileMenu(!showMobileMenu)}
//           >
//             <Menu className="h-6 w-6" />
//           </Button>
//         </div>
//       </header>

//       <AnimatePresence>
//         {showMobileMenu && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//             className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
//           >
//             <div className="p-4 space-y-2">
//               <Button
//                 className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
//                 onClick={() => setShowNotifications(!showNotifications)}
//               >
//                 <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
//               </Button>
//               <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
//                 <User className="h-5 w-5 mr-2" /> প্রোফাইল
//               </Button>
//               <Button
//                 className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
//                 onClick={handleLogout}
//               >
//                 <LogOut className="h-5 w-5 mr-2" /> লগআউট
//               </Button>
//               <Button
//                 onClick={toggleNightMode}
//                 className={`flex items-center justify-center w-full ${
//                   isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//                 } hover:opacity-80 rounded-md`}
//               >
//                 {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
//                 {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
//               </Button>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>

//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//           <div>
//             <h2 className="text-3xl font-bold text-white">
//               স্বাগতম, {sellerData.name ? sellerData.name : 'সেলার'}!
//             </h2>
//             <p className="text-white opacity-80">আপনার সেলার ড্যাশবোর্ড</p>
//           </div>
//         </div>

//         <div className="mb-8">
//           <nav className="flex flex-wrap gap-4">
//             <Button
//               onClick={() => setActiveTab('overview')}
//               className={`${
//                 activeTab === 'overview'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ওভারভিউ
//             </Button>
//             <Button
//               onClick={() => setActiveTab('customers')}
//               className={`${
//                 activeTab === 'customers'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               কাস্টমার
//             </Button>
//             <Button
//               onClick={() => setActiveTab('cashback')}
//               className={`${
//                 activeTab === 'cashback'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ক্যাশব্যাক
//             </Button>
//             <Button
//               onClick={() => setActiveTab('withdraw')}
//               className={`${
//                 activeTab === 'withdraw'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               উইথড্র
//             </Button>
//           </nav>
//         </div>

//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -20 }}
//             transition={{ duration: 0.3 }}
//           >
//             {activeTab === 'overview' && (
//               <div className="space-y-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
//                   <FeatureCard
//                     icon={Gift}
//                     title="বোনাস ফান্ড"
//                     value={`৳${sellerData.bonusFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ৪০% (২ টাকা) বোনাস হিসেবে বন্টন হয়"
//                     gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
//                   />
//                   <FeatureCard
//                     icon={DollarSign}
//                     title="সেলার ইনকাম"
//                     value={`৳${sellerData.sellerIncome.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সরাসরি ইনকাম"
//                     gradient="bg-gradient-to-br from-green-400 to-teal-500"
//                   />
//                   <FeatureCard
//                     icon={Award}
//                     title="রয়্যালটি ফান্ড"
//                     value={`৳${sellerData.royaltyFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সাপ্তাহিক রয়্যালটি"
//                     gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
//                   />
//                   <FeatureCard
//                     icon={Percent}
//                     title="ইনসেন্টিভ ফান্ড"
//                     value={`৳${sellerData.incentiveFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ২০% (১ টাকা) ইনসেন্টিভ ফান্ডে জমা"
//                     gradient="bg-gradient-to-br from-purple-400 to-pink-500"
//                   />
//                   <FeatureCard
//                     icon={TrendingUp}
//                     title="কোম্পানির লাভ"
//                     value={`৳${sellerData.companyIncome.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১৫% (৭৫ পয়সা) কোম্পানির লাভ"
//                     gradient="bg-gradient-to-br from-red-400 to-pink-500"
//                   />
//                   <FeatureCard
//                     icon={Users}
//                     title="সক্রিয় কাস্টমার"
//                     value={customersData.activeCustomers.length}
//                     description="মোট সক্রিয় কাস্টমারের সংখ্যা"
//                     gradient="bg-gradient-to-br from-teal-400 to-green-500"
//                   />
//                 </div>

//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমারদের মেসেজ পাঠান</h3>
//                   <form onSubmit={handleSendMessage} className="space-y-4">
//                     <textarea
//                       value={message}
//                       onChange={(e) => setMessage(e.target.value)}
//                       placeholder="আপনার মেসেজ লিখুন..."
//                       className="w-full p-2 rounded-md bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
//                       rows={4}
//                       required
//                     />
//                     <Button type="submit" className="bg-yellow-400 text-black hover:bg-yellow-500">
//                       <Send className="h-5 w-5 mr-2" />
//                       মেসেজ পাঠান
//                     </Button>
//                   </form>
//                 </Card>
//               </div>
//             )}

//             {activeTab === 'customers' && (
//               <div className="space-y-6">
//                 <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমার</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.allCustomers && customersData.allCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.allCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-yellow-600 to-orange-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">গোল্ডেন কাস্টমার (১০০+ পয়েন্ট)</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.goldenCustomers && customersData.goldenCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.goldenCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো গোল্ডেন কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সক্রিয় কাস্টমার</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.activeCustomers && customersData.activeCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.activeCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো সক্রিয় কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-pink-600 to-red-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">
//                     উচ্চ পয়েন্টধারী কাস্টমার (১০০০+ পয়েন্ট)
//                   </h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.highPointCustomers && customersData.highPointCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.highPointCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো উচ্চ পয়েন্টধারী কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>
//               </div>
//             )}

//             {activeTab === 'cashback' && (
//               <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">ক্যাশব্যাক অ্যাসাইন করুন</h3>
//                 <form onSubmit={handleAssignCashback} className="space-y-6">
//                   <div className="space-y-2">
//                     <label htmlFor="customerId" className="block text-sm font-medium text-white">
//                       কাস্টমার আইডি
//                     </label>
//                     <input
//                       type="text"
//                       id="customerId"
//                       value={customerId}
//                       onChange={(e) => setCustomerId(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="কাস্টমার আইডি লিখুন"
//                       required
//                     />
//                   </div>
//                   <div className="space-y-2">
//                     <label htmlFor="cashbackAmount" className="block text-sm font-medium text-white">
//                       ক্যাশব্যাক পরিমাণ (৳)
//                     </label>
//                     <input
//                       type="number"
//                       id="cashbackAmount"
//                       value={cashbackAmount}
//                       onChange={(e) => setCashbackAmount(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="ক্যাশব্যাক পরিমাণ লিখুন"
//                       required
//                     />
//                   </div>
//                   <Button
//                     type="submit"
//                     className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600 transition-all duration-300"
//                   >
//                     <Gift className="h-5 w-5 mr-2" />
//                     ক্যাশব্যাক অ্যাসাইন করুন
//                   </Button>
//                 </form>
//               </Card>
//             )}

//             {activeTab === 'withdraw' && (
//               <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট এবং লগ</h3>
//                 {loadingWithdrawRequests ? (
//                   <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                 ) : withdrawRequests && withdrawRequests.length > 0 ? (
//                   <ul className="space-y-2">
//                     {withdrawRequests.map((request) => (
//                       <WithdrawRequestItem
//                         key={request.id}
//                         request={request}
//                         onApprove={handleApproveWithdraw}
//                       />
//                     ))}
//                   </ul>
//                 ) : (
//                   <p className="text-white">কোনো উইথড্র রিকুয়েস্ট পাওয়া যায়নি।</p>
//                 )}
//               </Card>
//             )}
//           </motion.div>
//         </AnimatePresence>
//       </main>

//       <footer
//         className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
//       >
//         <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//           <div className="mb-4 sm:mb-0">
//             <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
//             <p className="text-sm mt-2 text-white opacity-80">আপনার ব্যবসা বৃদ্ধিতে সহায়তা করছে</p>
//           </div>
//           <nav>
//             <ul className="flex space-x-4">
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   গোপনীয়তা নীতি
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   ব্যবহারের শর্তাবলী
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   যোগাযোগ করুন
//                 </a>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </footer>
//     </div>
//   );
// }




// SellerDashboard.jsx

// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Bell,
//   User,
//   LogOut,
//   Menu,
//   Gift,
//   TrendingUp,
//   DollarSign,
//   Award,
//   Percent,
//   Users,
//   Send,
//   Sun,
//   Moon,
//   CheckCircle,
// } from 'lucide-react';

// // Backend API URL
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// // Button Component
// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.button>
// );

// // Card Component
// const Card = ({ children, className, ...props }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//     whileHover={{ scale: 1.02 }}
//     className={`p-6 rounded-2xl shadow-xl ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.div>
// );

// // FeatureCard Component
// const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
//   <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
//     <div className="p-3 rounded-full bg-white bg-opacity-20">
//       <Icon className="h-8 w-8 text-white" />
//     </div>
//     <div className="text-center">
//       <p className="text-lg font-medium text-white">{title}</p>
//       <p className="text-3xl font-bold text-white">{value}</p>
//       <p className="text-sm text-white opacity-80 mt-2">{description}</p>
//     </div>
//   </Card>
// );

// // CustomerItem Component
// const CustomerItem = ({ name, customerId, points }) => (
//   <motion.li
//     initial={{ opacity: 0, x: -20 }}
//     animate={{ opacity: 1, x: 0 }}
//     className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//   >
//     <div>
//       <p className="font-medium text-white">{name}</p>
//       <p className="text-sm text-white opacity-80">ID: {customerId}</p>
//     </div>
//     <div className="text-right">
//       <p className="text-lg font-bold text-yellow-300">{points} পয়েন্ট</p>
//     </div>
//   </motion.li>
// );

// // WithdrawRequestItem Component
// const WithdrawRequestItem = ({ request, onApprove }) => {
//   if (!request || !request.user) {
//     return null; // অথবা একটি মেসেজ দেখাতে পারেন
//   }

//   return (
//     <motion.li
//       initial={{ opacity: 0, x: -20 }}
//       animate={{ opacity: 1, x: 0 }}
//       className="flex flex-col md:flex-row md:items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//     >
//       <div className="mb-4 md:mb-0">
//         <p className="font-medium text-white">কাস্টমার ID: {request.user.customerId}</p>
//         <p className="text-sm text-white opacity-80">নাম: {request.user.name}</p>
//         <p className="text-sm text-white opacity-80">ফোন নম্বর: {request.phoneNumber}</p>
//         <p className="text-sm text-white opacity-80">পেমেন্ট মেথড: {request.paymentMethod}</p>
//         <p className="text-sm text-white opacity-80">
//           রিকুয়েস্টের তারিখ: {new Date(request.createdAt).toLocaleString()}
//         </p>
//         <p className="text-sm text-white opacity-80">স্ট্যাটাস: {request.status}</p>
//         {request.approvedAt && (
//           <p className="text-sm text-white opacity-80">
//             অ্যাপ্রুভের সময়: {new Date(request.approvedAt).toLocaleString()}
//           </p>
//         )}
//       </div>
//       <div className="text-right">
//         <p className="text-lg font-bold text-green-300">৳{request.amount}</p>
//         {request.status === 'pending' && (
//           <Button
//             className="mt-2 bg-green-500 text-white hover:bg-green-600"
//             onClick={() => onApprove(request.id)}
//           >
//             <CheckCircle className="h-5 w-5 mr-1" /> অ্যাপ্রুভ
//           </Button>
//         )}
//       </div>
//     </motion.li>
//   );
// };

// export default function SellerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview');
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [customerId, setCustomerId] = useState('');
//   const [cashbackAmount, setCashbackAmount] = useState('');
//   const [message, setMessage] = useState('');
//   const [isNightMode, setIsNightMode] = useState(false);

//   const [sellerData, setSellerData] = useState({
//     name: '',
//     bonusFund: 0,
//     sellerIncome: 0,
//     royaltyFund: 0,
//     incentiveFund: 0,
//     companyIncome: 0,
//   });

//   const [customersData, setCustomersData] = useState({
//     allCustomers: [],
//     goldenCustomers: [],
//     activeCustomers: [],
//     highPointCustomers: [],
//   });

//   const [withdrawRequests, setWithdrawRequests] = useState([]);

//   // Loading states
//   const [loadingSellerData, setLoadingSellerData] = useState(true);
//   const [loadingCustomers, setLoadingCustomers] = useState(true);
//   const [loadingWithdrawRequests, setLoadingWithdrawRequests] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       // লগইন পেজে রিডাইরেক্ট করুন
//       window.location.href = '/login';
//     } else {
//       // সেলার তথ্য ফেচ করুন
//       fetchSellerFunds();
//       // কাস্টমার তথ্য ফেচ করুন
//       fetchCustomers();
//       // উইথড্র রিকুয়েস্ট ফেচ করুন
//       fetchWithdrawRequests();
//     }
//   }, []);

//   const fetchSellerFunds = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/funds`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setSellerData({
//           name: data.name || '',
//           bonusFund: data.bonusFund || 0,
//           sellerIncome: data.sellerIncome || 0,
//           royaltyFund: data.royaltyFund || 0,
//           incentiveFund: data.incentiveFund || 0,
//           companyIncome: data.companyIncome || 0,
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching seller funds:', errorText);
//         alert(`সেলার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching seller funds:', error);
//       alert('সেলার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingSellerData(false);
//     }
//   };

//   const fetchCustomers = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/customers`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setCustomersData({
//           allCustomers: data.allCustomers || [],
//           goldenCustomers: data.goldenCustomers || [],
//           activeCustomers: data.activeCustomers || [],
//           highPointCustomers: data.highPointCustomers || [],
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching customers:', errorText);
//         alert(`কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching customers:', error);
//       alert('কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingCustomers(false);
//     }
//   };

//   const fetchWithdrawRequests = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/withdraw-requests`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setWithdrawRequests(data.requests || []);
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching withdraw requests:', errorText);
//         alert(`উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching withdraw requests:', error);
//       alert('উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingWithdrawRequests(false);
//     }
//   };

//   const handleAssignCashback = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/assign-cashback`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           customerId,
//           amount: parseFloat(cashbackAmount),
//         }),
//       });

//       if (response.ok) {
//         // সফল মেসেজ দেখান
//         alert('ক্যাশব্যাক সফলভাবে অ্যাসাইন করা হয়েছে!');
//         // ফর্ম রিসেট করুন
//         setCustomerId('');
//         setCashbackAmount('');
//         // কাস্টমার তথ্য রিফ্রেশ করুন
//         fetchCustomers();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error assigning cashback:', error);
//       alert('ক্যাশব্যাক অ্যাসাইন করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/send-message`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           content: message,
//         }),
//       });

//       if (response.ok) {
//         alert('মেসেজ সফলভাবে পাঠানো হয়েছে!');
//         setMessage('');
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('মেসেজ পাঠাতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleApproveWithdraw = async (requestId) => {
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/approve-withdraw`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           requestId,
//         }),
//       });

//       if (response.ok) {
//         alert('উইথড্র রিকুয়েস্ট সফলভাবে অ্যাপ্রুভ করা হয়েছে।');
//         // উইথড্র রিকুয়েস্ট তালিকা আপডেট করুন
//         fetchWithdrawRequests();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error approving withdraw request:', error);
//       alert('উইথড্র রিকুয়েস্ট অ্যাপ্রুভ করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     window.location.href = '/login';
//   };

//   const toggleNightMode = () => {
//     setIsNightMode(!isNightMode);
//   };

//   const bgGradient = isNightMode
//     ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
//     : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

//   const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

//   if (loadingSellerData) {
//     return (
//       <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
//         <p className="text-white">ডেটা লোড হচ্ছে...</p>
//       </div>
//     );
//   }

//   return (
//     <div className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}>
//       <header
//         className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
//       >
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="flex items-center space-x-2">
//             <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ সেলার হাব</span>
//           </div>
//           <nav className="hidden md:flex items-center space-x-4">
//             <Button
//               className="bg-yellow-400 text-black hover:bg-yellow-500"
//               onClick={() => setShowNotifications(!showNotifications)}
//             >
//               <Bell className="h-5 w-5" />
//             </Button>
//             <Button className="bg-green-400 text-black hover:bg-green-500">
//               <User className="h-5 w-5 mr-2" />
//               প্রোফাইল
//             </Button>
//             <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
//               <LogOut className="h-5 w-5 mr-2" />
//               লগআউট
//             </Button>
//             <Button
//               onClick={toggleNightMode}
//               className={`p-2 rounded-full ${
//                 isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//               } hover:opacity-80`}
//             >
//               {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
//             </Button>
//           </nav>
//           <Button className="md:hidden bg-yellow-400 text-black" onClick={() => setShowMobileMenu(!showMobileMenu)}>
//             <Menu className="h-6 w-6" />
//           </Button>
//         </div>
//       </header>

//       <AnimatePresence>
//         {showMobileMenu && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//             className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
//           >
//             <div className="p-4 space-y-2">
//               <Button
//                 className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
//                 onClick={() => setShowNotifications(!showNotifications)}
//               >
//                 <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
//               </Button>
//               <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
//                 <User className="h-5 w-5 mr-2" /> প্রোফাইল
//               </Button>
//               <Button
//                 className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
//                 onClick={handleLogout}
//               >
//                 <LogOut className="h-5 w-5 mr-2" /> লগআউট
//               </Button>
//               <Button
//                 onClick={toggleNightMode}
//                 className={`flex items-center justify-center w-full ${
//                   isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//                 } hover:opacity-80 rounded-md`}
//               >
//                 {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
//                 {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
//               </Button>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>

//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//           <div>
//             <h2 className="text-3xl font-bold text-white">
//               স্বাগতম, {sellerData.name ? sellerData.name : 'সেলার'}!
//             </h2>
//             <p className="text-white opacity-80">আপনার সেলার ড্যাশবোর্ড</p>
//           </div>
//         </div>

//         <div className="mb-8">
//           <nav className="flex flex-wrap gap-4">
//             <Button
//               onClick={() => setActiveTab('overview')}
//               className={`${
//                 activeTab === 'overview'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ওভারভিউ
//             </Button>
//             <Button
//               onClick={() => setActiveTab('customers')}
//               className={`${
//                 activeTab === 'customers'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               কাস্টমার
//             </Button>
//             <Button
//               onClick={() => setActiveTab('cashback')}
//               className={`${
//                 activeTab === 'cashback'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ক্যাশব্যাক
//             </Button>
//             <Button
//               onClick={() => setActiveTab('withdraw')}
//               className={`${
//                 activeTab === 'withdraw'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               উইথড্র
//             </Button>
//           </nav>
//         </div>

//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -20 }}
//             transition={{ duration: 0.3 }}
//           >
//             {activeTab === 'overview' && (
//               <div className="space-y-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
//                   <FeatureCard
//                     icon={Gift}
//                     title="বোনাস ফান্ড"
//                     value={`৳${sellerData.bonusFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ৪০% (২ টাকা) বোনাস হিসেবে বন্টন হয়"
//                     gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
//                   />
//                   <FeatureCard
//                     icon={DollarSign}
//                     title="সেলার ইনকাম"
//                     value={`৳${sellerData.sellerIncome.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সরাসরি ইনকাম"
//                     gradient="bg-gradient-to-br from-green-400 to-teal-500"
//                   />
//                   <FeatureCard
//                     icon={Award}
//                     title="রয়্যালটি ফান্ড"
//                     value={`৳${sellerData.royaltyFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সাপ্তাহিক রয়্যালটি"
//                     gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
//                   />
//                   <FeatureCard
//                     icon={Percent}
//                     title="ইনসেন্টিভ ফান্ড"
//                     value={`৳${sellerData.incentiveFund.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ২০% (১ টাকা) ইনসেন্টিভ ফান্ডে জমা"
//                     gradient="bg-gradient-to-br from-purple-400 to-pink-500"
//                   />
//                   <FeatureCard
//                     icon={TrendingUp}
//                     title="কোম্পানির লাভ"
//                     value={`৳${sellerData.companyIncome.toFixed(2)}`}
//                     description="প্রতি ৫ টাকার ১৫% (৭৫ পয়সা) কোম্পানির লাভ"
//                     gradient="bg-gradient-to-br from-red-400 to-pink-500"
//                   />
//                   <FeatureCard
//                     icon={Users}
//                     title="সক্রিয় কাস্টমার"
//                     value={customersData.activeCustomers.length}
//                     description="মোট সক্রিয় কাস্টমারের সংখ্যা"
//                     gradient="bg-gradient-to-br from-teal-400 to-green-500"
//                   />
//                 </div>

//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমারদের মেসেজ পাঠান</h3>
//                   <form onSubmit={handleSendMessage} className="space-y-4">
//                     <textarea
//                       value={message}
//                       onChange={(e) => setMessage(e.target.value)}
//                       placeholder="আপনার মেসেজ লিখুন..."
//                       className="w-full p-2 rounded-md bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
//                       rows={4}
//                       required
//                     />
//                     <Button type="submit" className="bg-yellow-400 text-black hover:bg-yellow-500">
//                       <Send className="h-5 w-5 mr-2" />
//                       মেসেজ পাঠান
//                     </Button>
//                   </form>
//                 </Card>
//               </div>
//             )}

//             {activeTab === 'customers' && (
//               <div className="space-y-6">
//                 <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমার</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.allCustomers && customersData.allCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.allCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-yellow-600 to-orange-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">গোল্ডেন কাস্টমার (১০০+ পয়েন্ট)</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.goldenCustomers && customersData.goldenCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.goldenCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো গোল্ডেন কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">সক্রিয় কাস্টমার</h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.activeCustomers && customersData.activeCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.activeCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো সক্রিয় কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>

//                 <Card className="bg-gradient-to-br from-pink-600 to-red-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">
//                     উচ্চ পয়েন্টধারী কাস্টমার (১০০০+ পয়েন্ট)
//                   </h3>
//                   {loadingCustomers ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : customersData.highPointCustomers && customersData.highPointCustomers.length > 0 ? (
//                     <ul className="space-y-2">
//                       {customersData.highPointCustomers.map((customer) => (
//                         <CustomerItem
//                           key={customer.id}
//                           name={customer.name}
//                           customerId={customer.customerId}
//                           points={customer.points}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো উচ্চ পয়েন্টধারী কাস্টমার পাওয়া যায়নি।</p>
//                   )}
//                 </Card>
//               </div>
//             )}

//             {activeTab === 'cashback' && (
//               <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">ক্যাশব্যাক অ্যাসাইন করুন</h3>
//                 <form onSubmit={handleAssignCashback} className="space-y-6">
//                   <div className="space-y-2">
//                     <label htmlFor="customerId" className="block text-sm font-medium text-white">
//                       কাস্টমার আইডি
//                     </label>
//                     <input
//                       type="text"
//                       id="customerId"
//                       value={customerId}
//                       onChange={(e) => setCustomerId(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="কাস্টমার আইডি লিখুন"
//                       required
//                     />
//                   </div>
//                   <div className="space-y-2">
//                     <label htmlFor="cashbackAmount" className="block text-sm font-medium text-white">
//                       ক্যাশব্যাক পরিমাণ (৳)
//                     </label>
//                     <input
//                       type="number"
//                       id="cashbackAmount"
//                       value={cashbackAmount}
//                       onChange={(e) => setCashbackAmount(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="ক্যাশব্যাক পরিমাণ লিখুন"
//                       required
//                     />
//                   </div>
//                   <Button
//                     type="submit"
//                     className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600 transition-all duration-300"
//                   >
//                     <Gift className="h-5 w-5 mr-2" />
//                     ক্যাশব্যাক অ্যাসাইন করুন
//                   </Button>
//                 </form>
//               </Card>
//             )}

//             {activeTab === 'withdraw' && (
//               <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট এবং লগ</h3>
//                 {loadingWithdrawRequests ? (
//                   <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                 ) : withdrawRequests && withdrawRequests.length > 0 ? (
//                   <ul className="space-y-2">
//                     {withdrawRequests.map((request) => (
//                       <WithdrawRequestItem
//                         key={request.id}
//                         request={request}
//                         onApprove={handleApproveWithdraw}
//                       />
//                     ))}
//                   </ul>
//                 ) : (
//                   <p className="text-white">কোনো উইথড্র রিকুয়েস্ট পাওয়া যায়নি।</p>
//                 )}
//               </Card>
//             )}
//           </motion.div>
//         </AnimatePresence>
//       </main>

//       <footer
//         className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
//       >
//         <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//           <div className="mb-4 sm:mb-0">
//             <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
//             <p className="text-sm mt-2 text-white opacity-80">আপনার ব্যবসা বৃদ্ধিতে সহায়তা করছে</p>
//           </div>
//           <nav>
//             <ul className="flex space-x-4">
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   গোপনীয়তা নীতি
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   ব্যবহারের শর্তাবলী
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   যোগাযোগ করুন
//                 </a>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </footer>
//     </div>
//   );
// }



// src/Pages/SellerDashboard.jsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Bell,
  User,
  LogOut,
  Menu,
  Gift,
  TrendingUp,
  DollarSign,
  Award,
  Percent,
  Users,
  Send,
  Sun,
  Moon,
  CheckCircle,
} from 'lucide-react';
import { useTheme } from '../ThemeContext'; // ThemeContext থেকে useTheme হুক ইমপোর্ট

// Backend API URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// Button Component
const Button = ({ children, className, ...props }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
    {...props}
  >
    {children}
  </motion.button>
);

// Card Component
const Card = ({ children, className, ...props }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.02 }}
    className={`p-6 rounded-2xl shadow-xl ${className}`}
    {...props}
  >
    {children}
  </motion.div>
);

// FeatureCard Component
const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
  <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
    <div className="p-3 rounded-full bg-white bg-opacity-20">
      <Icon className="h-8 w-8 text-white" />
    </div>
    <div className="text-center">
      <p className="text-lg font-medium text-white">{title}</p>
      <p className="text-3xl font-bold text-white">{value}</p>
      <p className="text-sm text-white opacity-80 mt-2">{description}</p>
    </div>
  </Card>
);

// CustomerItem Component
const CustomerItem = ({ name, customerId, points }) => (
  <motion.li
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
  >
    <div>
      <p className="font-medium text-white">{name}</p>
      <p className="text-sm text-white opacity-80">ID: {customerId}</p>
    </div>
    <div className="text-right">
      <p className="text-lg font-bold text-yellow-300">{points} পয়েন্ট</p>
    </div>
  </motion.li>
);

// WithdrawRequestItem Component
const WithdrawRequestItem = ({ request, onApprove }) => {
  if (!request || !request.user) {
    return null; // অথবা একটি মেসেজ দেখাতে পারেন
  }

  return (
    <motion.li
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      className="flex flex-col md:flex-row md:items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
    >
      <div className="mb-4 md:mb-0">
        <p className="font-medium text-white">কাস্টমার ID: {request.user.customerId}</p>
        <p className="text-sm text-white opacity-80">নাম: {request.user.name}</p>
        <p className="text-sm text-white opacity-80">ফোন নম্বর: {request.phoneNumber}</p>
        <p className="text-sm text-white opacity-80">পেমেন্ট মেথড: {request.paymentMethod}</p>
        <p className="text-sm text-white opacity-80">
          রিকুয়েস্টের তারিখ: {new Date(request.createdAt).toLocaleString()}
        </p>
        <p className="text-sm text-white opacity-80">স্ট্যাটাস: {request.status}</p>
        {request.approvedAt && (
          <p className="text-sm text-white opacity-80">
            অ্যাপ্রুভের সময়: {new Date(request.approvedAt).toLocaleString()}
          </p>
        )}
      </div>
      <div className="text-right">
        <p className="text-lg font-bold text-green-300">৳{request.amount}</p>
        {request.status === 'pending' && (
          <Button
            className="mt-2 bg-green-500 text-white hover:bg-green-600"
            onClick={() => onApprove(request.id)}
          >
            <CheckCircle className="h-5 w-5 mr-1" /> অ্যাপ্রুভ
          </Button>
        )}
      </div>
    </motion.li>
  );
};

export default function SellerDashboard() {
  const { isNightMode, toggleNightMode } = useTheme(); // ThemeContext থেকে থিম এবং টগল ফাংশন
  const [activeTab, setActiveTab] = useState('overview');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [cashbackAmount, setCashbackAmount] = useState('');
  const [message, setMessage] = useState('');

  const [sellerData, setSellerData] = useState({
    name: '',
    bonusFund: 0,
    sellerIncome: 0,
    royaltyFund: 0,
    incentiveFund: 0,
    companyIncome: 0,
  });

  const [customersData, setCustomersData] = useState({
    allCustomers: [],
    goldenCustomers: [],
    activeCustomers: [],
    highPointCustomers: [],
  });

  const [withdrawRequests, setWithdrawRequests] = useState([]);

  // Loading states
  const [loadingSellerData, setLoadingSellerData] = useState(true);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingWithdrawRequests, setLoadingWithdrawRequests] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      // লগইন পেজে রিডাইরেক্ট করুন
      window.location.href = '/login';
    } else {
      // সেলার তথ্য ফেচ করুন
      fetchSellerFunds();
      // কাস্টমার তথ্য ফেচ করুন
      fetchCustomers();
      // উইথড্র রিকুয়েস্ট ফেচ করুন
      fetchWithdrawRequests();
    }
  }, []);

  const fetchSellerFunds = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}dashboard/seller/funds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSellerData({
          name: data.name || '',
          bonusFund: data.bonusFund || 0,
          sellerIncome: data.sellerIncome || 0,
          royaltyFund: data.royaltyFund || 0,
          incentiveFund: data.incentiveFund || 0,
          companyIncome: data.companyIncome || 0,
        });
      } else {
        const errorText = await response.text();
        console.error('Error fetching seller funds:', errorText);
        alert(`সেলার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching seller funds:', error);
      alert('সেলার তথ্য লোড করতে সমস্যা হয়েছে।');
    } finally {
      setLoadingSellerData(false);
    }
  };

  const fetchCustomers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}dashboard/seller/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setCustomersData({
          allCustomers: data.allCustomers || [],
          goldenCustomers: data.goldenCustomers || [],
          activeCustomers: data.activeCustomers || [],
          highPointCustomers: data.highPointCustomers || [],
        });
      } else {
        const errorText = await response.text();
        console.error('Error fetching customers:', errorText);
        alert(`কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      alert('কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে।');
    } finally {
      setLoadingCustomers(false);
    }
  };

  const fetchWithdrawRequests = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${API_BASE_URL}dashboard/seller/withdraw-requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setWithdrawRequests(data.requests || []);
      } else {
        const errorText = await response.text();
        console.error('Error fetching withdraw requests:', errorText);
        alert(`উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching withdraw requests:', error);
      alert('উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে।');
    } finally {
      setLoadingWithdrawRequests(false);
    }
  };

  const handleAssignCashback = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_BASE_URL}dashboard/seller/assign-cashback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          customerId,
          amount: parseFloat(cashbackAmount),
        }),
      });

      if (response.ok) {
        // সফল মেসেজ দেখান
        alert('ক্যাশব্যাক সফলভাবে অ্যাসাইন করা হয়েছে!');
        // ফর্ম রিসেট করুন
        setCustomerId('');
        setCashbackAmount('');
        // কাস্টমার তথ্য রিফ্রেশ করুন
        fetchCustomers();
      } else {
        const errorText = await response.text();
        alert(`এরর: ${errorText}`);
      }
    } catch (error) {
      console.error('Error assigning cashback:', error);
      alert('ক্যাশব্যাক অ্যাসাইন করতে সমস্যা হয়েছে।');
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_BASE_URL}dashboard/seller/send-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          content: message,
        }),
      });

      if (response.ok) {
        alert('মেসেজ সফলভাবে পাঠানো হয়েছে!');
        setMessage('');
      } else {
        const errorText = await response.text();
        alert(`এরর: ${errorText}`);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('মেসেজ পাঠাতে সমস্যা হয়েছে।');
    }
  };

  const handleApproveWithdraw = async (requestId) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${API_BASE_URL}dashboard/seller/approve-withdraw`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          requestId,
        }),
      });

      if (response.ok) {
        alert('উইথড্র রিকুয়েস্ট সফলভাবে অ্যাপ্রুভ করা হয়েছে।');
        // উইথড্র রিকুয়েস্ট তালিকা আপডেট করুন
        fetchWithdrawRequests();
      } else {
        const errorText = await response.text();
        alert(`এরর: ${errorText}`);
      }
    } catch (error) {
      console.error('Error approving withdraw request:', error);
      alert('উইথড্র রিকুয়েস্ট অ্যাপ্রুভ করতে সমস্যা হয়েছে।');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const bgGradient = isNightMode
    ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
    : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

  const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

  if (loadingSellerData) {
    return (
      <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
        <p className="text-white">ডেটা লোড হচ্ছে...</p>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}>
      <header
        className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
      >
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ সেলার হাব</span>
          </div>
          <nav className="hidden md:flex items-center space-x-4">
            <Button
              className="bg-yellow-400 text-black hover:bg-yellow-500"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <Bell className="h-5 w-5" />
            </Button>
            <Button className="bg-green-400 text-black hover:bg-green-500">
              <User className="h-5 w-5 mr-2" />
              প্রোফাইল
            </Button>
            <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
              <LogOut className="h-5 w-5 mr-2" />
              লগআউট
            </Button>
            <Button
              onClick={toggleNightMode}
              className={`p-2 rounded-full ${
                isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
              } hover:opacity-80`}
            >
              {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
            </Button>
          </nav>
          <Button className="md:hidden bg-yellow-400 text-black" onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <Menu className="h-6 w-6" />
          </Button>
        </div>
      </header>

      <AnimatePresence>
        {showMobileMenu && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
          >
            <div className="p-4 space-y-2">
              <Button
                className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
              </Button>
              <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
                <User className="h-5 w-5 mr-2" /> প্রোফাইল
              </Button>
              <Button
                className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
                onClick={handleLogout}
              >
                <LogOut className="h-5 w-5 mr-2" /> লগআউট
              </Button>
              <Button
                onClick={toggleNightMode}
                className={`flex items-center justify-center w-full ${
                  isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
                } hover:opacity-80 rounded-md`}
              >
                {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
                {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
          <div>
            <h2 className="text-3xl font-bold text-white">
              স্বাগতম, {sellerData.name ? sellerData.name : 'সেলার'}!
            </h2>
            <p className="text-white opacity-80">আপনার সেলার ড্যাশবোর্ড</p>
          </div>
        </div>

        <div className="mb-8">
          <nav className="flex flex-wrap gap-4">
            <Button
              onClick={() => setActiveTab('overview')}
              className={`${
                activeTab === 'overview'
                  ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              }`}
            >
              ওভারভিউ
            </Button>
            <Button
              onClick={() => setActiveTab('customers')}
              className={`${
                activeTab === 'customers'
                  ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              }`}
            >
              কাস্টমার
            </Button>
            <Button
              onClick={() => setActiveTab('cashback')}
              className={`${
                activeTab === 'cashback'
                  ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              }`}
            >
              ক্যাশব্যাক
            </Button>
            <Button
              onClick={() => setActiveTab('withdraw')}
              className={`${
                activeTab === 'withdraw'
                  ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              }`}
            >
              উইথড্র
            </Button>
          </nav>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeTab === 'overview' && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
                  <FeatureCard
                    icon={Gift}
                    title="বোনাস ফান্ড"
                    value={`৳${sellerData.bonusFund.toFixed(2)}`}
                    description="প্রতি ৫ টাকার ৪০% (২ টাকা) বোনাস হিসেবে বন্টন হয়"
                    gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
                  />
                  <FeatureCard
                    icon={DollarSign}
                    title="সেলার ইনকাম"
                    value={`৳${sellerData.sellerIncome.toFixed(2)}`}
                    description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সরাসরি ইনকাম"
                    gradient="bg-gradient-to-br from-green-400 to-teal-500"
                  />
                  <FeatureCard
                    icon={Award}
                    title="রয়্যালটি ফান্ড"
                    value={`৳${sellerData.royaltyFund.toFixed(2)}`}
                    description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সাপ্তাহিক রয়্যালটি"
                    gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
                  />
                  <FeatureCard
                    icon={Percent}
                    title="ইনসেন্টিভ ফান্ড"
                    value={`৳${sellerData.incentiveFund.toFixed(2)}`}
                    description="প্রতি ৫ টাকার ২০% (১ টাকা) ইনসেন্টিভ ফান্ডে জমা"
                    gradient="bg-gradient-to-br from-purple-400 to-pink-500"
                  />
                  <FeatureCard
                    icon={TrendingUp}
                    title="কোম্পানির লাভ"
                    value={`৳${sellerData.companyIncome.toFixed(2)}`}
                    description="প্রতি ৫ টাকার ১৫% (৭৫ পয়সা) কোম্পানির লাভ"
                    gradient="bg-gradient-to-br from-red-400 to-pink-500"
                  />
                  <FeatureCard
                    icon={Users}
                    title="সক্রিয় কাস্টমার"
                    value={customersData.activeCustomers.length}
                    description="মোট সক্রিয় কাস্টমারের সংখ্যা"
                    gradient="bg-gradient-to-br from-teal-400 to-green-500"
                  />
                </div>

                <Card className="bg-gradient-to-br from-green-600 to-teal-700">
                  <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমারদের মেসেজ পাঠান</h3>
                  <form onSubmit={handleSendMessage} className="space-y-4">
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      
                      placeholder="আপনার মেসেজ লিখুন..."
                      className="w-full p-2 rounded-md bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                      rows={4}
                      required
                    />
              <Button type="submit" className="bg-yellow-400 text-black hover:bg-yellow-500 flex items-center justify-center space-x-2">
  <Send className="h-7 w-5" />
  <span>মেসেজ পাঠান</span>
</Button>

                  </form>
                </Card>
              </div>
            )}

            {activeTab === 'customers' && (
              <div className="space-y-6">
               
                               <Card className="bg-gradient-to-br from-green-600 to-teal-700">
                  <h3 className="text-2xl font-bold mb-4 text-white">সক্রিয় কাস্টমার</h3>
                  {loadingCustomers ? (
                    <p className="text-white">ডেটা লোড হচ্ছে...</p>
                  ) : customersData.activeCustomers && customersData.activeCustomers.length > 0 ? (
                    <ul className="space-y-2">
                      {customersData.activeCustomers.map((customer) => (
                        <CustomerItem
                          key={customer.id}
                          name={customer.name}
                          customerId={customer.customerId}
                          points={customer.points}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className="text-white">কোনো সক্রিয় কাস্টমার পাওয়া যায়নি।</p>
                  )}
                </Card>

                <Card className="bg-gradient-to-br from-yellow-600 to-orange-700">
                  <h3 className="text-2xl font-bold mb-4 text-white">গোল্ডেন কাস্টমার (১০০+ পয়েন্ট)</h3>
                  {loadingCustomers ? (
                    <p className="text-white">ডেটা লোড হচ্ছে...</p>
                  ) : customersData.goldenCustomers && customersData.goldenCustomers.length > 0 ? (
                    <ul className="space-y-2">
                      {customersData.goldenCustomers.map((customer) => (
                        <CustomerItem
                          key={customer.id}
                          name={customer.name}
                          customerId={customer.customerId}
                          points={customer.points}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className="text-white">কোনো গোল্ডেন কাস্টমার পাওয়া যায়নি।</p>
                  )}
                </Card>


 <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
                  <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমার</h3>
                  {loadingCustomers ? (
                    <p className="text-white">ডেটা লোড হচ্ছে...</p>
                  ) : customersData.allCustomers && customersData.allCustomers.length > 0 ? (
                    <ul className="space-y-2">
                      {customersData.allCustomers.map((customer) => (
                        <CustomerItem
                          key={customer.id}
                          name={customer.name}
                          customerId={customer.customerId}
                          points={customer.points}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className="text-white">কোনো কাস্টমার পাওয়া যায়নি।</p>
                  )}
                </Card>

                <Card className="bg-gradient-to-br from-pink-600 to-red-700">
                  <h3 className="text-2xl font-bold mb-4 text-white">
                    উচ্চ পয়েন্টধারী কাস্টমার (১০০০+ পয়েন্ট)
                  </h3>
                  {loadingCustomers ? (
                    <p className="text-white">ডেটা লোড হচ্ছে...</p>
                  ) : customersData.highPointCustomers && customersData.highPointCustomers.length > 0 ? (
                    <ul className="space-y-2">
                      {customersData.highPointCustomers.map((customer) => (
                        <CustomerItem
                          key={customer.id}
                          name={customer.name}
                          customerId={customer.customerId}
                          points={customer.points}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className="text-white">কোনো উচ্চ পয়েন্টধারী কাস্টমার পাওয়া যায়নি।</p>
                  )}
                </Card>
              </div>
            )}

            {activeTab === 'cashback' && (
              <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
                <h3 className="text-2xl font-bold mb-4 text-white">ক্যাশব্যাক অ্যাসাইন করুন</h3>
                <form onSubmit={handleAssignCashback} className="space-y-6">
                  <div className="space-y-2">
                    <label htmlFor="customerId" className="block text-sm font-medium text-white">
                      কাস্টমার আইডি
                    </label>
                    <input
                      type="text"
                      id="customerId"
                      value={customerId}
                      onChange={(e) => setCustomerId(e.target.value)}
                      className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
                      placeholder="কাস্টমার আইডি লিখুন"
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="cashbackAmount" className="block text-sm font-medium text-white">
                      ক্যাশব্যাক পরিমাণ (৳)
                    </label>
                    <input
                      type="number"
                      id="cashbackAmount"
                      value={cashbackAmount}
                      onChange={(e) => setCashbackAmount(e.target.value)}
                      className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
                      placeholder="ক্যাশব্যাক পরিমাণ লিখুন"
                      required
                    />
                  </div>
<Button
  type="submit"
  className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600 transition-all duration-300 flex items-center justify-center"
>
  <Gift className="h-6 h-6 mr-2" />
  ক্যাশব্যাক অ্যাসাইন করুন
</Button>

                </form>
              </Card>
            )}

            {activeTab === 'withdraw' && (
              <Card className="bg-gradient-to-br from-green-600 to-teal-700">
                <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট এবং লগ</h3>
                {loadingWithdrawRequests ? (
                  <p className="text-white">ডেটা লোড হচ্ছে...</p>
                ) : withdrawRequests && withdrawRequests.length > 0 ? (
                  <ul className="space-y-2">
                    {withdrawRequests.map((request) => (
                      <WithdrawRequestItem
                        key={request.id}
                        request={request}
                        onApprove={handleApproveWithdraw}
                      />
                    ))}
                  </ul>
                ) : (
                  <p className="text-white">কোনো উইথড্র রিকুয়েস্ট পাওয়া যায়নি।</p>
                )}
              </Card>
            )}
          </motion.div>
        </AnimatePresence>
      </main>

      <footer
        className={`${
          isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30'
        } backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
      >
        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
          <div className="mb-4 sm:mb-0">
            <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
            <p className="text-sm mt-2 text-white opacity-80">আপনার ব্যবসা বৃদ্ধিতে সহায়তা করছে</p>
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a href="#" className="hover:text-yellow-400 transition-colors">
                  গোপনীয়তা নীতি
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-yellow-400 transition-colors">
                  ব্যবহারের শর্তাবলী
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-yellow-400 transition-colors">
                  যোগাযোগ করুন
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
}





// import React, { useState, useEffect, createContext, useContext } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Bell,
//   User,
//   LogOut,
//   Menu,
//   Gift,
//   TrendingUp,
//   DollarSign,
//   Award,
//   Percent,
//   Users,
//   Send,
//   Sun,
//   Moon,
//   CheckCircle,
// } from 'lucide-react';

// // Backend API URL
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

// // Create a Theme Context
// const ThemeContext = createContext();

// // Custom Hook for using Theme Context
// const useTheme = () => useContext(ThemeContext);

// // Button Component
// const Button = ({ children, className, ...props }) => {
//   const { isNightMode } = useTheme();
//   return (
//     <motion.button
//       whileHover={{ scale: 1.05 }}
//       whileTap={{ scale: 0.95 }}
//       className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${
//         isNightMode ? 'text-white' : 'text-black'
//       } ${className}`}
//       {...props}
//     >
//       {children}
//     </motion.button>
//   );
// };

// // Card Component
// const Card = ({ children, className, ...props }) => {
//   const { isNightMode } = useTheme();
//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//       whileHover={{ scale: 1.02 }}
//       className={`p-6 rounded-2xl shadow-xl ${
//         isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-white bg-opacity-70'
//       } ${className}`}
//       {...props}
//     >
//       {children}
//     </motion.div>
//   );
// };

// // FeatureCard Component
// const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
//   <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
//     <div className="p-3 rounded-full bg-white bg-opacity-20">
//       <Icon className="h-8 w-8 text-white" />
//     </div>
//     <div className="text-center">
//       <p className="text-lg font-medium text-white">{title}</p>
//       <p className="text-3xl font-bold text-white">{value}</p>
//       <p className="text-sm text-white opacity-80 mt-2">{description}</p>
//     </div>
//   </Card>
// );

// // CustomerItem Component
// const CustomerItem = ({ name, customerId, points }) => (
//   <motion.li
//     initial={{ opacity: 0, x: -20 }}
//     animate={{ opacity: 1, x: 0 }}
//     className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//   >
//     <div>
//       <p className="font-medium text-white">{name}</p>
//       <p className="text-sm text-white opacity-80">ID: {customerId}</p>
//     </div>
//     <div className="text-right">
//       <p className="text-lg font-bold text-yellow-300">{points} পয়েন্ট</p>
//     </div>
//   </motion.li>
// );

// // WithdrawRequestItem Component
// const WithdrawRequestItem = ({ request, onApprove }) => {
//   if (!request || !request.user) {
//     return null;
//   }

//   return (
//     <motion.li
//       initial={{ opacity: 0, x: -20 }}
//       animate={{ opacity: 1, x: 0 }}
//       className="flex flex-col md:flex-row md:items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
//     >
//       <div className="mb-4 md:mb-0">
//         <p className="font-medium text-white">কাস্টমার ID: {request.user.customerId}</p>
//         <p className="text-sm text-white opacity-80">নাম: {request.user.name}</p>
//         <p className="text-sm text-white opacity-80">ফোন নম্বর: {request.phoneNumber}</p>
//         <p className="text-sm text-white opacity-80">পেমেন্ট মেথড: {request.paymentMethod}</p>
//         <p className="text-sm text-white opacity-80">
//           রিকুয়েস্টের তারিখ: {new Date(request.createdAt).toLocaleString()}
//         </p>
//         <p className="text-sm text-white opacity-80">স্ট্যাটাস: {request.status}</p>
//         {request.approvedAt && (
//           <p className="text-sm text-white opacity-80">
//             অ্যাপ্রুভের সময়: {new Date(request.approvedAt).toLocaleString()}
//           </p>
//         )}
//       </div>
//       <div className="text-right">
//         <p className="text-lg font-bold text-green-300">৳{request.amount}</p>
//         {request.status === 'pending' && (
//           <Button
//             className="mt-2 bg-green-500 hover:bg-green-600"
//             onClick={() => onApprove(request.id)}
//           >
//             <CheckCircle className="h-5 w-5 mr-1" /> অ্যাপ্রুভ
//           </Button>
//         )}
//       </div>
//     </motion.li>
//   );
// };

// // ThemeProvider Component
// const ThemeProvider = ({ children }) => {
//   const [isNightMode, setIsNightMode] = useState(
//     localStorage.getItem('isNightMode') === 'true' || false
//   );

//   useEffect(() => {
//     localStorage.setItem('isNightMode', isNightMode);
//   }, [isNightMode]);

//   const toggleNightMode = () => setIsNightMode(!isNightMode);

//   return (
//     <ThemeContext.Provider value={{ isNightMode, toggleNightMode }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };

// export default function SellerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview');
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [customerId, setCustomerId] = useState('');
//   const [cashbackAmount, setCashbackAmount] = useState('');
//   const [message, setMessage] = useState('');

//   const { isNightMode, toggleNightMode } = useTheme();

//   const [sellerData, setSellerData] = useState({
//     name: '',
//     bonusFund: 0,
//     sellerIncome: 0,
//     royaltyFund: 0,
//     incentiveFund: 0,
//     companyIncome: 0,
//   });

//   const [customersData, setCustomersData] = useState({
//     allCustomers: [],
//     goldenCustomers: [],
//     activeCustomers: [],
//     highPointCustomers: [],
//   });

//   const [withdrawRequests, setWithdrawRequests] = useState([]);

//   // Loading states
//   const [loadingSellerData, setLoadingSellerData] = useState(true);
//   const [loadingCustomers, setLoadingCustomers] = useState(true);
//   const [loadingWithdrawRequests, setLoadingWithdrawRequests] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       window.location.href = '/login';
//     } else {
//       fetchSellerFunds();
//       fetchCustomers();
//       fetchWithdrawRequests();
//     }
//   }, []);

//   const fetchSellerFunds = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/funds`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setSellerData({
//           name: data.name || '',
//           bonusFund: data.bonusFund || 0,
//           sellerIncome: data.sellerIncome || 0,
//           royaltyFund: data.royaltyFund || 0,
//           incentiveFund: data.incentiveFund || 0,
//           companyIncome: data.companyIncome || 0,
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching seller funds:', errorText);
//         alert(`সেলার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching seller funds:', error);
//       alert('সেলার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingSellerData(false);
//     }
//   };

//   const fetchCustomers = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/customers`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setCustomersData({
//           allCustomers: data.allCustomers || [],
//           goldenCustomers: data.goldenCustomers || [],
//           activeCustomers: data.activeCustomers || [],
//           highPointCustomers: data.highPointCustomers || [],
//         });
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching customers:', errorText);
//         alert(`কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching customers:', error);
//       alert('কাস্টমার তথ্য লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingCustomers(false);
//     }
//   };

//   const fetchWithdrawRequests = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/withdraw-requests`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setWithdrawRequests(data.requests || []);
//       } else {
//         const errorText = await response.text();
//         console.error('Error fetching withdraw requests:', errorText);
//         alert(`উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error fetching withdraw requests:', error);
//       alert('উইথড্র রিকুয়েস্ট লোড করতে সমস্যা হয়েছে।');
//     } finally {
//       setLoadingWithdrawRequests(false);
//     }
//   };

//   const handleAssignCashback = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/assign-cashback`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           customerId,
//           amount: parseFloat(cashbackAmount),
//         }),
//       });

//       if (response.ok) {
//         alert('ক্যাশব্যাক সফলভাবে অ্যাসাইন করা হয়েছে!');
//         setCustomerId('');
//         setCashbackAmount('');
//         fetchCustomers();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error assigning cashback:', error);
//       alert('ক্যাশব্যাক অ্যাসাইন করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/send-message`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           content: message,
//         }),
//       });

//       if (response.ok) {
//         alert('মেসেজ সফলভাবে পাঠানো হয়েছে!');
//         setMessage('');
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('মেসেজ পাঠাতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleApproveWithdraw = async (requestId) => {
//     const token = localStorage.getItem('token');

//     try {
//       const response = await fetch(`${API_BASE_URL}dashboard/seller/approve-withdraw`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           requestId,
//         }),
//       });

//       if (response.ok) {
//         alert('উইথড্র রিকুয়েস্ট সফলভাবে অ্যাপ্রুভ করা হয়েছে।');
//         fetchWithdrawRequests();
//       } else {
//         const errorText = await response.text();
//         alert(`এরর: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error approving withdraw request:', error);
//       alert('উইথড্র রিকুয়েস্ট অ্যাপ্রুভ করতে সমস্যা হয়েছে।');
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     window.location.href = '/login';
//   };

//   const bgGradient = isNightMode
//     ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
//     : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

//   const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-white bg-opacity-20';

//   if (loadingSellerData) {
//     return (
//       <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
//         <p className="text-white">ডেটা লোড হচ্ছে...</p>
//       </div>
//     );
//   }

//   return (
//     <ThemeProvider>
//       <div className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}>
//         <header
//           className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
//         >
//           <div className="max-w-7xl mx-auto flex justify-between items-center">
//             <div className="flex items-center space-x-2">
//               <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ সেলার হাব</span>
//             </div>
//             <nav className="hidden md:flex items-center space-x-4">
//               <Button
//                 className="bg-yellow-400 hover:bg-yellow-500"
//                 onClick={() => setShowNotifications(!showNotifications)}
//               >
//                 <Bell className="h-5 w-5" />
//               </Button>
//               <Button className="bg-green-400 hover:bg-green-500">
//                 <User className="h-5 w-5 mr-2" />
//                 প্রোফাইল
//               </Button>
//               <Button className="bg-red-400 hover:bg-red-500" onClick={handleLogout}>
//                 <LogOut className="h-5 w-5 mr-2" />
//                 লগআউট
//               </Button>
//               <Button
//                 onClick={toggleNightMode}
//                 className={`p-2 rounded-full ${
//                   isNightMode ? 'bg-yellow-400' : 'bg-indigo-600'
//                 } hover:opacity-80`}
//               >
//                 {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
//               </Button>
//             </nav>
//             <Button className="md:hidden bg-yellow-400" onClick={() => setShowMobileMenu(!showMobileMenu)}>
//               <Menu className="h-6 w-6" />
//             </Button>
//           </div>
//         </header>

//         <AnimatePresence>
//           {showMobileMenu && (
//             <motion.div
//               initial={{ opacity: 0, height: 0 }}
//               animate={{ opacity: 1, height: 'auto' }}
//               exit={{ opacity: 0, height: 0 }}
//               transition={{ duration: 0.3 }}
//               className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
//             >
//               <div className="p-4 space-y-2">
//                 <Button
//                   className="flex items-center justify-center w-full bg-yellow-400 hover:bg-yellow-500 rounded-md"
//                   onClick={() => setShowNotifications(!showNotifications)}
//                 >
//                   <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
//                 </Button>
//                 <Button className="flex items-center justify-center w-full bg-green-400 hover:bg-green-500 rounded-md">
//                   <User className="h-5 w-5 mr-2" /> প্রোফাইল
//                 </Button>
//                 <Button
//                   className="flex items-center justify-center w-full bg-red-400 hover:bg-red-500 rounded-md"
//                   onClick={handleLogout}
//                 >
//                   <LogOut className="h-5 w-5 mr-2" /> লগআউট
//                 </Button>
//                 <Button
//                   onClick={toggleNightMode}
//                   className={`flex items-center justify-center w-full ${
//                     isNightMode ? 'bg-yellow-400' : 'bg-indigo-600'
//                   } hover:opacity-80 rounded-md`}
//                 >
//                   {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
//                   {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
//                 </Button>
//               </div>
//             </motion.div>
//           )}
//         </AnimatePresence>

//         <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//           <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//             <div>
//               <h2 className="text-3xl font-bold text-white">
//                 স্বাগতম, {sellerData.name ? sellerData.name : 'সেলার'}!
//               </h2>
//               <p className="text-white opacity-80">আপনার সেলার ড্যাশবোর্ড</p>
//             </div>
//           </div>

//           <div className="mb-8">
//             <nav className="flex flex-wrap gap-4">
//               <Button
//                 onClick={() => setActiveTab('overview')}
//                 className={`${
//                   activeTab === 'overview'
//                     ? 'bg-gradient-to-r from-green-400 to-blue-500'
//                     : 'bg-white bg-opacity-20 hover:bg-opacity-30'
//                 }`}
//               >
//                 ওভারভিউ
//               </Button>
//               <Button
//                 onClick={() => setActiveTab('customers')}
//                 className={`${
//                   activeTab === 'customers'
//                     ? 'bg-gradient-to-r from-green-400 to-blue-500'
//                     : 'bg-white bg-opacity-20 hover:bg-opacity-30'
//                 }`}
//               >
//                 কাস্টমার
//               </Button>
//               <Button
//                 onClick={() => setActiveTab('cashback')}
//                 className={`${
//                   activeTab === 'cashback'
//                     ? 'bg-gradient-to-r from-green-400 to-blue-500'
//                     : 'bg-white bg-opacity-20 hover:bg-opacity-30'
//                 }`}
//               >
//                 ক্যাশব্যাক
//               </Button>
//               <Button
//                 onClick={() => setActiveTab('withdraw')}
//                 className={`${
//                   activeTab === 'withdraw'
//                     ? 'bg-gradient-to-r from-green-400 to-blue-500'
//                     : 'bg-white bg-opacity-20 hover:bg-opacity-30'
//                 }`}
//               >
//                 উইথড্র
//               </Button>
//             </nav>
//           </div>

//           <AnimatePresence mode="wait">
//             <motion.div
//               key={activeTab}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               exit={{ opacity: 0, y: -20 }}
//               transition={{ duration: 0.3 }}
//             >
//               {activeTab === 'overview' && (
//                 <div className="space-y-6">
//                   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
//                     <FeatureCard
//                       icon={Gift}
//                       title="বোনাস ফান্ড"
//                       value={`৳${sellerData.bonusFund.toFixed(2)}`}
//                       description="প্রতি ৫ টাকার ৪০% (২ টাকা) বোনাস হিসেবে বন্টন হয়"
//                       gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
//                     />
//                     <FeatureCard
//                       icon={DollarSign}
//                       title="সেলার ইনকাম"
//                       value={`৳${sellerData.sellerIncome.toFixed(2)}`}
//                       description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সরাসরি ইনকাম"
//                       gradient="bg-gradient-to-br from-green-400 to-teal-500"
//                     />
//                     <FeatureCard
//                       icon={Award}
//                       title="রয়্যালটি ফান্ড"
//                       value={`৳${sellerData.royaltyFund.toFixed(2)}`}
//                       description="প্রতি ৫ টাকার ১০% (৫০ পয়সা) সাপ্তাহিক রয়্যালটি"
//                       gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
//                     />
//                     <FeatureCard
//                       icon={Percent}
//                       title="ইনসেন্টিভ ফান্ড"
//                       value={`৳${sellerData.incentiveFund.toFixed(2)}`}
//                       description="প্রতি ৫ টাকার ২০% (১ টাকা) ইনসেন্টিভ ফান্ডে জমা"
//                       gradient="bg-gradient-to-br from-purple-400 to-pink-500"
//                     />
//                     <FeatureCard
//                       icon={TrendingUp}
//                       title="কোম্পানির লাভ"
//                       value={`৳${sellerData.companyIncome.toFixed(2)}`}
//                       description="প্রতি ৫ টাকার ১৫% (৭৫ পয়সা) কোম্পানির লাভ"
//                       gradient="bg-gradient-to-br from-red-400 to-pink-500"
//                     />
//                     <FeatureCard
//                       icon={Users}
//                       title="সক্রিয় কাস্টমার"
//                       value={customersData.activeCustomers.length}
//                       description="মোট সক্রিয় কাস্টমারের সংখ্যা"
//                       gradient="bg-gradient-to-br from-teal-400 to-green-500"
//                     />
//                   </div>

//                   <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                     <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমারদের মেসেজ পাঠান</h3>
//                     <form onSubmit={handleSendMessage} className="space-y-4">
//                       <textarea
//                         value={message}
//                         onChange={(e) => setMessage(e.target.value)}
//                         placeholder="আপনার মেসেজ লিখুন..."
//                         className="w-full p-2 rounded-md bg-white bg-opacity-20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
//                         rows={4}
//                         required
//                       />
//                       <Button type="submit" className="bg-yellow-400 hover:bg-yellow-500">
//                         <Send className="h-5 w-5 mr-2" />
//                         মেসেজ পাঠান
//                       </Button>
//                     </form>
//                   </Card>
//                 </div>
//               )}

//               {activeTab === 'customers' && (
//                 <div className="space-y-6">
//                   <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
//                     <h3 className="text-2xl font-bold mb-4 text-white">সকল কাস্টমার</h3>
//                     {loadingCustomers ? (
//                       <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                     ) : customersData.allCustomers && customersData.allCustomers.length > 0 ? (
//                       <ul className="space-y-2">
//                         {customersData.allCustomers.map((customer) => (
//                           <CustomerItem
//                             key={customer.id}
//                             name={customer.name}
//                             customerId={customer.customerId}
//                             points={customer.points}
//                           />
//                         ))}
//                       </ul>
//                     ) : (
//                       <p className="text-white">কোনো কাস্টমার পাওয়া যায়নি।</p>
//                     )}
//                   </Card>

//                   {/* অন্যান্য কাস্টমার তালিকা কার্ডগুলো এখানেও একইভাবে যোগ করতে পারেন */}
//                 </div>
//               )}

//               {activeTab === 'cashback' && (
//                 <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">ক্যাশব্যাক অ্যাসাইন করুন</h3>
//                   <form onSubmit={handleAssignCashback} className="space-y-6">
//                     <div className="space-y-2">
//                       <label htmlFor="customerId" className="block text-sm font-medium text-white">
//                         কাস্টমার আইডি
//                       </label>
//                       <input
//                         type="text"
//                         id="customerId"
//                         value={customerId}
//                         onChange={(e) => setCustomerId(e.target.value)}
//                         className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                         placeholder="কাস্টমার আইডি লিখুন"
//                         required
//                       />
//                     </div>
//                     <div className="space-y-2">
//                       <label htmlFor="cashbackAmount" className="block text-sm font-medium text-white">
//                         ক্যাশব্যাক পরিমাণ (৳)
//                       </label>
//                       <input
//                         type="number"
//                         id="cashbackAmount"
//                         value={cashbackAmount}
//                         onChange={(e) => setCashbackAmount(e.target.value)}
//                         className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                         placeholder="ক্যাশব্যাক পরিমাণ লিখুন"
//                         required
//                       />
//                     </div>
//                     <Button
//                       type="submit"
//                       className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-yellow-500 hover:to-orange-600 transition-all duration-300"
//                     >
//                       <Gift className="h-5 w-5 mr-2" />
//                       ক্যাশব্যাক অ্যাসাইন করুন
//                     </Button>
//                   </form>
//                 </Card>
//               )}

//               {activeTab === 'withdraw' && (
//                 <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট এবং লগ</h3>
//                   {loadingWithdrawRequests ? (
//                     <p className="text-white">ডেটা লোড হচ্ছে...</p>
//                   ) : withdrawRequests && withdrawRequests.length > 0 ? (
//                     <ul className="space-y-2">
//                       {withdrawRequests.map((request) => (
//                         <WithdrawRequestItem
//                           key={request.id}
//                           request={request}
//                           onApprove={handleApproveWithdraw}
//                         />
//                       ))}
//                     </ul>
//                   ) : (
//                     <p className="text-white">কোনো উইথড্র রিকুয়েস্ট পাওয়া যায়নি।</p>
//                   )}
//                 </Card>
//               )}
//             </motion.div>
//           </AnimatePresence>
//         </main>

//         <footer
//           className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
//         >
//           <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//             <div className="mb-4 sm:mb-0">
//               <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
//               <p className="text-sm mt-2 text-white opacity-80">আপনার ব্যবসা বৃদ্ধিতে সহায়তা করছে</p>
//             </div>
//             <nav>
//               <ul className="flex space-x-4">
//                 <li>
//                   <a href="#" className="hover:text-yellow-400 transition-colors">
//                     গোপনীয়তা নীতি
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="hover:text-yellow-400 transition-colors">
//                     ব্যবহারের শর্তাবলী
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="hover:text-yellow-400 transition-colors">
//                     যোগাযোগ করুন
//                   </a>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </footer>
//       </div>
//     </ThemeProvider>
//   );
// }
