// 'use client'

// import React, { useState, useEffect } from 'react'
// import { motion, AnimatePresence } from 'framer-motion'
// import { Bell, ChevronRight, Copy, Globe, HelpCircle, MessageCircle, Clock, User, LogOut, Menu } from 'lucide-react'
// const Uaip = process.env.REACT_APP_API_BASE_URL;
// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className={`px-4 py-2 rounded-full font-semibold transition-colors duration-300 ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.button>
// )

// const Card = ({ children, className, ...props }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//     className={`bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.div>
// )

// export default function CustomerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview')
//   const [showMobileMenu, setShowMobileMenu] = useState(false)
//   const [userData, setUserData] = useState(null)

//   // Fetch customer data (useEffect for data fetching from your backend)
//   useEffect(() => {
//     const fetchData = async () => {
//       const token = localStorage.getItem('token')

//       const response = await fetch(`${Uaip}dashboard/customer`, {
//         headers: { Authorization: `Bearer ${token}` },
//       })

//       if (response.ok) {
//         const data = await response.json()
//         console.log(data)
//         setUserData(data)
//       } else {
//         console.error('Failed to fetch data')
//       }
//     }

//     fetchData()
//   }, [])

//   const handleLogout = () => {
//     localStorage.removeItem('token')
//     localStorage.removeItem('userType')
//     window.location.href = '/login'
//   }

//   if (!userData) {
//     return <p>Loading...</p>
//   }

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white font-roboto">
//       <header className="w-full py-6 px-4 sm:px-6 lg:px-8 bg-white shadow-md">
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="flex items-center space-x-2">
//             <Globe className="h-8 w-8 text-blue-600" />
//             <span className="text-2xl font-bold text-blue-600">BazarProtikhon</span>
//           </div>
//           <nav className="hidden md:flex items-center space-x-4">
//             <Button className="bg-blue-600 text-white hover:bg-blue-700">
//               <Bell className="h-5 w-5" />
//             </Button>
//             <Button className="bg-gray-200 text-gray-800 hover:bg-gray-300">
//               <User className="h-5 w-5" />
//               Profile
//             </Button>
//             <Button className="bg-red-500 text-white hover:bg-red-600" onClick={handleLogout}>
//               <LogOut className="h-5 w-5" />
//               Logout
//             </Button>
//           </nav>
//           <Button className="md:hidden" onClick={() => setShowMobileMenu(!showMobileMenu)}>
//             <Menu className="h-6 w-6" />
//           </Button>
//         </div>
//       </header>

//       {/*
//       <AnimatePresence>
//         {showMobileMenu && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             className="md:hidden bg-white shadow-md"
//           >
//             <div className="p-4 space-y-2">
//               <Button className="w-full bg-blue-600 text-white hover:bg-blue-700">
//                 <Bell className="h-5 w-5 mr-2" /> Notifications
//               </Button>
//               <Button className="w-full bg-gray-200 text-gray-800 hover:bg-gray-300">
//                 <User className="h-5 w-5 mr-2" /> Profile
//               </Button>
//               <Button className="w-full bg-red-500 text-white hover:bg-red-600" onClick={handleLogout}>
//                 <LogOut className="h-5 w-5 mr-2" /> Logout
//               </Button>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>*/}

// <AnimatePresence>
//   {showMobileMenu && (
//     <motion.div
//       initial={{ opacity: 0, height: 0 }}
//       animate={{ opacity: 1, height: 'auto' }}
//       exit={{ opacity: 0, height: 0 }}
//       transition={{ duration: 0.3 }} // এখানে ট্রানজেকশনের সময়সীমা নির্ধারণ করা হয়েছে
//       className="md:hidden bg-white shadow-md overflow-hidden"
//     >
//       <div className="p-4 space-y-2">
//         <Button className="flex items-center justify-center w-full bg-blue-600 text-white hover:bg-blue-700 rounded-md">
//           <Bell className="h-5 w-5 mr-2" /> Notifications
//         </Button>
//         <Button className="flex items-center justify-center w-full bg-gray-200 text-gray-800 hover:bg-gray-300 rounded-md">
//           <User className="h-5 w-5 mr-2" /> Profile
//         </Button>
//         <Button className="flex items-center justify-center w-full bg-red-500 text-white hover:bg-red-600 rounded-md" onClick={handleLogout}>
//           <LogOut className="h-5 w-5 mr-2" /> Logout
//         </Button>
//       </div>
//     </motion.div>
//   )}
// </AnimatePresence>






//       {/* Main Content */}
//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//           <div className="flex items-center space-x-4 mb-4 md:mb-0 h-24 w-24">
//             <img
//               src="https://bazarprotikhon.com/Logo.jpg?height=8&width=8"
//               alt="Profile"
//               className={`rounded-full border-4 ${
//                 userData.points >= 100 ? 'border-yellow-400' : 'border-white'
//               } shadow-lg`}
//             />
//             <div>
//               <h2 className="text-2xl font-bold text-gray-800">{userData.name}</h2>
//               <p className="text-gray-600">{userData.email}</p>
              
//           <p  className="text-gray-600">Phone : {userData.phone}</p> {/* ইমেইলের জায়গায় মোবাইল নাম্বর ন */}  
              
//               <p className="text-sm text-gray-500">Customer ID: {userData.customerId}</p>
//             </div>
           
            
//           </div>
//           <div className="flex items-center bg-red-600 space-x-2">
//             <span className="text-sm font-medium text-gray-500">Golden Customer:</span>
//             {userData.points >= 100 ? (
//               <div className="flex items-center">
//                 {[...Array(Math.floor(userData.points / 100))].map((_, i) => (
//                   <motion.svg
//                     key={i}
//                     className="w-6 h-6 text-yellow-400"
//                     fill="currentColor"
//                     viewBox="0 0 20 20"
//                     animate={{ scale: [1, 1.2, 1] }}
//                     transition={{ repeat: Infinity, duration: 2 }}
//                   >
//                     <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
//                   </motion.svg>
//                 ))}
//               </div>
//             ) : (
//               <span className="text-gray-500">Not a Golden Customer yet</span>
//             )}
//           </div>
//         </div>

//         {/* Tabs and Tab Content */}
//         <div className="mb-8">
//           <nav className="flex space-x-4">
//             <Button
//               onClick={() => setActiveTab('overview')}
//               className={`${
//                 activeTab === 'overview' ? 'bg-blue-600 text-white' : 'bg-white text-gray-600 hover:bg-gray-100'
//               }`}
//             >
//               Overview
//             </Button>
//             <Button
//               onClick={() => setActiveTab('activity')}
//               className={`${
//                 activeTab === 'activity' ? 'bg-blue-600 text-white' : 'bg-white text-gray-600 hover:bg-gray-100'
//               }`}
//             >
//               Referral Log
//             </Button>
//           </nav>
//         </div>

//         {/* Render tab content dynamically */}
//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -20 }}
//             transition={{ duration: 0.3 }}
//           >
//             {activeTab === 'overview' ? (
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                 <Card>
//                   <h3 className="text-xl font-bold mb-4 text-gray-800">Points Overview</h3>
//                   <div className="flex items-center justify-between mb-4">
//                     <span className="text-3xl font-bold text-blue-600">{userData.points}</span>
//                     <span className="text-sm text-gray-500">Current Points</span>
//                   </div>
//                   {userData.points >= 100 && (
//                     <motion.div
//                       initial={{ opacity: 0, y: -20 }}
//                       animate={{ opacity: 1, y: 0 }}
//                       className="mb-4 p-3 bg-yellow-100 rounded-md"
//                     >
//                       <p className="text-yellow-800 font-semibold">Golden Customer Status Achieved!</p>
//                       <p className="text-sm text-yellow-700">
//                         You've earned {Math.floor(userData.points / 100)} star(s)!
//                       </p>
//                     </motion.div>
//                   )}
//                 </Card>
//                 <Card>
//                   <h3 className="text-xl font-bold mb-4 text-gray-800">Referral Program</h3>
//                   <p className="text-gray-600 mb-4">Share your referral code and earn bonus points!</p>
//                   <div className="flex items-center space-x-2 mb-4">
//                     <input
//                       type="text"
//                       value={userData.customerId}
//                       readOnly
//                       className="bg-gray-100 px-3 py-2 rounded-md flex-grow"
//                     />
//                     <Button
//                       className="bg-blue-600 text-white hover:bg-blue-700"
//                       onClick={() => {
//                         navigator.clipboard.writeText(userData.customerId)
//                         alert('Referral code copied to clipboard!')
//                       }}
//                     >
//                       <Copy className="h-5 w-5" />
//                     </Button>
//                   </div>
                  
                  
//                   <Button className="w-full bg-orange-500 text-white hover:bg-orange-600" onClick={() => {
//                         navigator.clipboard.writeText(userData.customerId);
                        
//                         const toast = document.createElement('div');
//                         toast.textContent = 'Copied to clipboard!';
//                         toast.className = 'fixed bottom-5 right-5 bg-green-500 text-white p-3 rounded-lg shadow-md';
//                         document.body.appendChild(toast);
                        
//                         setTimeout(() => toast.remove(), 3000);
//                       }}>
//                     Share Referral Link
//                   </Button>
                  
                  
                  
//                 </Card>
//               </div>
//             ) : (
//               <Card>
//                 <h3 className="text-xl font-bold mb-4 text-gray-800">Your Referrals</h3>
//                 <ul className="space-y-4">
//                   {userData.referredUsers.map((activity) => (
//                     <motion.li
//                       key={activity.id}
//                       className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg"
//                       initial={{ opacity: 0, x: -20 }}
//                       animate={{ opacity: 1, x: 0 }}
//                       transition={{ duration: 0.3 }}
//                     >
//                       <Clock className="h-5 w-5 text-gray-400" />
//                       <div>
//                         <p className="font-medium">{activity.name}</p>
//                         <p className="text-sm text-gray-500">{activity.customerId}</p>
//                       </div>
//                     </motion.li>
//                   ))}
//                 </ul>
//               </Card>
//             )}
//           </motion.div>
//         </AnimatePresence>
//       </main>

//       <footer className="bg-gray-800 text-white py-8 px-4 sm:px-6 lg:px-8 mt-12">
//         <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//           <div className="mb-4 sm:mb-0">
//             <span className="text-2xl font-bold">BazarProtikhon</span>
//             <p className="text-sm mt-2">Empowering your shopping experience</p>
//           </div>
//           <nav>
//             <ul className="flex space-x-4">
//               <li><a href="#" className="hover:text-blue-400 transition-colors">Privacy Policy</a></li>
//               <li><a href="#" className="hover:text-blue-400 transition-colors">Terms of Service</a></li>
//               <li><a href="#" className="hover:text-blue-400 transition-colors">Contact Us</a></li>
//             </ul>
//           </nav>
//         </div>
//       </footer>
//     </div>
//   )
// }










// // UpdatedCustomerDashboard.jsx

// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Bell,
//   ChevronRight,
//   Copy,
//   Globe,
//   HelpCircle,
//   MessageCircle,
//   User,
//   LogOut,
//   Menu,
//   Gift,
//   TrendingUp,
//   ShoppingBag,
//   Coins,
//   Calendar,
//   Zap,
//   Award,
//   Sparkles,
//   DollarSign,
//   Send,
//   CreditCard,
//   Users,
//   AlertCircle,
//   Sun,
//   Moon,
// } from 'lucide-react';

// // Backend API URL
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/';

// // Button Component
// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.button>
// );

// // Card Component
// const Card = ({ children, className, ...props }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//     whileHover={{ scale: 1.02 }}
//     className={`p-6 rounded-2xl shadow-xl ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.div>
// );

// // FeatureCard Component
// const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
//   <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
//     <div className="p-3 rounded-full bg-white bg-opacity-20">
//       <Icon className="h-8 w-8 text-white" />
//     </div>
//     <div className="text-center">
//       <p className="text-lg font-medium text-white">{title}</p>
//       <p className="text-3xl font-bold text-white">{value}</p>
//       <p className="text-sm text-white opacity-80 mt-2">{description}</p>
//     </div>
//   </Card>
// );

// // GoldenBar Component
// const GoldenBar = ({ points }) => {
//   const brightness = Math.min(100 + points / 10, 200); // Brightness calculation
//   const level = Math.floor(points / 100) + 0; // Calculate level based on points

//   return (
//     <motion.div
//       className="w-40 h-10 bg-gradient-to-br from-yellow-500 via-yellow-600 to-yellow-700 overflow-hidden relative rounded-lg shadow-lg"
//       style={{ filter: `brightness(${brightness}%)` }}
//       animate={{
//         scale: [1, 1.05, 1],
//         boxShadow: [
//           '0 0 20px rgba(255, 223, 0, 0.8)',
//           '0 0 30px rgba(255, 215, 0, 1)',
//           '0 0 20px rgba(255, 223, 0, 0.8)',
//         ],
//       }}
//       transition={{
//         duration: 2,
//         repeat: Infinity,
//         ease: 'easeInOut',
//       }}
//     >
//       {/* Moving shimmer effect */}
//       <motion.div
//         className="absolute inset-0 h-full bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-800"
//         animate={{
//           x: ['-100%', '100%'],
//         }}
//         transition={{
//           duration: 3,
//           repeat: Infinity,
//           ease: 'easeInOut',
//         }}
//       />

//       {/* Center text */}
//       <div className="font-serif absolute inset-0 flex items-center justify-center text-white font-bold text-sm tracking-wide">
//         {`Golden Customer ${level}`}
//       </div>

//       {/* Shimmering border */}
//       <motion.div
//         className="absolute inset-0 w-full h-full border-4 rounded-lg border-yellow-400"
//         animate={{
//           opacity: [0.6, 0.9, 0.6],
//         }}
//         transition={{
//           duration: 1.5,
//           repeat: Infinity,
//           ease: 'easeInOut',
//         }}
//       />
//     </motion.div>
//   );
// };

// export default function UpdatedCustomerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview');
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [userData, setUserData] = useState(null);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [withdrawAmount, setWithdrawAmount] = useState('');
//   const [withdrawMethod, setWithdrawMethod] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [showMessageNotification, setShowMessageNotification] = useState(false);
//   const [isNightMode, setIsNightMode] = useState(false);

//   useEffect(() => {
//     // Fetch user data from backend
//     const fetchUserData = async () => {
//       try {
//         const token = localStorage.getItem('token');
//         if (!token) {
//           window.location.href = '/login';
//           return;
//         }

//         const response = await fetch(`${API_BASE_URL}dashboard/customer`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setUserData(data);
//           if (data.message) {
//             setShowMessageNotification(true);
//           }
//         } else {
//           // Handle unauthorized access
//           window.location.href = '/login';
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     };

//     fetchUserData();
//   }, []);

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     window.location.href = '/login';
//   };

//   const handleWithdraw = async (e) => {
//     e.preventDefault();

//     if (userData.balance < parseFloat(withdrawAmount)) {
//       alert('আপনার অ্যাকাউন্টে পর্যাপ্ত টাকা নেই।');
//       return;
//     }

//     try {
//       const token = localStorage.getItem('token');

//       const response = await fetch(`${API_BASE_URL}dashboard/customer/withdraw`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           amount: parseFloat(withdrawAmount),
//           paymentMethod: withdrawMethod,
//         }),
//       });

//       if (response.ok) {
//         alert('উইথড্র রিকুয়েস্ট সফলভাবে পাঠানো হয়েছে।');
//         setWithdrawAmount('');
//         setWithdrawMethod('');
//         setPhoneNumber('');
//       } else {
//         const errorText = await response.text();
//         alert(`উইথড্র রিকুয়েস্ট ব্যর্থ হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error submitting withdraw request:', error);
//       alert('কোনো সমস্যা হয়েছে। আবার চেষ্টা করুন।');
//     }
//   };

//   const toggleNightMode = () => {
//     setIsNightMode(!isNightMode);
//   };

//   const bgGradient = isNightMode
//     ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
//     : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

//   const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

//   if (!userData) {
//     return (
//       <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
//         <motion.div
//           animate={{ rotate: 360, scale: [1, 1.2, 1] }}
//           transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
//           className="w-16 h-16 border-t-4 border-b-4 border-white rounded-full"
//         ></motion.div>
//       </div>
//     );
//   }

//   return (
//     <div className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}>
//       <header
//         className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
//       >
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="flex items-center space-x-2">
//             <Globe className="h-8 w-8 text-yellow-400" />
//             <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ</span>
//           </div>
//           <nav className="hidden md:flex items-center space-x-4">
//             <Button
//               className="bg-yellow-400 text-black hover:bg-yellow-500 relative"
//               onClick={() => setShowNotifications(!showNotifications)}
//             >
//               <Bell className="h-5 w-5" />
//               {showMessageNotification && (
//                 <span className="absolute -top-1 -right-1 h-3 w-3 bg-red-500 rounded-full"></span>
//               )}
//             </Button>
//             <Button className="bg-green-400 text-black hover:bg-green-500">
//               <User className="h-5 w-5 mr-2" />
//               প্রোফাইল
//             </Button>
//             <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
//               <LogOut className="h-5 w-5 mr-2" />
//               লগআউট
//             </Button>
//             <Button
//               onClick={toggleNightMode}
//               className={`p-2 rounded-full ${
//                 isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//               } hover:opacity-80`}
//             >
//               {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
//             </Button>
//           </nav>
//           <Button className="md:hidden bg-yellow-400 text-black" onClick={() => setShowMobileMenu(!showMobileMenu)}>
//             <Menu className="h-6 w-6" />
//           </Button>
//         </div>
//       </header>

//       <AnimatePresence>
//         {showMobileMenu && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//             className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
//           >
//             <div className="p-4 space-y-2">
//               <Button
//                 className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
//                 onClick={() => setShowNotifications(!showNotifications)}
//               >
//                 <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
//               </Button>
//               <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
//                 <User className="h-5 w-5 mr-2" /> প্রোফাইল
//               </Button>
//               <Button
//                 className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
//                 onClick={handleLogout}
//               >
//                 <LogOut className="h-5 w-5 mr-2" /> লগআউট
//               </Button>
//               <Button
//                 onClick={toggleNightMode}
//                 className={`flex items-center justify-center w-full ${
//                   isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//                 } hover:opacity-80 rounded-md`}
//               >
//                 {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
//                 {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
//               </Button>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>

//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//           <div className="flex items-center space-x-4 mb-4 md:mb-0">
//             <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="relative">
//               <img
//                 src={'/path/to/default/profile/image.jpg'} // You can use a default image or user's profile image
//                 alt="প্রোফাইল"
//                 className="h-24 w-24 rounded-full border-4 border-white shadow-lg"
//               />
//               <div className="absolute bottom-0 right-0 bg-green-400 rounded-full p-1">
//                 <Zap className="h-4 w-4 text-white" />
//               </div>
//             </motion.div>
//             <div>
//               <h2 className="text-3xl font-bold text-white">{userData.name}</h2>
//               <p className="text-white opacity-80">{userData.email}</p>
//               <p className="text-white opacity-80">ফোন: {userData.phone}</p>
//               <p className="text-sm text-white opacity-60">কাস্টমার আইডি: {userData.customerId}</p>
//             </div>
//           </div>
//           <div className="flex items-center space-x-2">
//             <span className="text-sm font-medium text-white">গোল্ডেন কাস্টমার:</span>
//             {userData.isGoldenCustomer ? (
//               <GoldenBar points={userData.points} />
//             ) : (
//               <span className="text-white opacity-60">এখনও গোল্ডেন কাস্টমার নন</span>
//             )}
//           </div>
//         </div>

//         <div className="mb-8">
//           <nav className="flex flex-wrap gap-4">
//             <Button
//               onClick={() => setActiveTab('overview')}
//               className={`${
//                 activeTab === 'overview'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ওভারভিউ
//             </Button>
//             <Button
//               onClick={() => setActiveTab('activity')}
//               className={`${
//                 activeTab === 'activity'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               রেফারেল লগ
//             </Button>
//             {userData.isGoldenCustomer && (
//               <Button
//                 onClick={() => setActiveTab('withdraw')}
//                 className={`${
//                   activeTab === 'withdraw'
//                     ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                     : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//                 }`}
//               >
//                 উইথড্র
//               </Button>
//             )}
//           </nav>
//         </div>

//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -20 }}
//             transition={{ duration: 0.3 }}
//           >
//             {activeTab === 'overview' && (
//               <div className="space-y-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
//                   <FeatureCard
//                     icon={Coins}
//                     title="পয়েন্ট"
//                     value={userData.points}
//                     description="মোট অর্জিত পয়েন্ট"
//                     gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
//                   />
//                   <FeatureCard
//                     icon={DollarSign}
//                     title="ব্যালেন্স"
//                     value={`৳${userData.balance.toFixed(2)}`}
//                     description="বর্তমান অ্যাকাউন্ট ব্যালেন্স"
//                     gradient="bg-gradient-to-br from-green-400 to-teal-500"
//                   />
//                   {userData.isGoldenCustomer && (
//                     <>
//                       <FeatureCard
//                         icon={Gift}
//                         title="বোনাস"
//                         value={`৳${userData.earnedBonus.toFixed(2)}`}
//                         description="অর্জিত বোনাস"
//                         gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
//                       />
//                       <FeatureCard
//                         icon={Award}
//                         title="রয়্যালটি"
//                         value={`৳${userData.earnedRoyalty.toFixed(2)}`}
//                         description="অর্জিত রয়্যালটি"
//                         gradient="bg-gradient-to-br from-purple-400 to-pink-500"
//                       />
//                       <FeatureCard
//                         icon={TrendingUp}
//                         title="ইনসেন্টিভ"
//                         value={`৳${userData.earnedIncentive.toFixed(2)}`}
//                         description="অর্জিত ইনসেন্টিভ"
//                         gradient="bg-gradient-to-br from-red-400 to-pink-500"
//                       />
//                     </>
//                   )}
//                   <FeatureCard
//                     icon={Users}
//                     title="রেফারেল"
//                     value={userData.totalReferrals}
//                     description="মোট রেফারেল সংখ্যা"
//                     gradient="bg-gradient-to-br from-teal-400 to-green-500"
//                   />
//                 </div>

//                 {userData.message && (
//                   <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                     <h3 className="text-2xl font-bold mb-4 text-white flex items-center">
//                       সেলার মেসেজ
//                       {showMessageNotification && (
//                         <motion.span
//                           className="ml-2 inline-block h-3 w-3 bg-yellow-500 rounded-full"
//                           animate={{ scale: [1, 1.2, 1] }}
//                           transition={{ duration: 0.5, repeat: Infinity }}
//                         />
//                       )}
//                     </h3>
//                     <div className="bg-white bg-opacity-10 p-4 rounded-lg flex items-start space-x-3">
//                       <AlertCircle className="h-6 w-6 text-yellow-400 flex-shrink-0 mt-1" />
//                       <p className="text-white">{userData.message}</p>
//                     </div>
//                   </Card>
//                 )}

//                 <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">রেফারেল প্রোগ্রাম</h3>
//                   <p className="text-white opacity-80 mb-4">
//                     আপনার রেফারেল কোড শেয়ার করুন এবং বোনাস পয়েন্ট অর্জন করুন!
//                   </p>
//                   <div className="flex items-center space-x-2 mb-4">
//                     <input
//                       type="text"
//                       value={userData.customerId}
//                       readOnly
//                       className="bg-white bg-opacity-20 px-3 py-2 rounded-md flex-grow text-white"
//                     />
//                     <Button
//                       className="bg-yellow-400 text-black hover:bg-yellow-500"
//                       onClick={() => {
//                         navigator.clipboard.writeText(userData.customerId);
//                         alert('কপি করা হয়েছে!');
//                       }}
//                     >
//                       <Copy className="h-5 w-5" />
//                     </Button>
//                   </div>
//                   <Button className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600">
//                     <Sparkles className="h-5 w-5 mr-2" />
//                     রেফারেল লিংক শেয়ার করুন
//                   </Button>
//                 </Card>
//               </div>
//             )}
//             {activeTab === 'activity' && (
//               <Card className="bg-gradient-to-br from-pink-500 to-red-500">
//                 <h3 className="text-2xl font-bold mb-4 text-white">আপনার রেফারেলসমূহ</h3>
//                 <ul className="space-y-4">
//                   {userData.referredUsers.map((refUser, index) => (
//                     <motion.li
//                       key={index}
//                       className="flex items-center space-x-3 p-3 bg-white bg-opacity-20 rounded-lg"
//                       initial={{ opacity: 0, x: -20 }}
//                       animate={{ opacity: 1, x: 0 }}
//                       transition={{ duration: 0.3 }}
//                     >
//                       <Coins className="h-5 w-5 text-yellow-400" />
//                       <div>
//                         <p className="font-medium text-white">{refUser.name}</p>
//                         <p className="text-sm text-white opacity-80">{refUser.customerId}</p>
//                       </div>
//                     </motion.li>
//                   ))}
//                 </ul>
//               </Card>
//             )}
//             {activeTab === 'withdraw' && userData.isGoldenCustomer && (
//               <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট</h3>
//                 <form onSubmit={handleWithdraw} className="space-y-4">
//                   <div>
//                     <label htmlFor="amount" className="block text-sm font-medium text-white mb-1">
//                       পরিমাণ (৳)
//                     </label>
//                     <input
//                       type="number"
//                       id="amount"
//                       value={withdrawAmount}
//                       onChange={(e) => setWithdrawAmount(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="উইথড্র পরিমাণ লিখুন"
//                       required
//                     />
//                   </div>
//                   <div>
//                     <label htmlFor="method" className="block text-sm font-medium text-white mb-1">
//                       পেমেন্ট মেথড
//                     </label>
//                     <select
//                       id="method"
//                       value={withdrawMethod}
//                       onChange={(e) => setWithdrawMethod(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white"
//                       required
//                     >
//                       <option value="">পেমেন্ট মেথড নির্বাচন করুন</option>
//                       <option value="bkash">বিকাশ</option>
//                       <option value="nagad">নগদ</option>
//                     </select>
//                   </div>
//                   <div>
//                     <label htmlFor="phone" className="block text-sm font-medium text-white mb-1">
//                       ফোন নম্বর
//                     </label>
//                     <input
//                       type="tel"
//                       id="phone"
//                       value={phoneNumber}
//                       onChange={(e) => setPhoneNumber(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="আপনার ফোন নম্বর লিখুন"
//                       required
//                     />
//                   </div>
//                   <Button
//                     type="submit"
//                     className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600 transition-all duration-300"
//                   >
//                     <CreditCard className="h-5 w-5 mr-2" />
//                     উইথড্র রিকুয়েস্ট পাঠান
//                   </Button>
//                 </form>
//               </Card>
//             )}
//           </motion.div>
//         </AnimatePresence>
//       </main>

//       <footer
//         className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
//       >
//         <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//           <div className="mb-4 sm:mb-0">
//             <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
//             <p className="text-sm mt-2 text-white opacity-80">আপনার শপিং অভিজ্ঞতাকে সমৃদ্ধ করছে</p>
//           </div>
//           <nav>
//             <ul className="flex space-x-4">
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   গোপনীয়তা নীতি
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   ব্যবহারের শর্তাবলী
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   যোগাযোগ করুন
//                 </a>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </footer>
//     </div>
//   );
// }


//নতুন 



// UpdatedCustomerDashboard.jsx

// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Bell,
//   ChevronRight,
//   Copy,
//   Globe,
//   HelpCircle,
//   MessageCircle,
//   User,
//   LogOut,
//   Menu,
//   Gift,
//   TrendingUp,
//   ShoppingBag,
//   Coins,
//   Calendar,
//   Zap,
//   Award,
//   Sparkles,
//   DollarSign,
//   Send,
//   CreditCard,
//   Users,
//   AlertCircle,
//   Sun,
//   Moon,
// } from 'lucide-react';

// // Backend API URL
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/';

// // Button Component
// const Button = ({ children, className, ...props }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.button>
// );

// // Card Component
// const Card = ({ children, className, ...props }) => (
//   <motion.div
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//     whileHover={{ scale: 1.02 }}
//     className={`p-6 rounded-2xl shadow-xl ${className}`}
//     {...props}
//   >
//     {children}
//   </motion.div>
// );

// // FeatureCard Component
// const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
//   <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
//     <div className="p-3 rounded-full bg-white bg-opacity-20">
//       <Icon className="h-8 w-8 text-white" />
//     </div>
//     <div className="text-center">
//       <p className="text-lg font-medium text-white">{title}</p>
//       <p className="text-3xl font-bold text-white">{value}</p>
//       <p className="text-sm text-white opacity-80 mt-2">{description}</p>
//     </div>
//   </Card>
// );

// // GoldenBar Component
// const GoldenBar = ({ points }) => {
//   const brightness = Math.min(100 + points / 10, 200); // Brightness calculation
//   const level = Math.floor(points / 100) + 0; // Calculate level based on points

//   return (
//     <motion.div
//       className="w-40 h-10 bg-gradient-to-br from-yellow-500 via-yellow-600 to-yellow-700 overflow-hidden relative rounded-lg shadow-lg"
//       style={{ filter: `brightness(${brightness}%)` }}
//       animate={{
//         scale: [1, 1.05, 1],
//         boxShadow: [
//           '0 0 20px rgba(255, 223, 0, 0.8)',
//           '0 0 30px rgba(255, 215, 0, 1)',
//           '0 0 20px rgba(255, 223, 0, 0.8)',
//         ],
//       }}
//       transition={{
//         duration: 2,
//         repeat: Infinity,
//         ease: 'easeInOut',
//       }}
//     >
//       {/* Moving shimmer effect */}
//       <motion.div
//         className="absolute inset-0 h-full bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-800"
//         animate={{
//           x: ['-100%', '100%'],
//         }}
//         transition={{
//           duration: 3,
//           repeat: Infinity,
//           ease: 'easeInOut',
//         }}
//       />

//       {/* Center text */}
//       <div className="font-serif absolute inset-0 flex items-center justify-center text-white font-bold text-sm tracking-wide">
//         {`Golden Customer ${level}`}
//       </div>

//       {/* Shimmering border */}
//       <motion.div
//         className="absolute inset-0 w-full h-full border-4 rounded-lg border-yellow-400"
//         animate={{
//           opacity: [0.6, 0.9, 0.6],
//         }}
//         transition={{
//           duration: 1.5,
//           repeat: Infinity,
//           ease: 'easeInOut',
//         }}
//       />
//     </motion.div>
//   );
// };

// export default function UpdatedCustomerDashboard() {
//   const [activeTab, setActiveTab] = useState('overview');
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [userData, setUserData] = useState(null);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [withdrawAmount, setWithdrawAmount] = useState('');
//   const [withdrawMethod, setWithdrawMethod] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [showMessageNotification, setShowMessageNotification] = useState(false);
//   const [isNightMode, setIsNightMode] = useState(false);

//   useEffect(() => {
//     // Fetch user data from backend
//     const fetchUserData = async () => {
//       try {
//         const token = localStorage.getItem('token');
//         if (!token) {
//           window.location.href = '/login';
//           return;
//         }

//         const response = await fetch(`${API_BASE_URL}dashboard/customer`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setUserData(data);
//           if (data.message) {
//             setShowMessageNotification(true);
//           }
//         } else {
//           // Handle unauthorized access
//           window.location.href = '/login';
//         }
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//       }
//     };

//     fetchUserData();
//   }, []);

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     window.location.href = '/login';
//   };

//   const handleWithdraw = async (e) => {
//     e.preventDefault();

//     if (userData.balance < parseFloat(withdrawAmount)) {
//       alert('আপনার অ্যাকাউন্টে পর্যাপ্ত টাকা নেই।');
//       return;
//     }

//     try {
//       const token = localStorage.getItem('token');

//       const response = await fetch(`${API_BASE_URL}dashboard/customer/withdraw`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//           amount: parseFloat(withdrawAmount),
//           paymentMethod: withdrawMethod,
//           phoneNumber: phoneNumber, // ফোন নম্বর পাঠানো হচ্ছে
//         }),
//       });

//       if (response.ok) {
//         alert('উইথড্র রিকুয়েস্ট সফলভাবে পাঠানো হয়েছে।');
//         setWithdrawAmount('');
//         setWithdrawMethod('');
//         setPhoneNumber('');
//       } else {
//         const errorText = await response.text();
//         alert(`উইথড্র রিকুয়েস্ট ব্যর্থ হয়েছে: ${errorText}`);
//       }
//     } catch (error) {
//       console.error('Error submitting withdraw request:', error);
//       alert('কোনো সমস্যা হয়েছে। আবার চেষ্টা করুন।');
//     }
//   };

//   const toggleNightMode = () => {
//     setIsNightMode(!isNightMode);
//   };

//   const bgGradient = isNightMode
//     ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
//     : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

//   const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

//   if (!userData) {
//     return (
//       <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
//         <motion.div
//           animate={{ rotate: 360, scale: [1, 1.2, 1] }}
//           transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
//           className="w-16 h-16 border-t-4 border-b-4 border-white rounded-full"
//         ></motion.div>
//       </div>
//     );
//   }

//   return (
//     <div className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}>
//       <header
//         className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
//       >
//         <div className="max-w-7xl mx-auto flex justify-between items-center">
//           <div className="flex items-center space-x-2">
//             <Globe className="h-8 w-8 text-yellow-400" />
//             <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ</span>
//           </div>
//           <nav className="hidden md:flex items-center space-x-4">
//             <Button
//               className="bg-yellow-400 text-black hover:bg-yellow-500 relative"
//               onClick={() => setShowNotifications(!showNotifications)}
//             >
//               <Bell className="h-5 w-5" />
//               {showMessageNotification && (
//                 <span className="absolute -top-1 -right-1 h-3 w-3 bg-red-500 rounded-full"></span>
//               )}
//             </Button>
//             <Button className="bg-green-400 text-black hover:bg-green-500">
//               <User className="h-5 w-5 mr-2" />
//               প্রোফাইল
//             </Button>
//             <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
//               <LogOut className="h-5 w-5 mr-2" />
//               লগআউট
//             </Button>
//             <Button
//               onClick={toggleNightMode}
//               className={`p-2 rounded-full ${
//                 isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//               } hover:opacity-80`}
//             >
//               {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
//             </Button>
//           </nav>
//           <Button className="md:hidden bg-yellow-400 text-black" onClick={() => setShowMobileMenu(!showMobileMenu)}>
//             <Menu className="h-6 w-6" />
//           </Button>
//         </div>
//       </header>

//       <AnimatePresence>
//         {showMobileMenu && (
//           <motion.div
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: 'auto' }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//             className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
//           >
//             <div className="p-4 space-y-2">
//               <Button
//                 className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
//                 onClick={() => setShowNotifications(!showNotifications)}
//               >
//                 <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
//               </Button>
//               <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
//                 <User className="h-5 w-5 mr-2" /> প্রোফাইল
//               </Button>
//               <Button
//                 className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
//                 onClick={handleLogout}
//               >
//                 <LogOut className="h-5 w-5 mr-2" /> লগআউট
//               </Button>
//               <Button
//                 onClick={toggleNightMode}
//                 className={`flex items-center justify-center w-full ${
//                   isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
//                 } hover:opacity-80 rounded-md`}
//               >
//                 {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
//                 {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
//               </Button>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>

//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
//         <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
//           <div className="flex items-center space-x-4 mb-4 md:mb-0">
//             <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="relative">
//               <img
//                 src={'/path/to/default/profile/image.jpg'} // আপনি চাইলে এখানে প্রোফাইল ইমেজ যোগ করতে পারেন
//                 alt="প্রোফাইল"
//                 className="h-24 w-24 rounded-full border-4 border-white shadow-lg"
//               />
//               <div className="absolute bottom-0 right-0 bg-green-400 rounded-full p-1">
//                 <Zap className="h-4 w-4 text-white" />
//               </div>
//             </motion.div>
//             <div>
//               <h2 className="text-3xl font-bold text-white">{userData.name}</h2>
//               {/* ইমেইল ফিল্ড যদি না থাকে তাহলে এটি সরিয়ে দিতে পারেন */}
//               {/* <p className="text-white opacity-80">{userData.email}</p> */}
//               <p className="text-white opacity-80">ফোন: {userData.phone}</p>
//               <p className="text-sm text-white opacity-60">কাস্টমার আইডি: {userData.customerId}</p>
//             </div>
//           </div>
//           <div className="flex items-center space-x-2">
//             <span className="text-sm font-medium text-white">গোল্ডেন কাস্টমার:</span>
//             {userData.isGoldenCustomer ? (
//               <GoldenBar points={userData.points} />
//             ) : (
//               <span className="text-white opacity-60">এখনও গোল্ডেন কাস্টমার নন</span>
//             )}
//           </div>
//         </div>

//         <div className="mb-8">
//           <nav className="flex flex-wrap gap-4">
//             <Button
//               onClick={() => setActiveTab('overview')}
//               className={`${
//                 activeTab === 'overview'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               ওভারভিউ
//             </Button>
//             <Button
//               onClick={() => setActiveTab('activity')}
//               className={`${
//                 activeTab === 'activity'
//                   ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                   : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//               }`}
//             >
//               রেফারেল লগ
//             </Button>
//             {userData.isGoldenCustomer && (
//               <Button
//                 onClick={() => setActiveTab('withdraw')}
//                 className={`${
//                   activeTab === 'withdraw'
//                     ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
//                     : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
//                 }`}
//               >
//                 উইথড্র
//               </Button>
//             )}
//           </nav>
//         </div>

//         <AnimatePresence mode="wait">
//           <motion.div
//             key={activeTab}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             exit={{ opacity: 0, y: -20 }}
//             transition={{ duration: 0.3 }}
//           >
//             {activeTab === 'overview' && (
//               <div className="space-y-6">
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
//                   <FeatureCard
//                     icon={Coins}
//                     title="পয়েন্ট"
//                     value={userData.points}
//                     description="মোট অর্জিত পয়েন্ট"
//                     gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
//                   />
//                   <FeatureCard
//                     icon={DollarSign}
//                     title="ব্যালেন্স"
//                     value={`৳${userData.balance.toFixed(2)}`}
//                     description="বর্তমান অ্যাকাউন্ট ব্যালেন্স"
//                     gradient="bg-gradient-to-br from-green-400 to-teal-500"
//                   />
//                   {userData.isGoldenCustomer && (
//                     <>
//                       <FeatureCard
//                         icon={Gift}
//                         title="বোনাস"
//                         value={`৳${userData.earnedBonus.toFixed(2)}`}
//                         description="অর্জিত বোনাস"
//                         gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
//                       />
//                       <FeatureCard
//                         icon={Award}
//                         title="রয়্যালটি"
//                         value={`৳${userData.earnedRoyalty.toFixed(2)}`}
//                         description="অর্জিত রয়্যালটি"
//                         gradient="bg-gradient-to-br from-purple-400 to-pink-500"
//                       />
//                       <FeatureCard
//                         icon={TrendingUp}
//                         title="ইনসেন্টিভ"
//                         value={`৳${userData.earnedIncentive.toFixed(2)}`}
//                         description="অর্জিত ইনসেন্টিভ"
//                         gradient="bg-gradient-to-br from-red-400 to-pink-500"
//                       />
//                     </>
//                   )}
//                   <FeatureCard
//                     icon={Users}
//                     title="রেফারেল"
//                     value={userData.totalReferrals}
//                     description="মোট রেফারেল সংখ্যা"
//                     gradient="bg-gradient-to-br from-teal-400 to-green-500"
//                   />
//                 </div>

//                 {userData.message && (
//                   <Card className="bg-gradient-to-br from-green-600 to-teal-700">
//                     <h3 className="text-2xl font-bold mb-4 text-white flex items-center">
//                       সেলার মেসেজ
//                       {showMessageNotification && (
//                         <motion.span
//                           className="ml-2 inline-block h-3 w-3 bg-yellow-500 rounded-full"
//                           animate={{ scale: [1, 1.2, 1] }}
//                           transition={{ duration: 0.5, repeat: Infinity }}
//                         />
//                       )}
//                     </h3>
//                     <div className="bg-white bg-opacity-10 p-4 rounded-lg flex items-start space-x-3">
//                       <AlertCircle className="h-6 w-6 text-yellow-400 flex-shrink-0 mt-1" />
//                       <p className="text-white">{userData.message}</p>
//                     </div>
//                   </Card>
//                 )}

//                 <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
//                   <h3 className="text-2xl font-bold mb-4 text-white">রেফারেল প্রোগ্রাম</h3>
//                   <p className="text-white opacity-80 mb-4">
//                     আপনার রেফারেল কোড শেয়ার করুন এবং বোনাস পয়েন্ট অর্জন করুন!
//                   </p>
//                   <div className="flex items-center space-x-2 mb-4">
//                     <input
//                       type="text"
//                       value={userData.customerId}
//                       readOnly
//                       className="bg-white bg-opacity-20 px-3 py-2 rounded-md flex-grow text-white"
//                     />
//                     <Button
//                       className="bg-yellow-400 text-black hover:bg-yellow-500"
//                       onClick={() => {
//                         navigator.clipboard.writeText(userData.customerId);
//                         alert('কপি করা হয়েছে!');
//                       }}
//                     >
//                       <Copy className="h-5 w-5" />
//                     </Button>
//                   </div>
//                   <Button className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600">
//                     <Sparkles className="h-5 w-5 mr-2" />
//                     রেফারেল লিংক শেয়ার করুন
//                   </Button>
//                 </Card>
//               </div>
//             )}
//             {activeTab === 'activity' && (
//               <Card className="bg-gradient-to-br from-pink-500 to-red-500">
//                 <h3 className="text-2xl font-bold mb-4 text-white">আপনার রেফারেলসমূহ</h3>
//                 <ul className="space-y-4">
//                   {userData.referredUsers.map((refUser, index) => (
//                     <motion.li
//                       key={index}
//                       className="flex items-center space-x-3 p-3 bg-white bg-opacity-20 rounded-lg"
//                       initial={{ opacity: 0, x: -20 }}
//                       animate={{ opacity: 1, x: 0 }}
//                       transition={{ duration: 0.3 }}
//                     >
//                       <Coins className="h-5 w-5 text-yellow-400" />
//                       <div>
//                         <p className="font-medium text-white">{refUser.name}</p>
//                         <p className="text-sm text-white opacity-80">{refUser.customerId}</p>
//                       </div>
//                     </motion.li>
//                   ))}
//                 </ul>
//               </Card>
//             )}
//             {activeTab === 'withdraw' && userData.isGoldenCustomer && (
//               <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
//                 <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট</h3>
//                 <form onSubmit={handleWithdraw} className="space-y-4">
//                   <div>
//                     <label htmlFor="amount" className="block text-sm font-medium text-white mb-1">
//                       পরিমাণ (৳)
//                     </label>
//                     <input
//                       type="number"
//                       id="amount"
//                       value={withdrawAmount}
//                       onChange={(e) => setWithdrawAmount(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="উইথড্র পরিমাণ লিখুন"
//                       required
//                     />
//                   </div>
//                   <div>
//                     <label htmlFor="method" className="block text-sm font-medium text-white mb-1">
//                       পেমেন্ট মেথড
//                     </label>
//                     <select
//                       id="method"
//                       value={withdrawMethod}
//                       onChange={(e) => setWithdrawMethod(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white"
//                       required
//                     >
//                       <option value="">পেমেন্ট মেথড নির্বাচন করুন</option>
//                       <option value="bkash">বিকাশ</option>
//                       <option value="nagad">নগদ</option>
//                     </select>
//                   </div>
//                   <div>
//                     <label htmlFor="phone" className="block text-sm font-medium text-white mb-1">
//                       ফোন নম্বর
//                     </label>
//                     <input
//                       type="tel"
//                       id="phone"
//                       value={phoneNumber}
//                       onChange={(e) => setPhoneNumber(e.target.value)}
//                       className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
//                       placeholder="আপনার ফোন নম্বর লিখুন"
//                       required
//                     />
//                   </div>
//                   <Button
//                     type="submit"
//                     className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600 transition-all duration-300"
//                   >
//                     <CreditCard className="h-5 w-5 mr-2" />
//                     উইথড্র রিকুয়েস্ট পাঠান
//                   </Button>
//                 </form>
//               </Card>
//             )}
//           </motion.div>
//         </AnimatePresence>
//       </main>

//       <footer
//         className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
//       >
//         <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
//           <div className="mb-4 sm:mb-0">
//             <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
//             <p className="text-sm mt-2 text-white opacity-80">আপনার শপিং অভিজ্ঞতাকে সমৃদ্ধ করছে</p>
//           </div>
//           <nav>
//             <ul className="flex space-x-4">
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   গোপনীয়তা নীতি
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   ব্যবহারের শর্তাবলী
//                 </a>
//               </li>
//               <li>
//                 <a href="#" className="hover:text-yellow-400 transition-colors">
//                   যোগাযোগ করুন
//                 </a>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </footer>
//     </div>
//   );
// }





// src/Pages/CustomerDashboard.jsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import {
  Bell,
  Copy,
  Globe,
  HelpCircle,
  MessageCircle,
  User,
  LogOut,
  Menu,
  Gift,
  TrendingUp,
  ShoppingBag,
  Coins,
  Calendar,
  Zap,
  Award,
  Sparkles,
  DollarSign,
  Send,
  CreditCard,
  Users,
  AlertCircle,
  Sun,
  ShoppingCart,
  Moon,
} from 'lucide-react';

import { useTheme } from '../ThemeContext'; // ThemeContext থেকে useTheme হুক ইমপোর্ট

// Backend API URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:50005/';

// Button Component
const Button = ({ children, className, ...props }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
    {...props}
  >
    {children}
  </motion.button>
);

// Card Component
const Card = ({ children, className, ...props }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.02 }}
    className={`p-6 rounded-2xl shadow-xl ${className}`}
    {...props}
  >
    {children}
  </motion.div>
);

// FeatureCard Component
const FeatureCard = ({ icon: Icon, title, value, description, gradient }) => (
  <Card className={`flex flex-col items-center space-y-4 overflow-hidden relative ${gradient}`}>
    <div className="p-3 rounded-full bg-white bg-opacity-20">
      <Icon className="h-8 w-8 text-white" />
    </div>
    <div className="text-center">
      <p className="text-lg font-medium text-white">{title}</p>
      <p className="text-3xl font-bold text-white">{value}</p>
      <p className="text-sm text-white opacity-80 mt-2">{description}</p>
    </div>
  </Card>
);

// GoldenBar Component
const GoldenBar = ({ points }) => {
  const brightness = Math.min(100 + points / 10, 200); // Brightness calculation
  const level = Math.floor(points / 100) + 0; // Calculate level based on points

  return (
    <motion.div
      className="w-40 h-10 bg-gradient-to-br from-yellow-500 via-yellow-600 to-yellow-700 overflow-hidden relative rounded-lg shadow-lg"
      style={{ filter: `brightness(${brightness}%)` }}
      animate={{
        scale: [1, 1.05, 1],
        boxShadow: [
          '0 0 20px rgba(255, 223, 0, 0.8)',
          '0 0 30px rgba(255, 215, 0, 1)',
          '0 0 20px rgba(255, 223, 0, 0.8)',
        ],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: 'easeInOut',
      }}
    >
      {/* Moving shimmer effect */}
      <motion.div
        className="absolute inset-0 h-full bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-800"
        animate={{
          x: ['-100%', '100%'],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />

      {/* Center text */}
      <div className="font-serif absolute inset-0 flex items-center justify-center text-white font-bold text-sm tracking-wide">
        {`Golden Customer ${level}`}
      </div>

      {/* Shimmering border */}
      <motion.div
        className="absolute inset-0 w-full h-full border-4 rounded-lg border-yellow-400"
        animate={{
          opacity: [0.6, 0.9, 0.6],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />
    </motion.div>
  );
};

// CustomerItem Component
const CustomerItem = ({ name, customerId, points }) => (
  <motion.li
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    className="flex items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
  >
    <div>
      <p className="font-medium text-white">{name}</p>
      <p className="text-sm text-white opacity-80">ID: {customerId}</p>
    </div>
    <div className="text-right">
      <p className="text-lg font-bold text-yellow-300">{points} পয়েন্ট</p>
    </div>
  </motion.li>
);

// WithdrawRequestItem Component
const WithdrawRequestItem = ({ request, onApprove }) => {
  if (!request || !request.user) {
    return null; // অথবা একটি মেসেজ দেখাতে পারেন
  }

  return (
    <motion.li
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      className="flex flex-col md:flex-row md:items-center justify-between p-4 bg-white bg-opacity-20 rounded-lg mb-2"
    >
      <div className="mb-4 md:mb-0">
        <p className="font-medium text-white">কাস্টমার ID: {request.user.customerId}</p>
        <p className="text-sm text-white opacity-80">নাম: {request.user.name}</p>
        <p className="text-sm text-white opacity-80">ফোন নম্বর: {request.phoneNumber}</p>
        <p className="text-sm text-white opacity-80">পেমেন্ট মেথড: {request.paymentMethod}</p>
        <p className="text-sm text-white opacity-80">
          রিকুয়েস্টের তারিখ: {new Date(request.createdAt).toLocaleString()}
        </p>
        <p className="text-sm text-white opacity-80">স্ট্যাটাস: {request.status}</p>
        {request.approvedAt && (
          <p className="text-sm text-white opacity-80">
            অ্যাপ্রুভের সময়: {new Date(request.approvedAt).toLocaleString()}
          </p>
        )}
      </div>
      <div className="text-right">
        <p className="text-lg font-bold text-green-300">৳{request.amount}</p>
        {request.status === 'pending' && (
          <Button
            className="mt-2 bg-green-500 text-white hover:bg-green-600"
            onClick={() => onApprove(request.id)}
          >
            <CheckCircle className="h-5 w-5 mr-1" /> অ্যাপ্রুভ
          </Button>
        )}
      </div>
    </motion.li>
  );
};

export default function CustomerDashboard() {
  const { isNightMode, toggleNightMode } = useTheme(); // ThemeContext থেকে থিম এবং টগল ফাংশন
  const [activeTab, setActiveTab] = useState('overview');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawMethod, setWithdrawMethod] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showMessageNotification, setShowMessageNotification] = useState(false);

  useEffect(() => {
    // Fetch user data from backend
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/login';
          return;
        }

        const response = await fetch(`${API_BASE_URL}dashboard/customer`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          if (data.message) {
            setShowMessageNotification(true);
          }
        } else {
          // Handle unauthorized access
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();

    if (userData.balance < parseFloat(withdrawAmount)) {
      alert('আপনার অ্যাকাউন্টে পর্যাপ্ত টাকা নেই।');
      return;
    }

    try {
      const token = localStorage.getItem('token');

      const response = await fetch(`${API_BASE_URL}dashboard/customer/withdraw`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          amount: parseFloat(withdrawAmount),
          paymentMethod: withdrawMethod,
          phoneNumber: phoneNumber, // ফোন নম্বর পাঠানো হচ্ছে
        }),
      });

      if (response.ok) {
        alert('উইথড্র রিকুয়েস্ট সফলভাবে পাঠানো হয়েছে।');
        setWithdrawAmount('');
        setWithdrawMethod('');
        setPhoneNumber('');
      } else {
        const errorText = await response.text();
        alert(`উইথড্র রিকুয়েস্ট ব্যর্থ হয়েছে: ${errorText}`);
      }
    } catch (error) {
      console.error('Error submitting withdraw request:', error);
      alert('কোনো সমস্যা হয়েছে। আবার চেষ্টা করুন।');
    }
  };

  const bgGradient = isNightMode
    ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
    : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

  const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

  if (!userData) {
    return (
      <div className={`flex items-center justify-center h-screen ${bgGradient}`}>
        <motion.div
          animate={{ rotate: 360, scale: [1, 1.2, 1] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
          className="w-16 h-16 border-t-4 border-b-4 border-white rounded-full"
        ></motion.div>
      </div>
    );
  }


//import React from 'react';

// const CopyCustomerId = ({ customerId }) => {
//   const handleCopy = () => {
//     navigator.clipboard.writeText(customerId)
//       .then(() => {
//         alert('কাস্টমার আইডি কপি হয়ে গেছে!');
        
//       })
//       .catch((err) => {
//         console.error('কপি করার সময় সমস্যা হয়েছে: ', err);
//       });
//   };

//   return (
//     <p
//       className="text-sm text-white opacity-90 cursor-pointer"
//       onClick={handleCopy}
//     >
//       কাস্টমার আইডি: {customerId}
//     </p>
//   );
// };

//export default CopyCustomerId;





// const CopyCustomerId = ({ customerId }) => {
//   const [copyMessage, setCopyMessage] = useState(false);

//   const handleCopy = () => {
//     navigator.clipboard.writeText(customerId)
//       .then(() => {
//         setCopyMessage(true);
//         setTimeout(() => {
//           setCopyMessage(false); // মেসেজটি ২ সেকেন্ড পরে সরিয়ে দেওয়া হবে
//         }, 2000);
//       })
//       .catch((err) => {
//         console.error('কপি করার সময় সমস্যা হয়েছে: ', err);
//       });
//   };

//   return (
//     <div style={{ position: 'relative', display: 'inline-block' }}>
//       <p
//         className="text-sm text-white opacity-90 flex items-center cursor-pointer"
//         style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
//         onClick={handleCopy}
//       >
//         কাস্টমার আইডি: {customerId}
//         {/* Lucide React Copy আইকন */}
//         <Copy
//           size={12}
//           color="white"
//           style={{ cursor: 'pointer' }}
//           title="কপি করুন"
//         />
//       </p>

//       {/* কপি কনফার্মেশন মেসেজ */}
//       {copyMessage && (
//         <div
//           style={{
//             position: 'absolute',
//             top: '100%', // মূল টেক্সটের নিচে দেখাবে
//             left: '0',
//             marginTop: '5px',
//             padding: '5px 10px',
//             backgroundColor: '#4CAF50',
//             color: 'white',
//             borderRadius: '5px',
//             fontSize: '12px',
//             whiteSpace: 'nowrap',
//             zIndex: 9999,
//             boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
//           }}
//         >
//           কাস্টমার আইডি কপি হয়ে গেছে!
//         </div>
//       )}
//     </div>
//   );
// };






// const CopyCustomerId = ({ customerId }) => {
//   const [copyMessage, setCopyMessage] = useState(false);

//   const handleCopy = () => {
//     navigator.clipboard.writeText(customerId)
//       .then(() => {
//         setCopyMessage(true);
//         setTimeout(() => {
//           setCopyMessage(false); // মেসেজটি ২ সেকেন্ড পরে অদৃশ্য হবে
//         }, 2000);
//       })
//       .catch((err) => {
//         console.error('কপি করার সময় সমস্যা হয়েছে: ', err);
//       });
//   };

//   return (
//     <div
//       style={{
//         display: 'inline-block',
//       border: '1px solid #ddd',
//       padding: '8px 12px',
//       borderRadius: '5px',
//         cursor: 'pointer',
//         color: 'white',
//       backgroundColor: '#f9f9f9',
//         position: 'relative',
//         boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
//         transition: 'background-color 0.3s',
//       }}
//       onClick={handleCopy}
//       title="ক্লিক করে কপি করুন"
//       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#')}
//       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#')}
//     >
//       <p
//         className="text-sm flex items-center"
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           gap: '8px',
//         //  fontSize: '18px',
//           margin: 0,
//         }}
//       >
//         <span style={{ fontWeight: 'bold', fontSize:'29px'}}> ID: {customerId}</span> 
//         {/* Lucide React Copy আইকন */}
//         <Copy size={16} color="#ffffff" />
//       </p>

//       {/* কপি কনফার্মেশন মেসেজ */}
//       {copyMessage && (
//         <div
//           style={{
//             position: 'absolute',
//             top: '-25px',
//             left: '50%',
//             transform: 'translateX(-50%)',
//             backgroundColor: '#4CAF50',
//             color: '#fff',
//             padding: '5px 10px',
//             borderRadius: '5px',
//             fontSize: '12px',
//             whiteSpace: 'nowrap',
//             boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
//           }}
//         >
//           কপি হয়ে গেছে!
//         </div>
//       )}
//     </div>
//   );
// };








// const CopyCustomerId = ({ customerId }) => {
//   const [copyMessage, setCopyMessage] = useState(false);

//   const handleCopy = () => {
//     navigator.clipboard.writeText(customerId)
//       .then(() => {
//         setCopyMessage(true);
//         setTimeout(() => {
//           setCopyMessage(false); // মেসেজটি ২ সেকেন্ড পরে অদৃশ্য হবে
//         }, 2000);
//       })
//       .catch((err) => {
//         console.error('কপি করার সময় সমস্যা হয়েছে: ', err);
//       });
//   };

//   return (
//     <div
//       style={{
//         display: 'flex',
//         border:'ridge white 2px',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         padding: '4px',
//         borderRadius: '8px',
//         cursor: 'pointer',
//         backgroundColor: '#', // হালকা ধূসর ব্যাকগ্রাউন্ড
//         boxShadow: '1px 1px 2px rgba(0, 0, 0, 2.6)', // মৃদু ছায়া
//         position: 'relative',
//         color: 'white', // ডার্ক গ্রে টেক্সট
//         fontWeight: 'bold',
//         fontSize: '18px',
//       //  padding:'2px',
//         maxWidth: '280px',
//         margin: '10px auto',
//       }}
//       onClick={handleCopy}
//       title="ক্লিক করে কপি করুন"
//     >
//       {/* কাস্টমার আইডি */}
//       <span>
//         ID: {customerId}
//       </span>

//       {/* কপি আইকন */}
//       <Copy size={20} color="#ffffff" /> {/* সলিড ব্লু আইকন */}

//       {/* কপি কনফার্মেশন মেসেজ */}
//       {copyMessage && (
//         <div
//           style={{
//             position: 'absolute',
//             top: '40px',
//             left: '50%',
//             transform: 'translateX(-50%)',
//             backgroundColor: '#277cffc5', // ডার্ক ব্লু ব্যাকগ্রাউন্ড
//             color: '#FFFFFF',
//             padding: '5px 10px',
//             borderRadius: '5px',
//             fontSize: '12px',
//             whiteSpace: 'nowrap',
//             boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
//           }}
//         >
//           কপি হয়ে গেছে! {customerId}
//         </div>
//       )}
//     </div>
//   );
// };





const CopyCustomerId = ({ customerId }) => {
  const [copyMessage, setCopyMessage] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(customerId)
      .then(() => {
        setCopyMessage(true);
        setTimeout(() => {
          setCopyMessage(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        border: 'ridge white 2px',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px',
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: '#',
        // boxShadow: '0px 0px 2px rgba(255, 255, 255, 0.8), 0px 0px 20px rgba(255, 255, 255, 0.8)',
        boxShadow: '0px -2px 6px rgba(255, 255, 255, 0.8), 0px 4px 12px rgba(0, 0, 0, 0.6)', 
        position: 'relative',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '18px',
        maxWidth: '280px',
        margin: '10px auto',
        animation: 'colorChange 2s infinite alternate', // এখানে অ্যানিমেশন যুক্ত করা হয়েছে
        
      }}
      onClick={handleCopy}
      title="Click to copy"
    >
      <span> My ID: {customerId}</span>
      <Copy size={20} color="#ffffff" />
      {copyMessage && (
        <div
          style={{
            position: 'absolute',
            top: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#277cffc5',
            color: '#FFFFFF',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
            
          }}
        >
          Copied! {customerId}
        </div>
      )}
    </div>
  );
};

const styles = `



`;

// Add the styles to the page
document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);
















  return (
    <div className={`min-h-screen ${bgGradient} font-bangla text-white transition-colors duration-500`}>
      <header
        className={`sticky top-0 z-10 w-full py-4 px-4 sm:px-6 lg:px-8 ${cardBg} backdrop-filter backdrop-blur-lg shadow-md`}
      >
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <ShoppingCart className="h-8 w-8 text-yellow-400" />
            <span className="text-2xl font-bold text-white">বাজারপ্রতিক্ষণ</span>
          </div>
          <nav className="hidden md:flex items-center space-x-4">
            <Button
              className="bg-yellow-400 text-black hover:bg-yellow-500 relative"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <Bell className="h-5 w-5" />
              {showMessageNotification && (
                <span className="absolute -top-1 -right-1 h-3 w-3 bg-red-500 rounded-full"></span>
              )}
            </Button>
            <Button className="bg-green-400 text-black hover:bg-green-500">
              <User className="h-5 w-5 mr-2" />
              প্রোফাইল
            </Button>
            <Button className="bg-red-400 text-black hover:bg-red-500" onClick={handleLogout}>
              <LogOut className="h-5 w-5 mr-2" />
              লগআউট
            </Button>
            <Button
              onClick={toggleNightMode}
              className={`p-2 rounded-full ${
                isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
              } hover:opacity-80`}
            >
              {isNightMode ? <Sun size={20} /> : <Moon size={20} />}
            </Button>
          </nav>
          <Button className="md:hidden bg-yellow-400 text-black" onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <Menu className="h-6 w-6" />
          </Button>
        </div>
      </header>

      <AnimatePresence>
        {showMobileMenu && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className={`md:hidden ${cardBg} backdrop-filter backdrop-blur-lg overflow-hidden shadow-md`}
          >
            <div className="p-4 space-y-2">
              <Button
                className="flex items-center justify-center w-full bg-yellow-400 text-black hover:bg-yellow-500 rounded-md"
                onClick={() => setShowNotifications(!showNotifications)}
              >
                <Bell className="h-5 w-5 mr-2" /> নোটিফিকেশন
              </Button>
              <Button className="flex items-center justify-center w-full bg-green-400 text-black hover:bg-green-500 rounded-md">
                <User className="h-5 w-5 mr-2" /> প্রোফাইল
              </Button>
              <Button
                className="flex items-center justify-center w-full bg-red-400 text-black hover:bg-red-500 rounded-md"
                onClick={handleLogout}
              >
                <LogOut className="h-5 w-5 mr-2" /> লগআউট
              </Button>
              <Button
                onClick={toggleNightMode}
                className={`flex items-center justify-center w-full ${
                  isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
                } hover:opacity-80 rounded-md`}
              >
                {isNightMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
                {isNightMode ? 'দিনের মোড' : 'রাতের মোড'}
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex items-center space-x-4 mb-4 md:mb-0">
            
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="relative">
              <img
                src={'https://bazarprotikhon.com/Logo.jpg?0.3'} // ইমেজ যোগ করতে পারেন
                
                alt="প্রোফাইল"
                className="h-24 w-24 rounded-full border-4 border-white shadow-lg"
              />
              <div className="absolute bottom-0 right-0 bg-green-400 rounded-full p-1">
                <Zap className="h-4 w-4 text-white" />
              </div>
            </motion.div>
            <div className="w-40">
              <h2 className="text-3xl font-bold text-white">{userData.name}</h2>
              <p className="text-white opacity-90">Phone: {userData.phone}</p>
              
              
              <CopyCustomerId customerId={userData.customerId} />

              
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium text-white">গোল্ডেন কাস্টমার:</span>
            {userData.isGoldenCustomer ? (
              <GoldenBar points={userData.points} />
            ) : (
              <span className="text-white opacity-60">এখনও গোল্ডেন কাস্টমার নন</span>
            )}
          </div>
        </div>

        <div className="mb-8">
          <nav className="flex flex-wrap gap-4">
            <Button
              onClick={() => setActiveTab('overview')}
              className={`${
                activeTab === 'overview'
                  ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              }`}
            >
              ওভারভিউ
            </Button>
            <Button
              onClick={() => setActiveTab('activity')}
              className={`${
                activeTab === 'activity'
                  ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              }`}
            >
              রেফারেল লগ
            </Button>
            {userData.isGoldenCustomer && (
              <Button
                onClick={() => setActiveTab('withdraw')}
                className={`${
                  activeTab === 'withdraw'
                    ? 'bg-gradient-to-r from-green-400 to-blue-500 text-white'
                    : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
                }`}
              >
                উইথড্র
              </Button>
            )}
          </nav>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeTab === 'overview' && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-6">
                  <FeatureCard
                    icon={Coins}
                    title="পয়েন্ট"
                    value={userData.points}
                    description="মোট অর্জিত পয়েন্ট"
                    gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
                  />
                  <FeatureCard
                    icon={DollarSign}
                    title="ব্যালেন্স"
                    value={`৳${userData.balance.toFixed(2)}`}
                    description="বর্তমান অ্যাকাউন্ট ব্যালেন্স"
                    gradient="bg-gradient-to-br from-green-400 to-teal-500"
                  />
                  {userData.isGoldenCustomer && (
                    <>
                      <FeatureCard
                        icon={Gift}
                        title="বোনাস"
                        value={`৳${userData.earnedBonus.toFixed(2)}`}
                        description="অর্জিত বোনাস"
                        gradient="bg-gradient-to-br from-blue-400 to-indigo-500"
                      />
                      <FeatureCard
                        icon={Award}
                        title="রয়্যালটি"
                        value={`৳${userData.earnedRoyalty.toFixed(2)}`}
                        description="অর্জিত রয়্যালটি"
                        gradient="bg-gradient-to-br from-purple-400 to-pink-500"
                      />
                      <FeatureCard
                        icon={TrendingUp}
                        title="ইনসেন্টিভ"
                        value={`৳${userData.earnedIncentive.toFixed(2)}`}
                        description="অর্জিত ইনসেন্টিভ"
                        gradient="bg-gradient-to-br from-red-400 to-pink-500"
                      />
                    </>
                  )}
                               
                  <FeatureCard
                  
                  

                  
                    icon={Users}
                    title="রেফারেল"
                    value={userData.totalReferrals}
                    description="মোট রেফারেল সংখ্যা"
                    gradient="bg-gradient-to-br from-teal-400 to-green-500"
                  />
                     
                </div>

                {userData.message && (
                  <Card className="bg-gradient-to-br from-green-600 to-teal-700">
                    <h3 className="text-2xl font-bold mb-4 text-white flex items-center">
                      সেলার মেসেজ
                      {showMessageNotification && (
                        <motion.span
                          className="ml-2 inline-block h-3 w-3 bg-yellow-500 rounded-full"
                          animate={{ scale: [1, 1.2, 1] }}
                          transition={{ duration: 0.5, repeat: Infinity }}
                        />
                      )}
                    </h3>
                    <div className="bg-white bg-opacity-10 p-4 rounded-lg flex items-start space-x-3">
                      <AlertCircle className="h-6 w-6 text-yellow-600 flex-shrink-0 mt-1" />
                      <p className="text-white">{userData.message}</p>
                    </div>
                  </Card>
                )}

                <Card className="bg-gradient-to-br from-blue-600 to-indigo-700">
                  <h3 className="text-2xl font-bold mb-4 text-white">রেফারেল প্রোগ্রাম</h3>
                  <p className="text-white opacity-80 mb-4">
                    আপনার রেফারেল কোড শেয়ার করুন এবং বোনাস  অর্জন করুন!
                  </p>
                  <div className="flex items-center space-x-2 mb-4">
                    <input
                      type="text"
                      value={userData.customerId}
                      readOnly
                      className="bg-white bg-opacity-20 px-3 py-2 rounded-md flex-grow text-white"
                    />
                    <Button
                      className="bg-yellow-400 text-black hover:bg-yellow-500"
                      onClick={() => {
                        navigator.clipboard.writeText(userData.customerId);
                        alert('কপি করা হয়েছে!');
                      }}
                    >
                      <Copy className="h-5 w-5" />
                    </Button>
                  </div>
               <Button className="w-full bg-gradient-to-r from-yellow-400 to-orange-500 text-black hover:from-yellow-500 hover:to-orange-600 transition-all duration-300 flex items-center justify-center py-3 px-4" onClick={() => {
                        navigator.clipboard.writeText(userData.customerId);
                        alert('কপি করা হয়েছে! ');
                      }}>
  <Sparkles className="h-5 w-5 mr-3" />
  রেফারেল কোড কপি করুন
</Button>

                </Card>
              </div>
            )}
            {activeTab === 'activity' && (
              <Card className="bg-gradient-to-br from-pink-500 to-red-500">
                <h3 className="text-2xl font-bold mb-4 text-white">আপনার রেফারেলসমূহ</h3>
                <ul className="space-y-4">
                 
                  {userData.referredUsers.map((refUser, index) => (
                    <motion.li
                      key={index}
                      className="flex items-center space-x-3 p-3 bg-white bg-opacity-20 rounded-lg"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Coins className="h-5 w-5 text-yellow-400" />
                      <div>
                        <p className="font-medium text-white">{refUser.name}</p>
                        <p className="text-sm text-white opacity-80">{refUser.customerId}</p>
                      </div>
                    </motion.li>
                  ))}
                </ul>
              </Card>
            )}
            {activeTab === 'withdraw' && userData.isGoldenCustomer && (
              <Card className="bg-gradient-to-br from-purple-600 to-indigo-700">
                <h3 className="text-2xl font-bold mb-4 text-white">উইথড্র রিকুয়েস্ট</h3>
                <form onSubmit={handleWithdraw} className="space-y-4">
                  <div>
                    <label htmlFor="amount" className="block text-sm font-medium text-white mb-1">
                      পরিমাণ (৳)
                    </label>
                    <input
                      type="number"
                      id="amount"
                      value={withdrawAmount}
                      onChange={(e) => setWithdrawAmount(e.target.value)}
                      className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
                      placeholder="উইথড্র পরিমাণ লিখুন"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="method" className="block text-sm font-medium text-white mb-1">
                      পেমেন্ট মেথড
                    </label>
                    <select
                      id="method"
                      value={withdrawMethod}
                      onChange={(e) => setWithdrawMethod(e.target.value)}
                      className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white"
                      required
                    >
                      <option value="">পেমেন্ট মেথড নির্বাচন করুন</option>
                      <option value="bkash">বিকাশ</option>
                      <option value="nagad">নগদ</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-white mb-1">
                      ফোন নম্বর
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-full px-4 py-2 rounded-md bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
                      placeholder="আপনার ফোন নম্বর লিখুন"
                      required
                    />
                  </div>
                <Button
  type="submit"
  className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600 transition-all duration-300 flex items-center justify-center py-3 px-4"
>
  <CreditCard className="h-5 w-5 mr-3" />
  উইথড্র রিকুয়েস্ট পাঠান
</Button>

                </form>
              </Card>
            )}
          </motion.div>
        </AnimatePresence>
      </main>

      <footer
        className={`${cardBg} backdrop-filter backdrop-blur-lg text-white py-8 px-4 sm:px-6 lg:px-8 mt-12 shadow-inner`}
      >
        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center">
          <div className="mb-4 sm:mb-0">
            <span className="text-2xl font-bold text-yellow-400">বাজারপ্রতিক্ষণ</span>
            <p className="text-sm mt-2 text-white opacity-80">আপনার শপিং অভিজ্ঞতাকে সমৃদ্ধ করছে</p>
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a href="#" className="hover:text-yellow-400 transition-colors">
                  গোপনীয়তা নীতি
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-yellow-400 transition-colors">
                  ব্যবহারের শর্তাবলী
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-yellow-400 transition-colors">
                  যোগাযোগ করুন
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
}
