// client/src/Components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, allowedUserType }) {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token) {
    // যদি টোকেন না থাকে, তাহলে লগইন পেজে রিডাইরেক্ট হবে
    return <Navigate to="/login" replace />;
  }

  if (allowedUserType && userType !== allowedUserType) {
    // যদি ইউজার টাইপ অনুমোদিত না হয়, তাহলে হোম পেজে রিডাইরেক্ট হবে
    return <Navigate to="/" replace />;
  }

  return children;
}

export default PrivateRoute;
