// src/ThemeContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isNightMode, setIsNightMode] = useState(() => {
    // প্রথমে localStorage থেকে থিম স্টেট লোড করা হচ্ছে
    const savedTheme = localStorage.getItem('nightMode');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  const toggleNightMode = () => {
    const newMode = !isNightMode;
    setIsNightMode(newMode);
    localStorage.setItem('nightMode', JSON.stringify(newMode)); // localStorage এ থিম সংরক্ষণ
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('nightMode');
    if (savedTheme !== null) {
      setIsNightMode(JSON.parse(savedTheme));
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ isNightMode, toggleNightMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
