// // client/src/Components/Navbar.js

// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// function Navbar() {
//   const token = localStorage.getItem('token');
//   const userType = localStorage.getItem('userType');
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('userType');
//     navigate('/login');
//   };

//   return (
//     <nav className="bg-blue-600 shadow-md py-4 px-6">
//       <div className="max-w-7xl mx-auto flex justify-between items-center">
//         {/* Logo Section */}
//         <div className="text-white font-bold text-1xl">
//           <Link to="/">BazarProtikhon</Link>
//         </div>
        
//         {/* Links Section */}
//         <div className="flex items-center space-x-6">
//           {token ? (
//             <>
//               <Link
//                 className="text-white ml-1 text-lg font-medium hover:text-yellow-400 transition-colors duration-200"
//                 to={`/${userType}-dashboard`}
//               >
//                 Dashboard
//               </Link>
//               <button
//                 onClick={handleLogout}
//                 className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300"
//               >
//                 Logout
//               </button>
//             </>
//           ) : (
//             <>
//               <Link
//                 className="text-white text-lg font-medium hover:text-yellow-400 transition-colors duration-200"
//                 to="/login"
//               >
//                 Login
//               </Link>
//               <span className="text-white">|</span>
//               <Link
//                 className="text-white text-lg font-medium hover:text-yellow-400 transition-colors duration-200"
//                 to="/register"
//               >
//                 Register
//               </Link>
//             </>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;


// client/src/Components/Navbar.js

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';
import { useTheme } from '../ThemeContext'; // ThemeContext থেকে useTheme হুক ইমপোর্ট

// Button Component (Optional: Reuse the Button component from a shared location if available)
const Button = ({ children, className, ...props }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`px-4 py-2 rounded-full font-semibold text-sm transition-all duration-300 focus:outline-none ${className}`}
    {...props}
  >
    {children}
  </motion.button>
);

// Framer Motion import for Button animations
import { motion } from 'framer-motion';

function Navbar() {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');
  const navigate = useNavigate();
  const { isNightMode, toggleNightMode } = useTheme(); // থিম স্টেট এবং টগল ফাংশন পাবার জন্য

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    navigate('/login');
  };

  // থিম অনুযায়ী ব্যাকগ্রাউন্ড গ্রেডিয়েন্ট সেট করা
  const bgGradient = isNightMode
    ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
    : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

  return (
    <nav className={`${bgGradient} shadow-md py-4 px-4 backdrop-filter backdrop-blur-lg transition-colors duration-500`}>
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        {/* লোগো সেকশন */}
        <div className="text-white font-bold text-1xl">
          <Link to="/">BazarProtikhon</Link>
        </div>
        
        {/* লিঙ্কস সেকশন */}
        <div className="flex items-center space-x-5">
          {token ? (
            <>
              <Link
                className="text-white ml-1 text-lg font-medium hover:text-yellow-400 transition-colors duration-200"
                to={`/${userType}-dashboard`}
              >
                Dashboard
              </Link>
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition duration-300"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link
                className="text-white text-lg font-medium hover:text-yellow-400 transition-colors duration-200"
                to="/login"
              >
                Login
              </Link>
              <span className="text-white">|</span>
              <Link
                className="text-white text-lg font-medium hover:text-yellow-400 transition-colors duration-200"
                to="/register"
              >
                Register
              </Link>
            </>
          )}
          {/* থিম টগল বোতাম */}
          <button
            onClick={toggleNightMode}
            className={`ml-4 p-2 rounded-full ${
              isNightMode ? 'bg-yellow-400 text-black' : 'bg-indigo-600 text-white'
            } hover:opacity-80 transition duration-300`}
          >
            {isNightMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
