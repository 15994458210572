// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { Eye, EyeOff, Check, AlertCircle, Lock, Mail, User } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';
// const Uaip = process.env.REACT_APP_API_BASE_URL;
// // Button component
// const Button = ({ children, onClick, className, disabled }) => (
//   <button
//     onClick={onClick}
//     className={`py-2 px-4 rounded-md font-medium ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
//     disabled={disabled}
//   >
//     {children}
//   </button>
// );

// // Input component
// const Input = ({ id, type, placeholder, value, onChange, className, name }) => (
//   <input
//     id={id}
//     name={name}
//     type={type}
//     placeholder={placeholder}
//     value={value}
//     onChange={onChange}
//     className={`border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 ${className}`}
//   />
// );

// // Label component
// const Label = ({ children, htmlFor }) => (
//   <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
//     {children}
//   </label>
// );

// // Select component for user type
// const Select = ({ id, name, value, onChange, options }) => (
//   <select
//     id={id}
//     name={name}
//     value={value}
//     onChange={onChange}
//     className="border px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full"
//   >
//     {options.map((option) => (
//       <option key={option.value} value={option.value}>
//         {option.label}
//       </option>
//     ))}
//   </select>
// );

// const InputWithValidation = ({ label, id, type, placeholder, value, onChange, error, success, icon: Icon, name }) => (
//   <div className="space-y-2">
//     <Label htmlFor={id}>{label}</Label>
//     <div className="relative">
//       <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//         <Icon className="h-5 w-5 text-gray-400" />
//       </div>
//       <Input
//         id={id}
//         name={name}
//         type={type}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         className={`pl-10 pr-10 ${error ? 'border-red-500' : success ? 'border-green-500' : ''}`}
//       />
//       {error && <AlertCircle className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500" />}
//       {success && <Check className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" />}
//     </div>
//     {error && <p className="text-sm text-red-500">{error}</p>}
//   </div>
// );

// export default function LoginPage() {
//   const [formData, setFormData] = useState({
//     identifier: '', // ইমেইল বা ফোন নম্বর
//     password: '',
//     userType: 'customer', // Default to 'customer'
//   });
//   const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//     validateField(name, value);
//   };

//   const validateField = (name, value) => {
//     let newErrors = { ...errors };
//     switch (name) {
//       case 'identifier':
//         if (!value.trim()) newErrors.identifier = 'Email or phone number is required';
//         else delete newErrors.identifier;
//         break;
//       case 'password':
//         if (!value.trim()) newErrors.password = 'Password is required';
//         else delete newErrors.password;
//         break;
//       default:
//         break;
//     }
//     setErrors(newErrors);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (Object.keys(errors).length === 0 && formData.identifier && formData.password) {
//       setIsSubmitting(true);
//       try {
//         const response = await fetch(`${Uaip}api/login/${formData.userType}`, {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({ identifier: formData.identifier, password: formData.password }),
//         });

//         if (response.ok) {
//           const data = await response.json();
//           localStorage.setItem('token', data.token);
//           localStorage.setItem('userType', formData.userType);
//           if (formData.userType === 'customer') {
//             navigate('/customer-dashboard');
//           } else {
//             navigate('/seller-dashboard');
//           }
//         } else {
//           const errorMsg = await response.text();
//           setError(errorMsg);
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//       setIsSubmitting(false);
//     } else {
//       Object.keys(formData).forEach((key) => validateField(key, formData[key]));
//     }
//   };

//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//       className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center pt-2 py-12 px-4 sm:px-6 lg:px-8"
//     >
//       <div className="max-w-md w-full space-y-8 bg-white p-10 pt-2 rounded-xl shadow-lg">
//         <div>
//           <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
//           <p className="mt-2 text-center text-sm text-gray-600">Welcome back to BazarProtikhon</p>
//         </div>
//         {error && <p style={{ color: 'red' }}>{error}</p>}
//         <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
//           <div className="space-y-6">
//             <InputWithValidation
//               label="Email or Phone Number"
//               id="identifier"
//               name="identifier"
//               type="text"
//               placeholder="you@example.com or +123456789"
//               value={formData.identifier}
//               onChange={handleChange}
//               error={errors.identifier}
//               success={formData.identifier && !errors.identifier}
//               icon={Mail}
//             />
//             <div className="space-y-2">
//               <Label htmlFor="password">Password</Label>
//               <div className="relative">
//                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                   <Lock className="h-5 w-5 text-gray-400" />
//                 </div>
//                 <Input
//                   id="password"
//                   name="password"
//                   type={showPassword ? 'text' : 'password'}
//                   placeholder="••••••••"
//                   value={formData.password}
//                   onChange={handleChange}
//                   className={`pl-10 pr-10 ${errors.password ? 'border-red-500' : formData.password ? 'border-green-500' : ''}`}
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setShowPassword(!showPassword)}
//                   className="absolute inset-y-0 right-2 pr-3 flex items-center"
//                 >
//                   {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
//                 </button>
//               </div>
//               {errors.password && <p className="text-sm text-red-500">{errors.password}</p>}
//             </div>

//             {/* User Type Selection */}
//             <div className="space-y-2">
//               <Label htmlFor="userType">User Type</Label>
//               <div className="relative">
//                 <div className="absolute inset-y-0 left-0.5 pl-0 flex items-center pointer-events-none">
//                   <User className="h-5 w-5 text-gray-400" />
//                 </div>
//                 <Select
//                   id="userType"
//                   name="userType"
//                   value={formData.userType}
//                   onChange={handleChange}
//                   options={[
//                     { value: 'customer', label: 'Customer' },
//                     { value: 'seller', label: 'Seller' },
//                   ]}
//                 />
//               </div>
//             </div>
//           </div>

//           <div>
//             <motion.button
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//               type="submit"
//               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//               disabled={isSubmitting}
//             >
//               {isSubmitting ? (
//                 <svg
//                   className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                 >
//                   <circle
//                     className="opacity-25"
//                     cx="12"
//                     cy="12"
//                     r="10"
//                     stroke="currentColor"
//                     strokeWidth="4"
//                   ></circle>
//                   <path
//                     className="opacity-75"
//                     fill="currentColor"
//                     d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                   ></path>
//                 </svg>
//               ) : (
//                 'Sign in'
//               )}
//             </motion.button>
//           </div>
//         </form>
//         <p className="mt-2 text-center text-sm text-gray-600">
//           Don't have an account?{' '}
//           <a href="/register" className="font-medium text-blue-600 hover:text-blue-500">
//             Sign up
//           </a>
//         </p>
//       </div>
//     </motion.div>
//   );
// }


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Eye, EyeOff, Phone, Lock, Sun, Moon, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';

const Uaip = process.env.REACT_APP_API_BASE_URL;

const Input = ({ icon: Icon, ...props }) => (
  <div className="relative">
    <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    <input
      className="w-full pl-10 pr-3 py-2 rounded-lg bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
      {...props}
    />
  </div>
);

const Select = ({ icon: Icon, ...props }) => (
  <div className="relative">
    <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    <select
      className="w-full pl-10 pr-3 py-2 rounded-lg bg-white bg-opacity-20 border-2 border-transparent focus:border-yellow-500 focus:bg-white focus:bg-opacity-30 focus:outline-none transition-all duration-300 text-white placeholder-gray-300"
      {...props}
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default function LoginPage() {
  const { isNightMode, toggleNightMode } = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    password: '',
    userType: 'customer', // Default user type
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };
    if (name === 'phoneNumber' && !value.trim()) {
      newErrors.phoneNumber = 'ফোন নম্বর প্রয়োজন';
    } else if (name === 'password' && !value.trim()) {
      newErrors.password = 'পাসওয়ার্ড প্রয়োজন';
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch(`${Uaip}api/login/${formData.userType}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          identifier: formData.phoneNumber,
          password: formData.password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('userType', formData.userType);
        navigate(formData.userType === 'customer' ? '/customer-dashboard' : '/seller-dashboard');
      } else {
        const errorMsg = await response.text();
        setError(errorMsg);
      }
    } catch (err) {
      setError('কোনো সমস্যা হয়েছে। আবার চেষ্টা করুন।');
    } finally {
      setIsSubmitting(false);
    }
  };

  const bgGradient = isNightMode
    ? 'bg-gradient-to-br from-gray-900 via-purple-900 to-violet-900'
    : 'bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500';

  const cardBg = isNightMode ? 'bg-gray-800 bg-opacity-30' : 'bg-black bg-opacity-30';

  return (
    <div className={`min-h-screen ${bgGradient} flex flex-col items-center justify-center p-4 transition-colors duration-500`}>
      

      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-bold text-center text-white mb-4">বাজারপ্রতিক্ষণে স্বাগতম</h1>
        <p className="text-xl text-center text-gray-200 mb-6">আপনার অ্যাকাউন্টে প্রবেশ করুন</p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full max-w-md mx-auto"
        >
          <form onSubmit={handleSubmit} className={`${cardBg} backdrop-filter backdrop-blur-lg p-8 rounded-2xl shadow-xl`}>
            <h2 className="text-3xl font-bold text-center text-white mb-6">লগইন</h2>
            {error && <p className="text-center text-red-500 mb-4">{error}</p>}
            <div className="space-y-4">
              <Input
                icon={Phone}
                type="tel"
                name="phoneNumber"
                placeholder="ফোন নম্বর"
                value={formData.phoneNumber}
                onChange={(e) => {
                  handleChange(e);
                  validateField(e.target.name, e.target.value);
                }}
                required
              />
              <div className="relative">
                <Input
                  icon={Lock}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="পাসওয়ার্ড"
                  value={formData.password}
                  onChange={(e) => {
                    handleChange(e);
                    validateField(e.target.name, e.target.value);
                  }}
                  required
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>

              {/* User Type Selection */}
              <Select
                icon={User}
                name="userType"
                value={formData.userType}
                onChange={handleChange}
                options={[
                  { value: 'customer', label: 'Customer' },
                  { value: 'seller', label: 'Seller' },
                ]}
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`w-full mt-6 py-3 px-4 bg-gradient-to-r ${
                isNightMode ? 'from-blue-500 to-purple-600' : 'from-yellow-400 to-orange-500'
              } text-white font-semibold rounded-lg shadow-md hover:from-yellow-500 hover:to-orange-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-opacity-75 transition-all duration-300`}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <svg
                  className="animate-spin h-5 w-5 mx-auto text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                'লগইন'
              )}
            </motion.button>
          </form>
          <p className="text-center text-white mt-4">
            একাউন্ট নেই?{' '}
            <a href="/register" className="text-yellow-400 hover:underline">
              রেজিস্টার করুন
            </a>
          </p>
        </motion.div>
      </div>
    </div>
  );
}
